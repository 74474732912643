import {Settings} from 'settings/utils';
import {MaskedMetricInstance, MetricsByName} from 'toolkit/metrics/types';
import {
  getComparisonMetricInstanceIdentifier,
  getDefaultMetricArguments,
  getEffectiveComparisonMetrics,
  getEffectiveMetric,
  getMetricOrThrow,
  getMetricsList,
  isComparisonMetric,
  metricListToMetricInstance,
} from 'toolkit/metrics/utils';
import {DEFAULT_PERIOD} from 'toolkit/time/constants';
import * as Types from 'types';
import {InventoryMeasure, MetricFeature} from 'types';
import {isNonNullish} from 'utils/functions';

import {COMPARISON_METRIC_NAMES, ComparisonMetricType, MetricInstanceProposedData} from './types';
import {
  getFlagsFromMetric,
  getModifiedMetricInstance,
  updateArgumentsForComparedToMetricInstance,
} from './utils';

export type ComparisonMetric = {
  metricName: string;
  childMetricNames?: ReadonlyArray<string>;
  arguments?:
    | Partial<Types.MetricArguments>
    | ((
        baseMetricInstance: Types.MetricInstance,
        vendorAnalysisSettings: Types.VendorAnalysisSettings
      ) => Partial<Types.MetricArguments>);
};

type ComparisonMetricInstanceConfig = {
  [key in string]: ReadonlyArray<ComparisonMetric>;
};

const defaultPartnerForecastArgs = {
  forecastComposition: Types.ForecastComposition.TOTAL,
  forecastType: Types.ForecastType.PARTNER,
  historicalPeriod: {
    amount: 4,
    unit: Types.CalendarUnit.WEEKS,
  },
};

const defaultPlanForecastArgs: Partial<Types.MetricArguments> = {
  forecastComposition: Types.ForecastComposition.TOTAL,
  forecastType: Types.ForecastType.PLAN,
};

const setGrossSalesForecastComposition = (baseMetricInstance: Types.MetricInstance) => ({
  forecastComposition:
    baseMetricInstance.arguments.forecastComposition ?? Types.ForecastComposition.TOTAL,
});

export const comparisonMetricInstances: ComparisonMetricInstanceConfig = {
  sales_units_net: [
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_units_net_target',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
    {
      metricName: 'unconstrained_demand_units',
    },
    {
      metricName: 'shipment_plan',
    },
  ],
  sales_units_gross_composable: [
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_units_net_target',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'unconstrained_demand_units',
    },
    {
      metricName: 'shipment_plan',
    },
  ],
  shipped_units: [
    {
      metricName: 'forecast_shipped_units',
      arguments: defaultPartnerForecastArgs,
    },
  ],
  inbound_shipped_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'shipment_plan',
      arguments: defaultPlanForecastArgs,
    },
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
  ],
  outbound_shipped_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'outbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'outbound',
      childMetricNames: ['committed_shipped_units'],
    },
    {
      metricName: 'outbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
  ],
  inbound_completed_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['requested_shipments_all_except_cancelled'],
    },
  ],
  inbound_cut_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['requested_shipments_all_except_cancelled'],
    },
  ],
  inbound_scheduled_shipped_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['requested_shipments_all_except_cancelled'],
    },
  ],
  outbound_completed_units: [
    {
      metricName: 'outbound',
      childMetricNames: ['requested_shipments_all_except_cancelled'],
    },
  ],
  outbound_cut_units: [
    {
      metricName: 'outbound',
      childMetricNames: ['requested_shipments_all_except_cancelled'],
    },
  ],
  outbound_scheduled_shipped_units: [
    {
      metricName: 'outbound',
      childMetricNames: ['requested_shipments_all_except_cancelled'],
    },
  ],
  completed_units: [
    {
      metricName: 'requested_shipments_all_except_cancelled',
    },
  ],
  cut_units: [
    {
      metricName: 'requested_shipments_all_except_cancelled',
    },
  ],
  scheduled_shipped_units: [
    {
      metricName: 'requested_shipments_all_except_cancelled',
    },
  ],
  requested_shipments_all_except_cancelled: [
    {metricName: 'completed_units'},
    {metricName: 'cut_units'},
    {metricName: 'scheduled_shipped_units'},
  ],
  inbound_requested_shipments_all_except_cancelled: [
    {
      metricName: 'inbound',
      childMetricNames: ['completed_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['cut_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['scheduled_shipped_units'],
    },
  ],
  outbound_requested_shipments_all_except_cancelled: [
    {
      metricName: 'outbound',
      childMetricNames: ['completed_units'],
    },
    {
      metricName: 'outbound',
      childMetricNames: ['cut_units'],
    },
    {
      metricName: 'outbound',
      childMetricNames: ['scheduled_shipped_units'],
    },
  ],
  inbound_committed_shipped_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
  ],
  inbound_received_units: [
    {
      metricName: 'planned_inbound_received_units',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_received_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'outbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'shipment_plan',
    },
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
  ],
  inbound_forecast_received_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
  ],
  inbound_ordered_units: [
    {
      metricName: 'planned_purchases',
    },
    {
      metricName: 'outbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
    {
      metricName: 'shipment_plan',
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
  ],
  inbound_requested_received_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_received_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_ordered_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
    {
      metricName: 'shipment_plan',
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
  ],
  outbound_ordered_units: [
    {
      metricName: 'outbound',
      childMetricNames: ['shipped_units'],
    },
  ],
  shipment_plan: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_ordered_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
  ],
  forecast_sales_units_net: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'shipment_plan',
      arguments: defaultPlanForecastArgs,
    },
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
    {
      metricName: 'expected_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'expected_sales_units_gross',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'unconstrained_demand_units',
    },
    {
      metricName: 'sales_units_net_target',
    },
  ],
  forecast_shipped_units: [
    {
      metricName: 'shipped_units',
    },
  ],
  inbound_forecast_shipped_units: [
    {
      metricName: 'shipment_plan',
      arguments: defaultPlanForecastArgs,
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_ordered_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipped_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
    {
      metricName: 'inbound',
      childMetricNames: ['requested_received_units'],
    },
  ],
  outbound_forecast_shipped_units: [
    {
      metricName: 'outbound',
      childMetricNames: ['shipped_units'],
    },
  ],
  inbound_forecast_ordered_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'shipment_plan',
      arguments: defaultPlanForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
  ],
  expected_sales_units_net: [
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_units_net_target',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['expected_shipped_units'],
      arguments: defaultPlanForecastArgs,
    },
  ],
  expected_sales_units_gross: [
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_units_net_target',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['expected_shipped_units'],
      arguments: defaultPlanForecastArgs,
    },
  ],
  expected_shipped_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
  ],
  planned_inbound_received_units: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_received_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'outbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['received_units'],
    },
  ],
  planned_purchases: [
    {
      metricName: 'shipment_plan',
      arguments: defaultPlanForecastArgs,
    },
    {
      metricName: 'outbound',
      childMetricNames: ['forecast_shipped_units'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['ordered_units'],
    },
  ],
  sales_net: [
    {
      metricName: 'forecasted_sales_dollars',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'forecast_sales_dollars_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'shipment_plan_dollars',
      arguments: {
        ...defaultPlanForecastArgs,
        dollarType: Types.DollarType.RETAIL_PRICE,
      },
    },
    {
      metricName: 'sales_dollars_net_target',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipments_dollars'],
      arguments: {dollarType: Types.DollarType.SELL_IN_PRICE},
    },
    {
      metricName: 'sales_gross',
    },
  ],
  sales_gross: [
    {
      metricName: 'forecasted_sales_dollars',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'shipment_plan_dollars',
      arguments: {
        ...defaultPlanForecastArgs,
        dollarType: Types.DollarType.RETAIL_PRICE,
      },
    },
    {
      metricName: 'sales_dollars_net_target',
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipments_dollars'],
      arguments: {dollarType: Types.DollarType.SELL_IN_PRICE},
    },
  ],
  forecasted_sales_dollars: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecasted_shipments_dollars'],
      arguments: {...defaultPartnerForecastArgs, dollarType: Types.DollarType.SELL_IN_PRICE},
    },
    {
      metricName: 'shipment_plan_dollars',
      arguments: {
        ...defaultPlanForecastArgs,
        dollarType: Types.DollarType.SELL_IN_PRICE,
      },
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'sales_gross',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
  ],
  inbound_shipped_cost: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecasted_shipments_dollars'],
      arguments: {...defaultPartnerForecastArgs, dollarType: Types.DollarType.SELL_IN_PRICE},
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
  ],
  inbound_shipments_dollars: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecasted_shipments_dollars'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'shipment_plan_dollars',
      arguments: {
        ...defaultPlanForecastArgs,
        dollarType: Types.DollarType.RETAIL_PRICE,
      },
    },
  ],
  outbound_shipments_dollars: [
    {
      metricName: 'outbound',
      childMetricNames: ['forecasted_shipments_dollars'],
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
  ],
  shipments_dollars: [
    {
      metricName: 'forecasted_shipments_dollars',
      arguments: defaultPartnerForecastArgs,
    },
  ],
  outbound_on_order_units: [
    {
      metricName: 'on_hand_units',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
  ],
  inbound_forecasted_shipments_dollars: [
    {
      metricName: 'inbound',
      childMetricNames: ['shipments_dollars'],
    },
    {
      metricName: 'forecasted_sales_dollars',
      arguments: {...defaultPartnerForecastArgs, dollarType: Types.DollarType.RETAIL_PRICE},
    },
  ],
  outbound_forecasted_shipments_dollars: [
    {
      metricName: 'outbound',
      childMetricNames: ['shipments_dollars'],
    },
  ],
  forecasted_shipments_dollars: [
    {
      metricName: 'shipments_dollars',
    },
  ],
  expected_shipments_dollars: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecasted_shipments_dollars'],
      arguments: defaultPartnerForecastArgs,
    },
  ],
  expected_sales_net: [
    {
      metricName: 'expected_shipments_dollars',
      arguments: {dollarType: Types.DollarType.SELL_IN_PRICE},
    },
  ],
  unconstrained_demand_units: [
    {
      metricName: 'sales_units_net',
    },
    {
      metricName: 'sales_units_gross_composable',
      arguments: setGrossSalesForecastComposition,
    },
    {
      metricName: 'forecast_sales_units_net',
      arguments: defaultPartnerForecastArgs,
    },
  ],
  shipment_plan_cogs: [
    {
      metricName: 'forecast_shipped_dollars',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'forecast_sales_dollars_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipments_dollars'],
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'sales_gross',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
  ],
  shipment_plan_dollars: [
    {
      metricName: 'forecast_shipped_dollars',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'forecast_sales_dollars_net',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'inbound',
      childMetricNames: ['shipments_dollars'],
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'sales_gross',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
  ],
  expected_sales_dollars_net: [
    {
      metricName: 'expected_shipped_cogs',
    },
  ],
  expected_shipped_cogs: [
    {
      metricName: 'inbound',
      childMetricNames: ['forecast_shipped_dollars'],
      arguments: defaultPartnerForecastArgs,
    },
  ],
  forecast_sales_dollars_net: [
    {
      metricName: 'forecast_shipped_dollars',
      arguments: defaultPartnerForecastArgs,
    },
    {
      metricName: 'shipment_plan_cogs',
      arguments: defaultPlanForecastArgs,
    },
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'sales_dollars_net_target',
    },
    {
      metricName: 'shipment_plan_dollars',
      arguments: {
        ...defaultPlanForecastArgs,
        dollarType: Types.DollarType.RETAIL_PRICE,
      },
    },
  ],
  forecast_shipped_dollars: [
    {
      metricName: 'inbound',
      childMetricNames: ['shipments_dollars'],
    },
    {
      metricName: 'shipment_plan_dollars',
      arguments: {
        ...defaultPlanForecastArgs,
        dollarType: Types.DollarType.RETAIL_PRICE,
      },
    },
  ],
  unconstrained_demand_dollars_generic: [
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'sales_gross',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'forecast_sales_dollars_net',
      arguments: defaultPartnerForecastArgs,
    },
  ],
  unconstrained_demand_cogs: [
    {
      metricName: 'sales_net',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'sales_gross',
      arguments: {dollarType: Types.DollarType.RETAIL_PRICE},
    },
    {
      metricName: 'forecast_sales_dollars_net',
      arguments: defaultPartnerForecastArgs,
    },
  ],
  simulated_on_hand_units: [
    {
      metricName: 'on_hand_units_target',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
    {
      metricName: 'target_inventory',
      arguments: (baseMetricInstance, vendorAnalysisSettings) => ({
        ...baseMetricInstance.arguments,
        inFlowTypes: [],
        supplyTargetPeriod: vendorAnalysisSettings.supplyTargetPeriod,
      }),
    },
  ],
  price: [
    {
      metricName: 'average_unit_price',
    },
  ],
  average_unit_price: [
    {
      metricName: 'price',
      arguments: {
        stockAggregator: Types.StockAggregator.ENDING,
      },
    },
  ],
  cost: [
    {
      metricName: 'average_unit_cost',
    },
  ],
  average_unit_cost: [
    {
      metricName: 'cost',
    },
  ],
  remaining_supply: [
    {
      metricName: 'remaining_supply_target',
      arguments: baseMetricInstance => ({
        timeUnit: baseMetricInstance.arguments.timeUnit,
      }),
    },
  ],
  simulated_remaining_supply: [
    {
      metricName: 'remaining_supply_target',
      arguments: baseMetricInstance => ({
        timeUnit: baseMetricInstance.arguments.timeUnit,
      }),
    },
  ],
  on_hand_units: [
    {
      metricName: 'on_hand_units_target',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
    {
      metricName: 'maximum_shelf_quantity',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
    {
      metricName: 'target_inventory',
      arguments: (baseMetricInstance, vendorAnalysisSettings) => ({
        forecastComposition: vendorAnalysisSettings.forecastComposition,
        forecastType: vendorAnalysisSettings.forecastType,
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
        supplyTargetPeriod: vendorAnalysisSettings.supplyTargetPeriod,
        outflowTypes: [vendorAnalysisSettings.outflowType],
      }),
    },
    {
      metricName: 'outbound',
      childMetricNames: ['on_order_units'],
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
  ],
  active_locations: [
    {
      metricName: 'scanning_locations',
    },
    {
      metricName: 'tracked_locations',
    },
  ],
  scanning_locations: [
    {
      metricName: 'active_locations',
    },
    {
      metricName: 'tracked_locations',
    },
  ],
  tracked_locations: [
    {
      metricName: 'active_locations',
    },
    {
      metricName: 'scanning_locations',
    },
  ],
  target_inventory: [
    {
      metricName: 'on_hand_units',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
    {
      metricName: 'on_hand_units_target',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
    {
      metricName: 'simulated_on_hand_units',
      arguments: (baseMetricInstance, vendorAnalysisSettings) => ({
        ...baseMetricInstance.arguments,
        inflowTypes: [],
        inventoryTypes: vendorAnalysisSettings.inventoryTypes,
      }),
    },
    {
      metricName: 'total_inventory',
      arguments: (baseMetricInstance, vendorAnalysisSettings) => ({
        ...baseMetricInstance.arguments,
        inventoryMeasure: InventoryMeasure.UNITS,
        inventoryTypes: vendorAnalysisSettings.inventoryTypes,
      }),
    },
  ],
  total_inventory: [
    {
      metricName: 'target_inventory',
      arguments: (baseMetricInstance, vendorAnalysisSettings) => ({
        ...baseMetricInstance.arguments,
        forecastComposition: vendorAnalysisSettings.forecastComposition,
        forecastType: vendorAnalysisSettings.forecastType,
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
        supplyTargetPeriod: vendorAnalysisSettings.supplyTargetPeriod,
        outflowTypes: [vendorAnalysisSettings.outflowType],
        inventoryMeasure: null,
      }),
    },
  ],
  on_hand_units_target: [
    {
      metricName: 'on_hand_units',
      arguments: baseMetricInstance => ({
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
      }),
    },
    {
      metricName: 'target_inventory',
      arguments: (baseMetricInstance, vendorAnalysisSettings) => ({
        ...baseMetricInstance.arguments,
        forecastComposition: vendorAnalysisSettings.forecastComposition,
        forecastType: vendorAnalysisSettings.forecastType,
        stockAggregator: baseMetricInstance.arguments.stockAggregator,
        supplyTargetPeriod: vendorAnalysisSettings.supplyTargetPeriod,
        outflowTypes: [vendorAnalysisSettings.outflowType],
      }),
    },
  ],
};

export const defaultComparisonTimeAgo = {
  amount: 1,
  unit: Types.CalendarUnit.YEARS,
};

export function getDefaultComparisonMetricInstance(
  baseMetricInstance: Types.MetricInstance
): Types.MetricInstance {
  return {
    metric: baseMetricInstance.metric,
    arguments: {
      ...baseMetricInstance.arguments,
      timeAgo: defaultComparisonTimeAgo,
      isTimeAgoCalendarPeriodAligned: false,
    },
  };
}

export function getComparisonMetricInstancesMap(
  parentMetricInstance: Types.MetricInstance,
  baseMetricInstance: Types.MetricInstance,
  availableMetrics: MetricsByName,
  vendorAnalysisSettings: Types.VendorAnalysisSettings
): ReadonlyArray<Types.MetricInstance> {
  const metricConfig =
    comparisonMetricInstances[getComparisonMetricInstanceIdentifier(baseMetricInstance)] ?? [];
  const comparisonMetricInstanceMap = metricConfig
    .filter(config => {
      const dependencies = [config.metricName, ...(config.childMetricNames ?? [])];
      return dependencies.every(dependencyMetricName => availableMetrics.has(dependencyMetricName));
    })
    .map(config => ({
      metric: availableMetrics.get(config.metricName)!,
      arguments: {
        ...getDefaultMetricArguments(baseMetricInstance.arguments.period),
        ...(!!config.arguments
          ? typeof config.arguments === 'function'
            ? config.arguments(baseMetricInstance, vendorAnalysisSettings)
            : config.arguments
          : {}),
        childMetrics: config.childMetricNames
          ? config.childMetricNames.map(name => availableMetrics.get(name)!)
          : null,
      },
    }))
    .map(metricInstance =>
      updateArgumentsForComparedToMetricInstance(
        parentMetricInstance,
        baseMetricInstance,
        metricInstance
      )
    );

  return [getDefaultComparisonMetricInstance(baseMetricInstance), ...comparisonMetricInstanceMap];
}

/**
 * Get the intermediate higher order metrics between the comparison metric
 * (eg. metric_comps_percent) and the effective comparison metric
 * (eg. inbound, shipped_units)
 *
 * For example, given a metric instance
 *   Metric: percent_of_grouping
 *   ChildMetrics: [mean, metric_comps_percent, unit_conversion, inbound, shipped_units]
 *
 * This function would return: [unit_conversion]
 */
export function getIntermediateComparisonMetrics(
  baseMetricInstance: Types.MetricInstance,
  comparisonMetricInstance: Types.MetricInstance
) {
  const metrics = getMetricsList(baseMetricInstance);
  if (!metrics.some(metric => COMPARISON_METRIC_NAMES.includes(metric.name))) {
    return [];
  }
  const indexOfComparisonMetric = metrics.findIndex(metric =>
    COMPARISON_METRIC_NAMES.includes(metric.name)
  );
  const effectiveBaseMetrics = getEffectiveComparisonMetrics(baseMetricInstance);
  const effectiveComparisonMetric = getEffectiveMetric(comparisonMetricInstance);
  const includeUnitConversion = effectiveComparisonMetric.features.includes(
    MetricFeature.IS_UNIT_METRIC
  );
  return metrics
    .slice(indexOfComparisonMetric + 1)
    .filter(
      metric =>
        !effectiveBaseMetrics.includes(metric) &&
        (metric.name !== 'unit_conversion' || includeUnitConversion)
    );
}

export function getPresetMetricComparisons(
  availableMetrics: MetricsByName,
  defaultEvaluationPeriod: Types.DatePeriod | null | undefined
): ReadonlyArray<MaskedMetricInstance> {
  const unitSalesMetric = availableMetrics.get('sales_units_net');
  const forecastedUnitSalesMetric = availableMetrics.get('forecast_sales_units_net');
  const percentCompMetric = availableMetrics.get('metric_comps_percent');
  const inboundMetric = availableMetrics.get('inbound');
  const shippedUnitsMetric = availableMetrics.get('shipped_units');
  const shipmentPlanMetric = availableMetrics.get('shipment_plan');
  const presets: MaskedMetricInstance[] = [];

  if (!percentCompMetric) {
    return [];
  }

  const period = defaultEvaluationPeriod || DEFAULT_PERIOD;
  const oneYearAgo = {
    amount: 1,
    unit: Types.CalendarUnit.YEARS,
  };
  const oneWeekVersionLag = {
    asOfDate: null,
    lag: null,
    mostRecent: false,
    relativePeriod: {
      amount: 1,
      unit: Types.CalendarUnit.WEEKS,
    },
  };

  if (unitSalesMetric) {
    presets.push({
      metricInstance: {
        metric: percentCompMetric,
        arguments: {
          ...getDefaultMetricArguments(period),
          childMetrics: [unitSalesMetric],
          timeAgo: oneYearAgo,
          comparisonMetrics: [
            {
              metric: unitSalesMetric,
              arguments: getDefaultMetricArguments(period),
            },
            {
              metric: unitSalesMetric,
              arguments: {
                ...getDefaultMetricArguments(period),
                timeAgo: oneYearAgo,
              },
            },
          ],
        },
      },
      arguments: [Types.MetricArgument.comparisonMetrics],
      displayName: 'POS: Change vs. Prior Year',
    });

    if (forecastedUnitSalesMetric) {
      presets.push({
        metricInstance: {
          metric: percentCompMetric,
          arguments: {
            ...getDefaultMetricArguments(period),
            childMetrics: [unitSalesMetric],
            comparisonMetrics: [
              {
                metric: unitSalesMetric,
                arguments: getDefaultMetricArguments(period),
              },
              {
                metric: forecastedUnitSalesMetric,
                arguments: {
                  ...getDefaultMetricArguments(period),
                  forecastComposition: Types.ForecastComposition.TOTAL,
                  forecastType: Types.ForecastType.PLAN,
                  versionRecency: oneWeekVersionLag,
                },
              },
            ],
          },
        },
        arguments: [Types.MetricArgument.comparisonMetrics],
        displayName: 'POS: Actuals vs. Forecast',
      });
    }
  }

  if (inboundMetric && shippedUnitsMetric && shipmentPlanMetric) {
    presets.push({
      metricInstance: {
        metric: percentCompMetric,
        arguments: {
          ...getDefaultMetricArguments(period),
          childMetrics: [inboundMetric, shippedUnitsMetric],
          comparisonMetrics: [
            {
              metric: inboundMetric,
              arguments: {
                ...getDefaultMetricArguments(period),
                childMetrics: [shippedUnitsMetric],
              },
            },
            {
              metric: shipmentPlanMetric,
              arguments: {
                ...getDefaultMetricArguments(period),
                forecastComposition: Types.ForecastComposition.TOTAL,
                forecastType: Types.ForecastType.PLAN,
                versionRecency: oneWeekVersionLag,
              },
            },
          ],
        },
      },
      arguments: [Types.MetricArgument.comparisonMetrics],
      displayName: 'Shipments: Actuals vs. Plan',
    });
  }

  return presets;
}

export function changeComparisonMetricType(
  comparisonType: ComparisonMetricType,
  originalMetric: Types.MetricInstance,
  comparedToMetric: Types.MetricInstance | null
): MetricInstanceProposedData {
  const flags = getFlagsFromMetric(originalMetric);
  if (comparisonType === ComparisonMetricType.NONE || !comparedToMetric) {
    return {
      flags: {
        ...flags,
        compsMetricType: ComparisonMetricType.NONE,
      },
    };
  }

  // When we change comparison types and we have existing comparison metrics (e.g. going from % Chg to Abs Chg)
  // we just use the base comparison metric as the new base metric instance. If we're changing from
  // non-comparison to comparison, we need to strip out the time ago from the base metric or there will be
  // no way to remove it using the editor (time ago on the base metric is disabled when doing comparisons).
  const baseMetricInstance = originalMetric.arguments.comparisonMetrics
    ? originalMetric.arguments.comparisonMetrics[0]
    : originalMetric.metric.name === 'contribution'
      ? {
          ...originalMetric,
          arguments: {
            ...originalMetric.arguments,
            timeAgo: null,
            isTimeAgoCalendarPeriodAligned: null,
          },
        }
      : originalMetric;

  return {
    flags: {
      ...flags,
      compsMetricType: comparisonType,
    },
    instanceArguments: {
      ...originalMetric.arguments,
      timeAgo: comparedToMetric.arguments.timeAgo,
      isTimeAgoCalendarPeriodAligned: comparedToMetric.arguments.isTimeAgoCalendarPeriodAligned,
      comparisonMetrics: [baseMetricInstance, comparedToMetric],
    },
  };
}

export function createComparisonMetric(
  comparisonType: ComparisonMetricType,
  originalMetric: Types.MetricInstance,
  comparedToMetric: Types.MetricInstance | null,
  settings: Settings,
  availableMetrics: MetricsByName,
  currency: Types.CurrencyCode
): Types.MetricInstance {
  const proposedData = changeComparisonMetricType(comparisonType, originalMetric, comparedToMetric);
  return getModifiedMetricInstance(
    originalMetric,
    proposedData,
    settings,
    availableMetrics,
    currency
  );
}

export function getFullComparisonSubMetric(
  comparisonMetric: Types.MetricInstance,
  comparisonMetricGetter: (metrics: ReadonlyArray<Types.MetricInstance>) => Types.MetricInstance,
  settings: Settings,
  availableMetrics: MetricsByName,
  currency: Types.CurrencyCode
): Types.MetricInstance | null {
  if (!isComparisonMetric(comparisonMetric)) {
    return null;
  }

  const metric = comparisonMetricGetter(comparisonMetric.arguments.comparisonMetrics!);
  const metricsList = [
    ...(isNonNullish(metric.arguments.timeAgo)
      ? [getMetricOrThrow('metric_comps_value', availableMetrics)]
      : []),
    ...getIntermediateComparisonMetrics(comparisonMetric, metric),
    ...getMetricsList(metric),
  ];

  const proposedMetricArguments: Types.MetricArguments = {
    ...comparisonMetric.arguments,
    ...metric.arguments,
  };
  const proposedMetricInstance = metricListToMetricInstance(metricsList, proposedMetricArguments);
  return getModifiedMetricInstance(
    proposedMetricInstance,
    {},
    settings,
    availableMetrics,
    currency
  );
}
