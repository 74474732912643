import React from 'react';

import {SubscribedResource} from 'utils/useApi';

export function withApi<P, Resources extends {[key: string]: unknown} & Partial<P>>(
  resources: () => {[key in keyof Resources]: SubscribedResource<Resources[key]>},
  Component: React.ComponentType<P>
) {
  const HocComponent: React.FunctionComponent<Omit<P, keyof Resources>> = props => {
    const resolvedResources = Object.fromEntries(
      Object.entries(resources()).map(([key, value]) => [key, value.privateDoNotUse()])
    ) as Resources;
    const fullProps = {
      ...props,
      ...resolvedResources,
    } as P & Resources;
    return <Component {...fullProps} />;
  };
  return HocComponent;
}
