import equal from 'fast-deep-equal';
import {List, Map, Set} from 'immutable';

import {mapView} from 'app/presentation/mapping';
import {getStateFromLocationChange} from 'dashboard/utils';
import {AnalysisAction} from 'redux/actions/analysis';
import {isFail, isLoading} from 'redux/actions/helpers';
import {DeleteTagAction, SaveTagAction} from 'redux/actions/navigation';
import ActionType from 'redux/actions/types';
import {AttributeHierarchies, AttributesById, PartnersById} from 'toolkit/attributes/types';
import {
  getWidgetIndicesWithConflictingSelectionFilters,
  mergeAttributeFilters,
} from 'toolkit/filters/utils';
import {
  ForecastTypesByForecastedMetricName,
  MetricDescriptionsByName,
  MetricNamesByCategory,
  MetricsByName,
} from 'toolkit/metrics/types';
import {isPlanningView} from 'toolkit/plans/utils';
import {getPartnerCalendar, getVendorEvaluationDate, hasDefaultCalendar} from 'toolkit/time/utils';
import {
  createDefaultWidgetData,
  EventWidgetData,
  ThinViewsById,
  ViewState,
  ViewUrlParams,
  WidgetData,
  WidgetDataType,
  FullViewsById,
} from 'toolkit/views/types';
import {
  getDefaultViewStateProps,
  isBuiltInView,
  isSaveableViewId,
  isViewDirty,
  isViewRefreshRequired,
  replaceCurrencyArgumentInWidgets,
  replacePeriodsInWidgets,
  replaceUnitConversionAttributesInWidgets,
  toThinView,
} from 'toolkit/views/utils';
import {getViewParamsFromLocation} from 'toolkit/views/view-urls';
import * as Types from 'types';
import {moveItem, removeAt, replaceAt} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {clip, isNonNullish} from 'utils/functions';
import {Status} from 'utils/status';
import {WidgetLayoutData} from 'widgets/types';
import {hasEventData, isWidgetRefreshRequired, Widgets} from 'widgets/utils';
import {
  getFlattenedWidgetDataTree,
  getFlattenedWidgetTree,
  getParentWidgetIndex,
  getWidgetTree,
  getWidgetTreeWithoutData,
  isChildWidget,
  isParentWidget,
  treeIndexToActualIndex,
} from 'widgets/widget-layout';

import {updateWidgetState} from './widget-data-reducers';

export interface AnalysisData {
  readonly allGroupings: AttributesById;
  readonly allGroupingsRequest: Promise<unknown> | null;
  readonly attributeDescriptions: {[name: string]: Types.AttributeDescription};
  readonly availableForecastTypesByMetricName: ForecastTypesByForecastedMetricName;
  readonly availableMetricNamesByCategory: MetricNamesByCategory;
  readonly allMetrics: MetricsByName;
  readonly allMetricsRequest: Promise<unknown> | null;
  readonly metricNamesWithAnyData: Set<string>;
  readonly vendorThinViews: ThinViewsById;
  readonly publicThinViews: ThinViewsById;
  // contains a list of all visited dashboards during a user's session. Used for navigation and determining dirty state
  readonly viewsCache: FullViewsById;
  readonly availableCalendarEvents: ReadonlyArray<Types.CalendarEvent>;
  readonly edgeDataRanges: ReadonlyArray<Types.EdgeDataRange>;
  readonly defaultAttributeHierarchies: AttributeHierarchies;
  readonly favoriteViews: Set<number>;
  readonly fetchingAllGroupingsStatus: Status;
  readonly fetchingAvailableCalendarEventsStatus: Status;
  readonly fetchingMetricsStatus: Status;
  readonly fetchingThinViewsStatus: Status;
  readonly metricDescriptions: MetricDescriptionsByName;
  readonly partners: Map<number, Types.Partner>;
  readonly partnersRequest: Promise<unknown> | null;
  readonly viewLinks: ReadonlyArray<Types.ViewLink>;
  readonly doubleCountingPartners: ReadonlyArray<Types.DoubleCountingPartner>;
}

export interface AnalysisState {
  readonly data: AnalysisData;
  readonly unsavedView: Types.View | null;
  readonly views: List<ViewState>;
}

export const defaultAnalysisState: AnalysisState = {
  data: {
    allGroupings: Map(),
    allGroupingsRequest: null,
    attributeDescriptions: {},
    availableCalendarEvents: [],
    availableForecastTypesByMetricName: Map(),
    availableMetricNamesByCategory: Map(),
    allMetrics: Map(),
    allMetricsRequest: null,
    metricNamesWithAnyData: Set(),
    viewsCache: Map(),
    vendorThinViews: Map(),
    edgeDataRanges: [],
    defaultAttributeHierarchies: Map(),
    favoriteViews: Set(),
    fetchingAllGroupingsStatus: Status.unstarted,
    fetchingAvailableCalendarEventsStatus: Status.unstarted,
    fetchingMetricsStatus: Status.unstarted,
    fetchingThinViewsStatus: Status.unstarted,
    metricDescriptions: Map(),
    partners: Map(),
    partnersRequest: null,
    publicThinViews: Map(),
    viewLinks: [],
    doubleCountingPartners: [],
  },
  unsavedView: null,
  views: List.of(new ViewState()),
};

const toMetricMap = (metrics: ReadonlyArray<Types.Metric>) =>
  Map<string, Types.Metric>(metrics.map<[string, Types.Metric]>(metric => [metric.name, metric]));

const toMetricDescriptionMap = (jsonData: ReadonlyArray<Types.MetricDescription>) =>
  Map<string, string>(jsonData.map(data => [data.name, data.description]));

const copyWidget = (widget: WidgetLayoutData): WidgetLayoutData => ({
  children: widget.children ? widget.children.map(copyWidget) : undefined,
  data: widget.data,
  widget: {...widget.widget, id: null},
});

function toDefaultWidgetData(widget: Types.Widget): WidgetData<unknown> {
  return {
    ...createDefaultWidgetData(),
    needsRefresh: !Widgets[widget.type].isContainer,
  };
}

function getWidgetDataToUpdate(
  prevViewState: ViewState,
  newView: Types.View
): ReadonlyArray<WidgetData<unknown>> {
  const viewDataPropsChanged = isViewRefreshRequired(
    {...prevViewState.view, widgets: []},
    {...newView, widgets: []}
  );

  if (!viewDataPropsChanged && equal(prevViewState.view.widgets, newView.widgets)) {
    return prevViewState.widgetData;
  }

  if (prevViewState.view.widgets.length !== newView.widgets.length) {
    return newView.widgets.map((widget, index) => {
      const prevWidget = prevViewState.view.widgets[index];
      const prevWidgetData = prevViewState.widgetData[index];
      return prevWidget === widget && prevWidgetData ? prevWidgetData : toDefaultWidgetData(widget);
    });
  }

  return newView.widgets.map((newWidget, index) =>
    viewDataPropsChanged || isWidgetRefreshRequired(prevViewState.view.widgets[index], newWidget)
      ? {
          ...toDefaultWidgetData(newWidget),
          data: !Widgets[prevViewState.view.widgets[index].type].supportsOutdatedData
            ? null
            : prevViewState.widgetData[index].data,
        }
      : prevViewState.widgetData[index]
  );
}

function haveOtherFiltersChanged(
  prevViewState: ViewState,
  nextSelectionFilters: Map<number, readonly Types.AttributeFilter[]>,
  widgetIndex: number
) {
  return !equal(
    prevViewState.selectionFiltersByWidgetIndex
      .filter((_, prevIndex) => prevIndex !== widgetIndex)
      .valueSeq()
      .flatMap(filters => filters)
      .toSet(),
    nextSelectionFilters
      .filter((_, nextIndex) => nextIndex !== widgetIndex)
      .valueSeq()
      .flatMap(filters => filters)
      .toSet()
  );
}

const withRefreshRequired = (
  widgetData: WidgetData<unknown>,
  widget: Types.Widget
): WidgetData<unknown> => ({...widgetData, needsRefresh: !Widgets[widget.type].isContainer});

const mapWithRefreshRequired = (
  widgetDataItems: ReadonlyArray<WidgetData<unknown>>,
  widgets: ReadonlyArray<Types.Widget>
) =>
  widgetDataItems.map<WidgetData<unknown>>((data, index) =>
    withRefreshRequired(data, widgets[index])
  );

const widgetsEqual = (widget: Types.Widget, otherWidget: Types.Widget) => {
  return equal({...widget, layoutParams: null}, {...otherWidget, layoutParams: null});
};

const canPropagateSelection = (view: Types.View) => view.layoutType !== 'FLOW';

function replaceViewTag<T extends Types.ThinView>(view: T, action: SaveTagAction): T {
  return {
    ...view,
    tags: view.tags.map(tag => (tag === action.previousTag?.name ? action.tagToSave.name : tag)),
  };
}

function deleteViewTag<T extends Types.ThinView>(view: T, action: DeleteTagAction): T {
  return {
    ...view,
    tags: view.tags.filter(tag => tag !== action.tag.name),
  };
}

function getUnsavedView(
  previousViewState: ViewState,
  newView: Types.ThinView | null,
  originalView: Types.View | null,
  availableThinViews: ThinViewsById
) {
  const wasDirty = isViewDirty(previousViewState.view, originalView);

  const isNewViewEqualToPrevious =
    newView &&
    newView.id === previousViewState.view.id &&
    isSaveableViewId(previousViewState.view.id);

  const wasBuiltIn = isBuiltInView(availableThinViews, previousViewState.view);

  return wasDirty && !wasBuiltIn && !isNewViewEqualToPrevious ? previousViewState.view : null;
}

function parseMetricData(
  allMetrics: ReadonlyArray<Types.Metric>,
  metricNamesWithAnyData: Set<string>
) {
  const availableMetrics = allMetrics.filter(({name}) => metricNamesWithAnyData.includes(name));
  const availableMetricNamesByCategory = availableMetrics
    .flatMap(metric =>
      metric.categories.map<{category: Types.MetricCategory; metricName: string}>(category => ({
        category,
        metricName: metric.name,
      }))
    )
    .reduce(
      (accumulator, categoryAndMetricName) =>
        accumulator.update(categoryAndMetricName.category, List<string>(), list =>
          list.push(categoryAndMetricName.metricName)
        ),
      Map<Types.MetricCategory, List<string>>()
    );
  return availableMetricNamesByCategory;
}

function applyWidgetTreeToState(widgetTree: List<WidgetLayoutData>, state: ViewState) {
  return state.merge({
    view: {...state.view, widgets: getFlattenedWidgetTree(widgetTree)},
    widgetData: getFlattenedWidgetDataTree(widgetTree),
  });
}

const getNextCalendar = (
  partners: PartnersById,
  filters: ReadonlyArray<Types.AttributeFilter>,
  vendorCalendar: Types.RetailCalendarEnum,
  isCalendarAutoSwitchingEnabled: boolean,
  prevViewState: ViewState
) => {
  const wasDefault = hasDefaultCalendar(prevViewState.view, partners, vendorCalendar);
  if (!wasDefault) {
    return prevViewState.view.calendar;
  } else if (!isCalendarAutoSwitchingEnabled) {
    return vendorCalendar;
  }

  const partnerCalendar = getPartnerCalendar(partners, filters);
  return partnerCalendar || vendorCalendar;
};

function viewReducer(prevViewState: ViewState, action: AnalysisAction): ViewState {
  if (action.type === ActionType.SetEvaluationDate) {
    return prevViewState.merge({
      evaluationDate: action.date,
      widgetData: mapWithRefreshRequired(prevViewState.widgetData, prevViewState.view.widgets),
    });
  } else if (action.type === ActionType.SetComputeEvaluationDateTime) {
    return prevViewState.merge({
      computeEvaluationDateTime: action.dateTime,
      widgetData: mapWithRefreshRequired(prevViewState.widgetData, prevViewState.view.widgets),
    });
  } else if (action.type === ActionType.SetUserRole) {
    return prevViewState.merge({
      widgetData: mapWithRefreshRequired(prevViewState.widgetData, prevViewState.view.widgets).map(
        item => ({...item, data: null})
      ),
    });
  } else if (action.type === ActionType.SetPresentationFile) {
    return prevViewState.merge({
      view: mapView(prevViewState.view, action.file),
      widgetData: mapWithRefreshRequired(prevViewState.widgetData, prevViewState.view.widgets),
    });
  } else if (action.type === ActionType.SetDefaultEvaluationPeriod) {
    const widgets = replacePeriodsInWidgets(
      prevViewState.view.widgets,
      prevViewState.view.evaluationPeriod,
      action.period
    );
    const view = {...prevViewState.view, evaluationPeriod: action.period, widgets};
    return prevViewState.merge({
      view,
      widgetData: prevViewState.view.widgets.map((widget, index) =>
        Widgets[widget.type].dataType === WidgetDataType.ATTRIBUTE
          ? prevViewState.widgetData[index]
          : toDefaultWidgetData(widget)
      ),
    });
  } else if (action.type === ActionType.SetUnitConversionAttribute) {
    const widgets = replaceUnitConversionAttributesInWidgets(
      action.settings,
      action.availableMetrics,
      prevViewState.view.widgets,
      prevViewState.view.unitConversionAttribute,
      action.unitConversionAttribute,
      prevViewState.view.currency
    );
    const view = {
      ...prevViewState.view,
      unitConversionAttribute: action.unitConversionAttribute,
      widgets,
    };
    return prevViewState.merge({
      view,
      widgetData: prevViewState.view.widgets.map((widget, index) =>
        Widgets[widget.type].dataType === WidgetDataType.ATTRIBUTE
          ? prevViewState.widgetData[index]
          : toDefaultWidgetData(widget)
      ),
    });
  } else if (action.type === ActionType.SetDashboardCurrency) {
    const widgets = replaceCurrencyArgumentInWidgets(prevViewState.view.widgets, action.currency);
    const view: Types.View = {
      ...prevViewState.view,
      currency: action.currency,
      widgets,
    };
    return prevViewState.merge({
      view,
      widgetData: prevViewState.view.widgets.map((widget, index) =>
        Widgets[widget.type].dataType === WidgetDataType.ATTRIBUTE
          ? prevViewState.widgetData[index]
          : toDefaultWidgetData(widget)
      ),
    });
  } else if (action.type === ActionType.SetGlobalFilters) {
    return equal(action.filters, prevViewState.view.filters)
      ? prevViewState
      : prevViewState.merge({
          view: {
            ...prevViewState.view,
            calendar: getNextCalendar(
              action.partners,
              action.filters,
              action.vendorCalendar,
              action.isCalendarAutoSwitchingEnabled,
              prevViewState
            ),
            filters: action.filters ? mergeAttributeFilters(action.filters) : action.filters,
          },
          widgetData: mapWithRefreshRequired(prevViewState.widgetData, prevViewState.view.widgets),
        });
  } else if (action.type === ActionType.SetSelectedExperiment) {
    return prevViewState.set('selectedExperiment', action.experiment);
  } else if (action.type === ActionType.RefreshCalendarEventWidgets) {
    return prevViewState.merge({
      widgetData: prevViewState.widgetData.map((item, index) => {
        const widget = prevViewState.view.widgets[index];
        return Widgets[widget.type].supportsCalendarEvents || hasEventData(widget)
          ? withRefreshRequired(item, widget)
          : item;
      }),
    });
  } else if (action.type === ActionType.ClearSelectionFilters) {
    return prevViewState.merge({
      selectionFiltersByWidgetIndex: isNonNullish(action.forWidgetIndex)
        ? prevViewState.selectionFiltersByWidgetIndex.filter(
            (_, widgetIndex) => widgetIndex !== action.forWidgetIndex
          )
        : Map(),
      widgetData: prevViewState.widgetData.map<WidgetData<unknown>>((data, index) => {
        const widgetMetadata = Widgets[prevViewState.view.widgets[index].type];
        const canRefresh = !widgetMetadata.isContainer && !widgetMetadata.isSidebar;
        return {
          ...data,
          needsRefresh:
            data.needsRefresh ||
            (canRefresh && haveOtherFiltersChanged(prevViewState, Map(), index)),
        };
      }),
    });
  } else if (action.type === ActionType.SetSelectionFiltersForWidget) {
    const widgetIndicesToRemove = [
      ...(Widgets[prevViewState.view.widgets[action.widgetIndex].type].isFilterWidget
        ? getWidgetIndicesWithConflictingSelectionFilters(
            action.filters,
            prevViewState.selectionFiltersByWidgetIndex
          )
        : []),
      action.widgetIndex,
    ];
    const selectionFiltersAfterRemoval: Map<
      number,
      ReadonlyArray<Types.AttributeFilter>
    > = prevViewState.selectionFiltersByWidgetIndex.removeAll(widgetIndicesToRemove);
    const nextSelectionFilters = action.filters.length
      ? selectionFiltersAfterRemoval.set(action.widgetIndex, action.filters)
      : selectionFiltersAfterRemoval.delete(action.widgetIndex);

    return prevViewState
      .merge({
        selectionFiltersByWidgetIndex: nextSelectionFilters,
      })
      .merge({
        widgetData: prevViewState.widgetData.map<WidgetData<unknown>>((data, index) => {
          const widgetMetadata = Widgets[prevViewState.view.widgets[index].type];
          const canRefresh = !widgetMetadata.isContainer && !widgetMetadata.isSidebar;
          return {
            ...data,
            needsRefresh:
              canRefresh &&
              (data.needsRefresh ||
                (index !== action.widgetIndex &&
                  canPropagateSelection(prevViewState.view) &&
                  haveOtherFiltersChanged(prevViewState, nextSelectionFilters, index))),
          };
        }),
      });
  } else if (action.type === ActionType.SetEditingLayout) {
    return prevViewState.set('isEditingLayout', action.isEditingLayout);
  } else if (action.type === ActionType.SetEditingWidget) {
    return prevViewState.merge({
      editingWidgetIndex: action.widgetIndex,
      originalWidgets:
        prevViewState.editingWidgetIndex === null && action.widgetIndex !== null
          ? prevViewState.view.widgets
          : prevViewState.originalWidgets,
      proposedWidgets:
        prevViewState.editingWidgetIndex === null
          ? prevViewState.view.widgets
          : prevViewState.proposedWidgets,
    });
  } else if (action.type === ActionType.SetEditingWidgetSubscription) {
    return prevViewState.set('editingWidgetSubscriptionIndex', action.widgetIndex);
  } else if (action.type === ActionType.SetViewWidgetFilesIndex) {
    return prevViewState.set('viewWidgetFilesIndex', action.widgetIndex);
  } else if (action.type === ActionType.SetExpandedWidget) {
    return prevViewState.set('expandedWidgetIndex', action.widgetIndex);
  } else if (action.type === ActionType.SelectWidget) {
    if (action.widgetIndex === null) {
      return prevViewState.merge({editingWidgetIndex: null, expandedWidgetIndex: null});
    }
    if (prevViewState.editingWidgetIndex !== null) {
      return prevViewState.set('editingWidgetIndex', action.widgetIndex);
    } else if (prevViewState.expandedWidgetIndex !== null) {
      return prevViewState.set('expandedWidgetIndex', action.widgetIndex);
    }
  } else if (action.type === ActionType.SetPeriodChooserOpen) {
    return prevViewState.set('isPeriodChooserOpen', action.isChooserOpen);
  } else if (action.type === ActionType.SetViewMenuOpen) {
    return prevViewState.set('isViewMenuOpen', action.isMenuOpen);
  } else if (action.type === ActionType.SetWidgetConfig) {
    const widgets = replaceAt(prevViewState.view.widgets, action.index, action.config);
    const widgetData = {
      ...prevViewState.widgetData[action.index],
      data: null as unknown,
      ephemeralData: {},
      needsRefresh: !Widgets[action.config.type].isContainer,
    };
    const newView: Types.View = {
      ...prevViewState.view,
      widgets,
    };
    return prevViewState.merge({
      view: newView,
      widgetData: replaceAt(prevViewState.widgetData, action.index, widgetData),
    });
  } else if (action.type === ActionType.SetWidgetConfigWithoutUpdate) {
    const previousWidgetConfig = prevViewState.view.widgets[action.index];
    const newWidgetConfig = {...previousWidgetConfig, ...action.partialConfig};
    const viewWidgets = replaceAt(prevViewState.view.widgets, action.index, newWidgetConfig);

    // We need to update proposed widgets because if this action gets dispatched when we are editing
    // a widget, then we would lose those updates when the widget editor changes are applied (copies
    // proposed widgets over to the view widgets).
    const previousProposedWidgetConfig = prevViewState.view.widgets[action.index];
    const newProposedWidgetConfig = {...previousProposedWidgetConfig, ...action.partialConfig};
    const proposedWidgets = replaceAt(
      prevViewState.proposedWidgets,
      action.index,
      newProposedWidgetConfig
    );

    return prevViewState.merge({
      view: {...prevViewState.view, widgets: viewWidgets},
      proposedWidgets,
    });
  } else if (action.type === ActionType.SetProposedWidgetTreeItem) {
    const oldTree = getWidgetTree(prevViewState.view.widgets, prevViewState.widgetData);
    const newTree = oldTree.set(action.treeIndex, action.data);

    const oldWidgets = prevViewState.view.widgets;
    const newWidgets = getFlattenedWidgetTree(newTree);
    const removedWidgetCount = Math.max(0, oldWidgets.length - newWidgets.length);
    const isEditing =
      prevViewState.editingWidgetIndex !== null && prevViewState.expandedWidgetIndex !== null;
    if (!isEditing) {
      return applyWidgetTreeToState(newTree, prevViewState).set('proposedWidgets', newWidgets);
    }

    const widgetIndex = treeIndexToActualIndex(newTree, action.treeIndex);
    const newSubtree = getFlattenedWidgetTree(List.of(action.data));
    const oldSubtree = getFlattenedWidgetTree(List.of(oldTree.get(action.treeIndex)!));

    const findNewIndex = (oldIndex: number): number => {
      const oldIndexInSubtree = oldIndex - widgetIndex;
      if (oldIndexInSubtree <= 0) {
        return oldIndex;
      }
      if (oldIndexInSubtree >= oldSubtree.length) {
        return oldIndex - removedWidgetCount;
      }
      if (newSubtree.length === 1) {
        return widgetIndex;
      }
      const newIndexInSubtree = newSubtree.findIndex(widget =>
        widgetsEqual(widget, oldWidgets[oldIndex])
      );
      if (newIndexInSubtree === -1) {
        // The widget wasn't found within the subtree, so we try to pick an adjacent widget,
        // ignoring any additional nesting structure.
        return widgetIndex + clip(oldIndexInSubtree - 1, 1, newSubtree.length - 1);
      }
      return widgetIndex + newIndexInSubtree;
    };

    return applyWidgetTreeToState(newTree, prevViewState).merge({
      editingWidgetIndex: findNewIndex(prevViewState.editingWidgetIndex),
      expandedWidgetIndex: findNewIndex(prevViewState.expandedWidgetIndex),
      proposedWidgets: newWidgets,
    });
  } else if (action.type === ActionType.SetSelectedCalendarDate) {
    const config = prevViewState.view.widgets[action.widgetIndex];
    if (Widgets[config.type].dataType !== 'EVENT') {
      return prevViewState;
    }
    const prevData = prevViewState.widgetData[action.widgetIndex] as EventWidgetData;
    const newData: EventWidgetData = {
      ...prevData,
      ephemeralData: {
        currentDate: action.date,
      },
      needsRefresh: true,
    };

    return prevViewState.update('widgetData', widgetData =>
      replaceAt(widgetData, action.widgetIndex, newData)
    );
  } else if (action.type === ActionType.CopyWidget) {
    const tree = getWidgetTree(prevViewState.view.widgets, prevViewState.widgetData);
    const newTree = tree.insert(action.toTreeIndex, copyWidget(tree.get(action.fromTreeIndex)!));
    return applyWidgetTreeToState(newTree, prevViewState);
  } else if (action.type === ActionType.MoveWidget) {
    const tree = getWidgetTree(prevViewState.view.widgets, prevViewState.widgetData);
    const newTree = moveItem(tree.toArray(), action.fromTreeIndex, action.toTreeIndex);
    return applyWidgetTreeToState(List(newTree), prevViewState);
  } else if (action.type === ActionType.MoveWidgetChild) {
    const isEditing =
      prevViewState.editingWidgetIndex !== null && prevViewState.expandedWidgetIndex !== null;
    if (!isEditing) {
      // not a hard requirement, but aligns with how the app should function
      throw new Error('Child widgets cannot be rearranged when not editing');
    }

    const widgetTree = getWidgetTree(prevViewState.view.widgets, prevViewState.widgetData);
    const proposedWidgetTree = getWidgetTree(
      prevViewState.proposedWidgets,
      prevViewState.widgetData
    );
    if (
      !isParentWidget(widgetTree.get(action.widgetTreeIndex)) ||
      !isParentWidget(proposedWidgetTree.get(action.widgetTreeIndex))
    ) {
      throw new Error('Widget has no children to rearrange');
    }

    const moveChildWidgets = (tree: List<WidgetLayoutData>): List<WidgetLayoutData> => {
      const childWidgets = moveItem(
        tree.get(action.widgetTreeIndex)!.children!.toArray(),
        action.fromChildIndex,
        action.toChildIndex
      );
      return tree.update(action.widgetTreeIndex, widget => ({
        ...widget,
        children: List.of(...childWidgets),
      }));
    };

    const newWidgetTree = moveChildWidgets(widgetTree);
    const newProposedWidgetTree = moveChildWidgets(proposedWidgetTree);
    const newWidgetIndex =
      treeIndexToActualIndex(newWidgetTree, action.widgetTreeIndex) + action.toChildIndex + 1;
    return applyWidgetTreeToState(newWidgetTree, prevViewState).merge({
      editingWidgetIndex: newWidgetIndex,
      expandedWidgetIndex: newWidgetIndex,
      proposedWidgets: getFlattenedWidgetTree(newProposedWidgetTree),
    });
  } else if (action.type === ActionType.DeleteWidget) {
    const widgets = prevViewState.view.widgets;
    const tempTree = getWidgetTreeWithoutData(widgets);
    const indexToDelete = isChildWidget(widgets, action.widgetIndex)
      ? treeIndexToActualIndex(tempTree, getParentWidgetIndex(tempTree, action.widgetIndex))
      : action.widgetIndex;
    const newTree = getWidgetTree(widgets, prevViewState.widgetData, indexToDelete);
    const newWidgets = getFlattenedWidgetTree(newTree);

    return prevViewState.merge({
      editingWidgetIndex: null,
      expandedWidgetIndex: null,
      proposedWidgets: newWidgets,
      view: {...prevViewState.view, widgets: newWidgets},
      widgetData: getFlattenedWidgetDataTree(newTree),
    });
  } else if (action.type === ActionType.SetOriginalWidgets) {
    return prevViewState.set('originalWidgets', action.widgets);
  } else if (action.type === ActionType.SetProposedWidgets) {
    return prevViewState.set('proposedWidgets', action.widgets);
  } else if (action.type === ActionType.SetWidgets) {
    const needsFullRefresh =
      action.widgets.length !== prevViewState.view.widgets.length ||
      (!!action.calendar && action.calendar !== prevViewState.view.calendar) ||
      !!action.forceRefreshAllWidgets;
    const nextSelectionFilters = isPlanningView(prevViewState.view)
      ? prevViewState.selectionFiltersByWidgetIndex
      : prevViewState.selectionFiltersByWidgetIndex.filter((_, widgetIndex) =>
          equal(action.widgets[widgetIndex], prevViewState.view.widgets[widgetIndex])
        );
    return prevViewState.merge({
      selectionFiltersByWidgetIndex: nextSelectionFilters,
      view: {
        ...prevViewState.view,
        widgets: action.widgets,
        calendar: action.calendar || prevViewState.view.calendar,
      },
      widgetData: action.widgets.map((newWidget, widgetIndex) => {
        if (needsFullRefresh) {
          return toDefaultWidgetData(newWidget);
        }
        return widgetsEqual(newWidget, prevViewState.view.widgets[widgetIndex])
          ? {
              ...prevViewState.widgetData[widgetIndex],
              needsRefresh:
                prevViewState.widgetData[widgetIndex].needsRefresh ||
                haveOtherFiltersChanged(prevViewState, nextSelectionFilters, widgetIndex),
            }
          : toDefaultWidgetData(newWidget);
      }),
    });
  } else if (action.type === ActionType.AddWidgetToEnd) {
    return prevViewState.merge({
      view: {
        ...prevViewState.view,
        widgets: [...prevViewState.view.widgets, action.widgetConfig],
      },
      widgetData: [
        ...prevViewState.widgetData,
        {
          ...createDefaultWidgetData(),
          needsRefresh: !Widgets[action.widgetConfig.type].isContainer,
        },
      ],
    });
  }
  return prevViewState;
}

function viewsReducer(analysisState: AnalysisState, action: AnalysisAction): List<ViewState> {
  const state = analysisState.views;

  if (action.type === ActionType.SetWidgetData) {
    if (action.viewIndex >= state.size) {
      return state;
    }
    const prevViewState = state.get(action.viewIndex)!;

    const matchingIndices = Set(
      prevViewState.view.widgets
        .map((widget, index) => ({widget, index}))
        .filter(item => equal(item.widget, action.dataKey))
        .map(item => item.index)
    );
    if (matchingIndices.isEmpty()) {
      return state; // matching config no longer found; ignore
    }

    return state.set(
      action.viewIndex,
      prevViewState.update('widgetData', widgetData =>
        widgetData.map((data, index) =>
          matchingIndices.contains(index) ? updateWidgetState(data, action) : data
        )
      )
    );
  } else if (action.type === ActionType.ReplaceCurrentViews) {
    const oldViewsSlice = state.slice(0, action.views.size);
    const newViewsSlice = action.views.slice(state.size);

    const defaultEvaluationDate = getVendorEvaluationDate(action.analysisSettings.evaluationDate);
    const viewParams = action.viewParams || List.of();

    const newViews = newViewsSlice.map(
      (view, index) =>
        new ViewState({
          ...getDefaultViewStateProps(view, viewParams.get(oldViewsSlice.size + index)),
          evaluationDate: defaultEvaluationDate,
          expandedWidgetIndex: null,
          widgetData: view.widgets.map(toDefaultWidgetData),
        })
    );

    return oldViewsSlice
      .map((prevViewState, index) => {
        const newView = action.views.get(index)!;
        return prevViewState.merge({
          ...getDefaultViewStateProps(newView, viewParams.get(index)),
          evaluationDate:
            newView.id !== null && equal(newView.id, state.get(index)!.view.id)
              ? state.get(index)!.evaluationDate
              : defaultEvaluationDate,
          computeEvaluationDateTime: equal(newView, state.get(index)!.view)
            ? state.get(index)!.computeEvaluationDateTime
            : null,
          expandedWidgetIndex: null,
          widgetData: getWidgetDataToUpdate(prevViewState, newView),
        });
      })
      .concat(newViews);
  } else if (action.type === ActionType.SetViews) {
    return action.newViews ?? state;
  }

  // The model supports any number of stacked views, but aside from the above
  // actions, the reducer actions are taken on the active view.
  // This is ok as long as the actions are not asynchronous.
  const viewIndex = state.size - 1;
  const prevViewState = state.get(viewIndex)!;
  return state.set(viewIndex, viewReducer(prevViewState, action));
}

function dataReducer(analysisState: AnalysisState, action: AnalysisAction): AnalysisData {
  const state = analysisState.data;
  if (action.type === ActionType.SetAvailableCalendarEvents) {
    if (isLoading(action)) {
      return {...state, fetchingAvailableCalendarEventsStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, fetchingAvailableCalendarEventsStatus: Status.failed};
    }
    return {
      ...state,
      availableCalendarEvents: action.data!,
      fetchingAvailableCalendarEventsStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.SetAvailableThinViews) {
    if (isLoading(action)) {
      return {...state, fetchingThinViewsStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, fetchingThinViewsStatus: Status.failed};
    }
    const vendorThinViews = Map<number, Types.ThinView>(
      action.data!.vendorViews.map<[number, Types.ThinView]>(view => [assertTruthy(view.id), view])
    );
    const publicThinViews = Map<number, Types.ThinView>(
      action.data!.publicViews.map<[number, Types.ThinView]>(view => [assertTruthy(view.id), view])
    );
    return {
      ...state,
      vendorThinViews,
      publicThinViews,
      fetchingThinViewsStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.ClearVendorViews) {
    return {
      ...state,
      viewsCache: Map(),
      vendorThinViews: Map(),
    };
  } else if (action.type === ActionType.AddVendorViews) {
    return {
      ...state,
      viewsCache: state.viewsCache.merge(
        Map(action.vendorViews.map(view => [assertTruthy(view.id), view]))
      ),
      vendorThinViews: state.vendorThinViews.merge(
        Map(action.vendorViews.map(view => [assertTruthy(view.id), toThinView(view)]))
      ),
    };
  } else if (action.type === ActionType.CreateOrUpdateView) {
    const existingView = state.viewsCache.find(view => view.id === action.view.id);
    const existingViewId = action.isNewView || !existingView?.id ? 0 : existingView.id;
    return {
      ...state,
      viewsCache: state.viewsCache.delete(existingViewId).set(action.view.id || 0, action.view),
      vendorThinViews: state.vendorThinViews
        .delete(existingViewId)
        .set(action.view.id || 0, toThinView(action.view)),
    };
  } else if (action.type === ActionType.CreateOrUpdateViews) {
    const newAvailableViews = Map<number, Types.View>(
      action.views.map<[number, Types.View]>(view => [assertTruthy(view.id), view])
    );
    const newVendorThinViews = Map<number, Types.ThinView>(
      action.views.map<[number, Types.ThinView]>(view => [assertTruthy(view.id), toThinView(view)])
    );
    return {
      ...state,
      viewsCache: state.viewsCache.merge(newAvailableViews),
      vendorThinViews: state.vendorThinViews.merge(newVendorThinViews),
    };
  } else if (action.type === ActionType.DeleteView) {
    return {
      ...state,
      viewsCache: state.viewsCache.delete(action.viewId),
      vendorThinViews: state.vendorThinViews.delete(action.viewId),
      favoriteViews: state.favoriteViews.delete(action.viewId),
      viewLinks: state.viewLinks.filter(
        link =>
          link.sourceViewId !== action.viewId &&
          (link.type !== 'ATTRIBUTE' || link.targetViewId !== action.viewId) &&
          (link.type !== 'METRIC' || link.targetViewId !== action.viewId)
      ),
    };
  } else if (action.type === ActionType.DeleteEvent) {
    return {
      ...state,
      availableCalendarEvents: removeAt(
        state.availableCalendarEvents,
        state.availableCalendarEvents.findIndex(event => event.id === action.eventId)
      ),
    };
  } else if (action.type === ActionType.UpdateEvent) {
    return {
      ...state,
      availableCalendarEvents: replaceAt(
        state.availableCalendarEvents,
        state.availableCalendarEvents.findIndex(event => event.id === action.event.id),
        action.event
      ),
    };
  } else if (action.type === ActionType.CreateEvent) {
    return {
      ...state,
      availableCalendarEvents: [...state.availableCalendarEvents, action.event],
    };
  } else if (action.type === ActionType.SetAllGroupings) {
    if (isLoading(action)) {
      return {
        ...state,
        fetchingAllGroupingsStatus: Status.inProgress,
        allGroupingsRequest: action.promise,
      };
    } else if (isFail(action)) {
      return {...state, fetchingAllGroupingsStatus: Status.failed, allGroupingsRequest: null};
    }
    return {
      ...state,
      allGroupings: action.data!,
      fetchingAllGroupingsStatus: Status.succeeded,
      allGroupingsRequest: null,
    };
  } else if (action.type === ActionType.SetDefaultHierarchy) {
    return {
      ...state,
      defaultAttributeHierarchies: state.defaultAttributeHierarchies.set(
        action.hierarchyType,
        action.attributes
      ),
    };
  } else if (action.type === ActionType.SetAvailableMetrics) {
    if (isLoading(action)) {
      return {
        ...state,
        fetchingMetricsStatus: Status.inProgress,
        allMetricsRequest: action.promise,
      };
    } else if (isFail(action)) {
      return {
        ...state,
        fetchingMetricsStatus: Status.failed,
        allMetricsRequest: null,
      };
    }
    const metricResponse = action.data!;
    const metricNamesWithAnyData = Set(metricResponse.metricsWithAnyData);
    const availableMetricNamesByCategory = parseMetricData(
      metricResponse.allMetrics,
      metricNamesWithAnyData
    );
    return {
      ...state,
      fetchingMetricsStatus: Status.succeeded,
      allMetrics: toMetricMap(metricResponse.allMetrics),
      allMetricsRequest: null,
      metricNamesWithAnyData,
      availableMetricNamesByCategory,
    };
  } else if (action.type === ActionType.SetAvailableForecastsByMetricName) {
    if (!isLoading(action) && !isFail(action)) {
      return {
        ...state,
        availableForecastTypesByMetricName: Map(action.data!).map(types => Set(types)),
      };
    }
  } else if (action.type === ActionType.SetAvailableMetricDescriptions) {
    if (!isLoading(action) && !isFail(action)) {
      return {...state, metricDescriptions: toMetricDescriptionMap(action.data)};
    }
  } else if (action.type === ActionType.SetPartners) {
    if (isLoading(action)) {
      return {...state, partnersRequest: action.promise};
    } else if (isFail(action)) {
      return {...state, partnersRequest: null};
    } else {
      return {
        ...state,
        partners: Map<number, Types.Partner>(
          action.data!.map(partner => [assertTruthy(partner.id), partner])
        ),
        partnersRequest: null,
      };
    }
  } else if (action.type === ActionType.SetViewFavoriteStatus) {
    return {
      ...state,
      favoriteViews: action.isFavorite
        ? state.favoriteViews.add(action.viewId)
        : state.favoriteViews.delete(action.viewId),
    };
  } else if (action.type === ActionType.SetFavoriteViews) {
    return {
      ...state,
      favoriteViews: action.views.map(view => assertTruthy(view.id)).toSet(),
    };
  } else if (action.type === ActionType.SetViewLinks) {
    return {...state, viewLinks: action.viewLinks};
  } else if (action.type === ActionType.SetDoubleCountingPartners) {
    return {...state, doubleCountingPartners: action.doubleCountingPartners};
  } else if (action.type === ActionType.SaveTag) {
    return {
      ...state,
      viewsCache: state.viewsCache.map(view => replaceViewTag(view, action)),
      vendorThinViews: state.vendorThinViews.map(view => replaceViewTag(view, action)),
    };
  } else if (action.type === ActionType.DeleteTag) {
    return {
      ...state,
      viewsCache: state.viewsCache.map(view => deleteViewTag(view, action)),
      vendorThinViews: state.vendorThinViews.map(view => deleteViewTag(view, action)),
    };
  } else if (action.type === ActionType.SetPresentationFile) {
    const {partnerMappings: partnerMappings} = action.file;
    return {
      ...state,
      viewsCache: state.viewsCache.map(view => mapView(view, action.file)),
      partners: state.partners.map(partner => ({
        ...partner,
        name: partnerMappings[partner.name] || partner.name,
      })),
    };
  } else if (action.type === ActionType.SetEdgeDataRanges) {
    if (!isLoading(action) && !isFail(action)) {
      return {...state, edgeDataRanges: action.data!};
    }
  }
  return state;
}

function baseReducer(state: AnalysisState, action: AnalysisAction) {
  if (action.type === ActionType.ClearUnsavedView) {
    return {
      ...state,
      unsavedView: null,
      views: state.unsavedView
        ? state.views.map(viewState =>
            viewState.view.id === state.unsavedView?.id ? new ViewState() : viewState
          )
        : state.views,
    };
  } else if (action.type === ActionType.SetViews) {
    const currentView = state.views.first()!.view;
    const params = getViewParamsFromLocation(action.location.search!) || List.of();
    if (
      currentView?.name &&
      // Allow static dashboard page to prevent alerts by giving dashboards a non-existing id
      (!currentView.id || state.data.viewsCache.has(currentView.id)) &&
      // Don't show the dialog if the user had built a drilldown context or is navigating into one.
      // In a drilldown, the user cannot modify the views anyway, and if the user pivots to
      // another view to look at the same data (so the view they're going to has params), saving
      // the previous view doesn't make sense.
      state.views.size === 1 &&
      ViewUrlParams.isEmpty(state.views.first()!.viewParams) &&
      (params.isEmpty() || ViewUrlParams.isEmpty(params.first()))
    ) {
      return {
        ...state,
        unsavedView: getUnsavedView(
          state.views.last(),
          state.data.vendorThinViews.get(action.newViews?.get(0)?.view.id) ?? null,
          state.data.viewsCache.get(currentView.id)!,
          state.data.vendorThinViews
        ),
      };
    }
  } else if (action.type === ActionType.ClearViewChanges) {
    const evaluationDate =
      (!state.views.isEmpty() && state.views.first()!.evaluationDate) ||
      getVendorEvaluationDate(action.analysisSettings.evaluationDate);
    return {
      ...state,
      views: state.views.map(viewState => {
        const viewWithoutChanges =
          isSaveableViewId(viewState.view.id) && state.data.viewsCache.get(viewState.view.id);
        if (!viewWithoutChanges) {
          return viewState;
        }
        return getStateFromLocationChange(
          viewState,
          evaluationDate,
          viewWithoutChanges,
          null,
          null
        );
      }),
    };
  }
  return state;
}

export default function analysis(prevState = defaultAnalysisState, action: AnalysisAction) {
  const state = baseReducer(prevState, action);
  return {...state, data: dataReducer(state, action), views: viewsReducer(state, action)};
}
