import './UserMenu.scss';

import classNames from 'classnames';
import React, {useCallback} from 'react';
import {Overlay} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/Overlay';
import {NavLink} from 'react-router-dom';
import {compose} from 'redux';

import * as Api from 'api';
import Analytics from 'app/analytics/analytics';
import {changePermission} from 'app/GlobalHotKeys';
import {useCurrentUser} from 'redux/context/user';
import {CurrentUser} from 'redux/reducers/user';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import Boundary from 'toolkit/components/Boundary';
import {ConfigLabelDisplayVariant} from 'toolkit/components/ConfigLabel';
import ContextMenuPopover from 'toolkit/components/ContextMenuPopover';
import CopyableText from 'toolkit/components/CopyableText';
import ExternalLink from 'toolkit/components/ExternalLink';
import FieldsetColumn from 'toolkit/components/FieldsetColumn';
import Icon from 'toolkit/components/Icon';
import OptionChooser, {Option} from 'toolkit/components/OptionChooser';
import {openZendeskWidget} from 'toolkit/components/SupportLink';
import VendorSelect from 'toolkit/components/VendorSelect';
import {getPackageOverride} from 'toolkit/permissisons/utils';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';
import {isAtLeast, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {defaultOverlayProps} from 'toolkit/utils/react-bootstrap';
import * as Types from 'types';
import {getCurrentApiVersion} from 'utils/ajax';
import {useApi, useResult} from 'utils/useApi';

import {changeVendor, getNewVendorPathname} from './utils';

const permissionsOptions: ReadonlyArray<Option<Types.Role>> = [
  {label: 'User', value: Types.Role.USER},
  {label: 'Admin', value: Types.Role.ADMIN},
  {label: 'Super', tooltip: 'Super Admin', value: Types.Role.SUPER_ADMIN},
];

function trackLogoutEvent() {
  Analytics.track('Logout');
}

export const switchToVendor = (
  vendor: Types.Vendor | null,
  openInNewWindow: boolean,
  currentUser: CurrentUser
) => {
  if (!vendor) {
    return;
  }

  if (openInNewWindow) {
    window.open(getNewVendorPathname(currentUser, vendor), '_blank');
  } else {
    changeVendor(currentUser, vendor);
  }
};

const PackageLink: React.FC = () => {
  const currentUser = useCurrentUser();
  const overridePackageId =
    !!getPackageOverride() && getPackageOverride() !== currentUser.vendor.packageId
      ? getPackageOverride()
      : null;
  const applicationPackages = useResult(
    useApi(Api.Authorization.getApplicationPackages.getResource())
  );
  const applicationPackage = applicationPackages.find(
    pkg => pkg.id === currentUser.vendor.packageId
  );
  const overridePackage = applicationPackages.find(pkg => pkg.id === overridePackageId);

  return (
    <ExternalLink
      className="no-hover"
      href="/ALLOY_PUBLIC/settings/permissions/package"
      showIcon={false}
    >
      {overridePackage ? (
        <>
          {overridePackage.name}{' '}
          {applicationPackage && (
            <span className="actual-package-name">(actual: {applicationPackage.name})</span>
          )}
        </>
      ) : (
        (applicationPackage?.name ?? 'None')
      )}
    </ExternalLink>
  );
};

export const UserMenuDevContent: React.FC = () => {
  const apiVersion = getCurrentApiVersion();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const environment = useSelector(state => state.environment.environment);

  return (
    <FieldsetColumn className="UserMenuDevContent">
      <fieldset>
        <legend>App Version</legend>
        <CopyableText monospace={false} showIcon={false} value={environment.version} borderless />
      </fieldset>
      {apiVersion !== environment.version && (
        <fieldset>
          <legend>API Version</legend>
          <CopyableText monospace={false} showIcon={false} value={apiVersion} borderless />
        </fieldset>
      )}
      {currentUser.vendor.id !== PUBLIC_VENDOR_ID && (
        <>
          <fieldset>
            <legend>Permissions</legend>
            <OptionChooser
              className="permission-chooser"
              options={permissionsOptions}
              selected={currentUser.user.role}
              onChange={(role: Types.Role) => changePermission(role, currentUser, dispatch)}
            />
          </fieldset>
          {isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) && (
            <fieldset>
              <legend>Package</legend>
              <Boundary>
                <PackageLink />
              </Boundary>
            </fieldset>
          )}
        </>
      )}
    </FieldsetColumn>
  );
};

const UserMenu: React.FC<Props> = ({placement = 'bottom-end', ...props}) => {
  const currentUser = useCurrentUser();
  const allowedVendors = useSelector(state => state.userData.allowedVendors);

  const handleEnterPress = (event: React.KeyboardEvent<HTMLElement>, callback: () => void) => {
    if (event.key === 'Enter') {
      props.onHide();
      callback();
    }
  };
  const handleChangeVendor = useCallback(
    (selectedVendor: Types.Vendor | null, openInNewWindow: boolean) =>
      switchToVendor(selectedVendor, openInNewWindow, currentUser),
    [currentUser]
  );

  return (
    <Overlay
      {...defaultOverlayProps}
      placement={placement}
      target={() => props.menuRef.current}
      show
      onHide={props.onHide}
    >
      <ContextMenuPopover className="UserMenu" id="topnav-user-menu">
        {currentUser.isDevelopmentMode && (
          <li className="unselectable-entry no-hover">
            <UserMenuDevContent />
          </li>
        )}
        {allowedVendors.size > 1 && (
          <li className="vendors-entry unselectable-entry no-hover">
            <VendorSelect
              allowedVendors={allowedVendors}
              className="vendor-select"
              configLabelDisplayVariant={ConfigLabelDisplayVariant.NO_BORDERS}
              floatOffsetPx={12}
              menuClassName="vendor-select-dropdown-content"
              selectedVendor={currentUser.vendor.id}
              autoFocus
              onSelect={handleChangeVendor}
            />
          </li>
        )}
        <NavLink
          className={classNames(getHideOnPhonesClassName())}
          to={`/${currentUser.vendor.name}/settings`}
          onClick={props.onHide}
        >
          <Icon className="entry-icon" icon="cog" />
          Settings
        </NavLink>
        <li
          className="contact-support-link"
          tabIndex={0}
          onClick={compose(openZendeskWidget, props.onHide)}
          onKeyUp={event =>
            handleEnterPress(event, () => {
              props.onHide();
              openZendeskWidget();
            })
          }
        >
          <Icon className="entry-icon" icon="concierge-bell" />
          Contact Support
        </li>
        <NavLink className="menu-item logout-item" to="/logout" onClick={trackLogoutEvent}>
          <Icon className="entry-icon" icon="sign-out-alt" />
          Sign Out
        </NavLink>
      </ContextMenuPopover>
    </Overlay>
  );
};
UserMenu.displayName = 'UserMenu';

export interface Props {
  menuRef: React.RefObject<HTMLDivElement>;
  onHide: () => void;
  placement?: Placement;
}

export default UserMenu;
