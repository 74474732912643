import Cookies from 'js-cookie';

const PACKAGE_OVERRIDE_COOKIE = 'application_package_override';
const PACKAGE_OVERRIDE_HEADER = 'X-Alloy-Application-Package-Override';

export function getPackageOverride(): number | null {
  return Cookies.getJSON(PACKAGE_OVERRIDE_COOKIE) ?? null;
}

export function getPackageOverrideHeaderEntry(): [string, string | undefined] {
  return [PACKAGE_OVERRIDE_HEADER, Cookies.get(PACKAGE_OVERRIDE_COOKIE)];
}

export function resetPackageOverrideCookie() {
  Cookies.remove(PACKAGE_OVERRIDE_COOKIE);
}

export function setPackageOverride(packageId: number | null, expires: Date) {
  if (!packageId) {
    resetPackageOverrideCookie();
  } else {
    Cookies.set(PACKAGE_OVERRIDE_COOKIE, JSON.stringify(packageId), {expires});
  }
}
