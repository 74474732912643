import {List, Set} from 'immutable';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';

import * as Api from 'api';
import Analytics from 'app/analytics/analytics';
import {setEndToEndTestBuildUrl} from 'redux/actions/ajax';
import {AnalysisDataActions, IAnalysisDataActions, setFavoriteViews} from 'redux/actions/analysis';
import {setEnvironment} from 'redux/actions/environment';
import {
  fetchExperiments,
  getFavoriteExperiments,
  setVisitedExperimentTimestampsForUser,
  setVisitedExperimentTimestampsForVendor,
} from 'redux/actions/groups';
import {fetchPlans, fetchPlanSettings} from 'redux/actions/plans';
import {CurrentUserActions} from 'redux/actions/user';
import {UserDataActions} from 'redux/actions/user-data';
import {withAnalysisDataActionCreators} from 'redux/context/analysis';
import {Dispatch, RootState} from 'redux/reducers';
import {CurrentUserState} from 'redux/reducers/user';
import useSelector from 'redux/selectors/useSelector';
import Centered from 'toolkit/components/Centered';
import ErrorPlaceholder from 'toolkit/components/ErrorPlaceholder';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {DATE_FORMAT} from 'toolkit/format/constants';
import {
  IPeriodEvaluationContext,
  PeriodEvaluationContext,
} from 'toolkit/time/PeriodEvaluationContext';
import {getVendorEvaluationDate} from 'toolkit/time/utils';
import {PUBLIC_VENDOR_ID, hasPermission} from 'toolkit/users/utils';
import * as Types from 'types';
import {getEndToEndTestBuildUrlFromCookie} from 'utils/cookies';
import {captureException, configureSentryUser, reloadAppOnError} from 'utils/exceptions';

import {promptUserToReload} from './alerts';
import {initializeSurvey} from './analytics/delighted';
import {configureIntercom} from './analytics/intercom';
import EmptyAppLoader from './EmptyAppLoader';

const MainLayout = React.lazy(() => import('./MainLayout').catch(reloadAppOnError));

const EVALUATION_DATE_CHECK_INTERVAL_MSEC = 5 * 60 * 1000;
const WithVendorDefaultPeriodEvaluationContext: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const settings = useSelector(
    state => state.user.settings.analysisSettings
  ) as Types.VendorAnalysisSettings;
  const calendar = settings?.calendar ?? Types.RetailCalendarEnum.NRF;

  const context: IPeriodEvaluationContext = {
    evaluationDate: getVendorEvaluationDate(settings?.evaluationDate),
    calendar,
  };

  useEffect(() => {
    const prevContextDate = context.evaluationDate.format(DATE_FORMAT);

    const interval = setInterval(() => {
      const evaluationDate = getVendorEvaluationDate(settings?.evaluationDate);
      const currentDate = evaluationDate.format(DATE_FORMAT);
      if (currentDate !== prevContextDate) {
        clearInterval(interval);
        promptUserToReload(
          `Your system date has changed. To reflect the change in dashboards, please reload Alloy.ai.`
        );
      }
    }, EVALUATION_DATE_CHECK_INTERVAL_MSEC);
    return () => clearInterval(interval);
  }, [calendar, context.evaluationDate, settings]);

  return (
    <PeriodEvaluationContext.Provider value={context}>{children}</PeriodEvaluationContext.Provider>
  );
};

class AppContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      bootstrapError: null,
    };
  }

  componentDidMount() {
    const vendorName = this.props.match.params.vendor;
    const e2eBuildUrl = getEndToEndTestBuildUrlFromCookie();
    if (e2eBuildUrl) {
      this.props.setEndToEndTestBuildUrl(e2eBuildUrl);
    }

    Api.Authentication.getAllowedVendors()
      .then(vendors => {
        const vendorsList = List(vendors);
        const actualVendor =
          vendorsList.find(v => v.name === vendorName) ||
          vendorsList.sortBy(vendor => vendor.id).first()!;
        this.props.setAllowedVendors(vendorsList);
        this.props.setCurrentVendor(actualVendor);
        if (actualVendor.name !== vendorName && !!vendorName) {
          const path = actualVendor.id === PUBLIC_VENDOR_ID ? '/settings/vendors' : '';
          this.props.history.push(`/${actualVendor.name}${path}`);
        }

        return this.initialize(vendorsList);
      })
      .catch(err => {
        captureException(err);
        this.setState(() => ({
          bootstrapError: err,
          isLoading: false,
        }));
      });

    // Preload the main app's code while the initial request is in-progress
    import('./MainLayout');
  }

  async initialize(allowedVendors: List<Types.Vendor>) {
    const allData = {
      currentUserData: Api.Authentication.getCurrentUser(),
      userIntercomCredentials: Api.Authentication.getIntercomCredentials(),
      doubleCountingPartners: Api.Partners.getDoubleCountingPartners(),
      environment: Api.Environment.getEnvironment(),
      featureFlags: Api.FeatureFlags.getMyFeatures(),
      vendorAnalysisSettings: Api.Metrics.getAnalysisSettings(),
    };

    this.props.fetchAllGroupings();
    this.props.fetchAvailableMetrics();
    this.props.fetchAvailableMetricDescriptions();
    this.props.fetchPartners();
    this.initializeUser(await allData.currentUserData, await allData.featureFlags, allowedVendors);

    this.props.setDoubleCountingPartners(await allData.doubleCountingPartners);
    this.props.setEnvironment(await allData.environment);
    this.props.setVendorAnalysisSettings(await allData.vendorAnalysisSettings);

    this.setState({isLoading: false}, async () => {
      if (hasPermission(this.props.currentUser!, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD)) {
        const viewData = {
          viewLinks: Api.Views.getViewLinks(),
          favoriteViews: Api.Views.getFavoriteThinViews().then(views => List(views)),
        };
        this.props.fetchAllThinViews();
        this.props.setViewLinks(await viewData.viewLinks);
        this.props.setFavoriteViews(await viewData.favoriteViews);
      }

      this.props.fetchAllCalendarEvents();
      this.props.fetchAvailableForecasts();
      this.props.fetchEdgeDataRanges();

      if (hasPermission(this.props.currentUser!, Types.PermissionKey.EXPERIMENT_VIEW_EXPERIMENT)) {
        this.props.fetchExperiments();
        this.props.getFavoriteExperiments();
        Api.Experiments.getVisitedExperimentTimestampsForUser().then(
          this.props.setVisitedExperimentTimestampsForUser
        );
        Api.Experiments.getVisitedExperimentTimestampsForVendor().then(
          this.props.setVisitedExperimentTimestampsForVendor
        );
      }

      if (this.props.currentUser!.vendor!.isDemandPlanningEnabled) {
        this.props.fetchPlans();
        this.props.fetchPlanSettings();
      }
    });
    configureSentryUser(await allData.currentUserData);
    configureIntercom(
      await allData.currentUserData,
      await allData.userIntercomCredentials,
      allowedVendors,
      await allData.environment,
      await allData.featureFlags
    );
  }

  initializeUser = (
    userData: Types.CurrentUserData,
    featureFlags: ReadonlyArray<string>,
    allowedVendors: List<Types.Vendor>
  ) => {
    const user = userData.user;
    const vendor = allowedVendors.find(vendor => vendor.id === user.vendorId);
    if (!vendor) {
      throw new Error(`Vendor not found for id ${user.vendorId}`);
    }
    this.props.setCurrentUser(user);
    this.props.setCurrentUserInfo(userData.userInfo);
    this.props.setPermissions(userData.permissionKeys);
    this.props.setUserFeatureFlags(Set<FeatureFlag>(featureFlags.map(f => f as FeatureFlag)));

    Analytics.identify(user, userData.userInfo, vendor);
    Analytics.track('Screen Dimensions', {
      value: `${window.screen.width} x ${window.screen.height}`,
    });

    initializeSurvey(user, userData.userInfo, vendor);
  };

  render() {
    const isLoading =
      this.state.isLoading || !this.props.currentUser?.user || !this.props.currentUser?.vendor;
    return isLoading ? (
      <EmptyAppLoader />
    ) : this.state.bootstrapError ? (
      <Centered className="error-mask">
        <ErrorPlaceholder error={this.state.bootstrapError} />
      </Centered>
    ) : (
      <React.Suspense fallback={<EmptyAppLoader />}>
        <WithVendorDefaultPeriodEvaluationContext>
          {this.props.children || <MainLayout location={this.props.location} />}
        </WithVendorDefaultPeriodEvaluationContext>
      </React.Suspense>
    );
  }
}

type Props = IAnalysisDataActions &
  RouteComponentProps<any> & {
    fetchExperiments: () => void;
    fetchPlans: () => void;
    fetchPlanSettings: () => void;
    getFavoriteExperiments: () => void;
    setAllowedVendors: (vendors: List<Types.Vendor>) => any;
    setCurrentUser: (user: Types.User) => any;
    setCurrentUserInfo: (userInfo: Types.UserInfo) => any;
    setCurrentVendor: (vendor: Types.Vendor) => any;
    setEnvironment: (configuration: Types.Environment) => any;
    setEndToEndTestBuildUrl: (e2eBuildUrl: string) => any;
    setPermissions: (permissionKeys: ReadonlyArray<string>) => any;
    setVisitedExperimentTimestampsForUser: (
      timestamps: ReadonlyArray<Types.VisitedTimestamp>
    ) => void;
    setVisitedExperimentTimestampsForVendor: (
      timestamps: ReadonlyArray<Types.VisitedTimestamp>
    ) => void;
    setFavoriteViews: (favoriteViewIds: List<Types.ThinView>) => any;
    setUserFeatureFlags: (featureFlags: Set<FeatureFlag>) => any;
    setVendorAnalysisSettings: (analysisSettings: Types.VendorAnalysisSettings) => any;
    children?: React.ReactNode;
    currentUser: CurrentUserState;
  };

interface State {
  isLoading: boolean;
  bootstrapError: Error | null;
}

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchExperiments: () => dispatch(fetchExperiments()),
    fetchPlans: () => dispatch(fetchPlans()),
    fetchPlanSettings: () => dispatch(fetchPlanSettings()),
    getFavoriteExperiments: () => dispatch(getFavoriteExperiments()),
    setAllowedVendors: (vendors: List<Types.Vendor>) =>
      dispatch(UserDataActions.setAllowedVendors(vendors)),
    setCurrentUser: (user: Types.User) => dispatch(CurrentUserActions.setUser(user)),
    setCurrentUserInfo: (userInfo: Types.UserInfo) =>
      dispatch(CurrentUserActions.setUserInfo(userInfo)),
    setCurrentVendor: (vendor: Types.Vendor) => dispatch(CurrentUserActions.setVendor(vendor)),
    setDoubleCountingPartners: (
      doubleCountingPartners: ReadonlyArray<Types.DoubleCountingPartner>
    ) => dispatch(AnalysisDataActions.setDoubleCountingPartners(doubleCountingPartners)),
    setEnvironment: (environment: Types.Environment) => dispatch(setEnvironment(environment)),
    setEndToEndTestBuildUrl: (buildUrl: string) => dispatch(setEndToEndTestBuildUrl(buildUrl)),
    setFavoriteViews: (views: List<Types.ThinView>) => dispatch(setFavoriteViews(views)),
    setPermissions: (permissions: ReadonlyArray<string>) =>
      dispatch(CurrentUserActions.setPermissions(permissions)),
    setUserFeatureFlags: (featureFlags: Set<FeatureFlag>) =>
      dispatch(CurrentUserActions.setUserFeatureFlags(featureFlags)),
    setVendorAnalysisSettings: (analysisSettings: Types.VendorAnalysisSettings) =>
      dispatch(CurrentUserActions.setVendorAnalysisSettings(analysisSettings)),
    setVisitedExperimentTimestampsForUser: (timestamps: ReadonlyArray<Types.VisitedTimestamp>) =>
      dispatch(setVisitedExperimentTimestampsForUser(timestamps)),
    setVisitedExperimentTimestampsForVendor: (timestamps: ReadonlyArray<Types.VisitedTimestamp>) =>
      dispatch(setVisitedExperimentTimestampsForVendor(timestamps)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withAnalysisDataActionCreators(AppContainer))
);
