import {List} from 'immutable';
import React from 'react';
import ReactGridLayout from 'react-grid-layout';

import {CurrentUser} from 'redux/reducers/user';
import {flattenFilterMap} from 'toolkit/filters/utils';
import {
  GRID_LAYOUT_COLUMNS,
  GRID_LAYOUT_DEFAULT_ROWS,
  isGridLikeLayout,
} from 'toolkit/layout/utils';
import {getVendorEvaluationDate} from 'toolkit/time/utils';
import {createDefaultWidgetData, WidgetData} from 'toolkit/views/types';
import {emptyViewLinks} from 'toolkit/views/view-link-types';
import * as Types from 'types';
import {findLast, last, removeAt} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';
import {
  LayoutProps,
  LayoutWidgetProps,
  SelectionBehavior,
  WidgetDisplayMode,
  WidgetInteractivityMode,
  WidgetLayoutData,
} from 'widgets/types';
import {getWidgetTitle, Widgets} from 'widgets/utils';

import {InsightColorVariant, InsightTextSize} from './chrome/headers/types';

export function getWidgetTreeWithoutData(widgets: ReadonlyArray<Types.Widget>, toDelete?: number) {
  return getWidgetTree(
    widgets,
    Array<WidgetData<unknown>>(widgets.length).fill(createDefaultWidgetData()),
    toDelete
  );
}

const hasValidChildCount = (widget: Types.Widget) =>
  widget.childCount !== null && widget.childCount !== undefined;

export const isSidebarWidget = (widget: Types.Widget) => Widgets[widget.type].isSidebar;
export const isChromeWidget = (widget: Types.Widget) => isSidebarWidget(widget);

export function getWidgetTree(
  widgets: ReadonlyArray<Types.Widget>,
  data: ReadonlyArray<WidgetData<unknown>>,
  toDeleteIndex = -1
) {
  // eslint-disable-next-line fp/no-let
  let groupedWidgets: List<WidgetLayoutData> = List.of();
  // eslint-disable-next-line fp/no-let
  for (let i = 0; i < widgets.length; ++i) {
    const widget = widgets[i];
    const willDelete = i === toDeleteIndex;
    if (hasValidChildCount(widget)) {
      const startIndex = i + 1;
      const childCount = widget.childCount || 0;
      const willDeleteChild =
        toDeleteIndex >= startIndex && toDeleteIndex < startIndex + childCount;
      const childWidgetsBeforeDelete = widgets.slice(startIndex, startIndex + childCount);
      const childWidgets = willDeleteChild
        ? removeAt(childWidgetsBeforeDelete, toDeleteIndex - startIndex)
        : childWidgetsBeforeDelete;
      const childDataBeforeDelete = data.slice(startIndex, startIndex + childCount);
      const childData = willDeleteChild
        ? removeAt(childDataBeforeDelete, toDeleteIndex - startIndex)
        : childDataBeforeDelete;
      if (!willDelete) {
        groupedWidgets = groupedWidgets.push({
          children: getWidgetTree(childWidgets, childData),
          data: data[i],
          widget: {...widget, childCount: childWidgets.length},
        });
      }
      i += childCount;
    } else if (!willDelete) {
      groupedWidgets = groupedWidgets.push({data: data[i], widget});
    }
  }

  return groupedWidgets;
}

export function getParentWidget(widgets: ReadonlyArray<Types.Widget>, widgetIndex: number | null) {
  if (widgetIndex === null) {
    return null;
  }

  return findLast(
    widgets
      .slice(0, widgetIndex)
      .map((widget, index) =>
        hasValidChildCount(widget) && widget.childCount! >= widgetIndex - index ? widget : null
      ),
    item => !!item
  );
}

export function isChildWidget(widgets: ReadonlyArray<Types.Widget>, widgetIndex: number | null) {
  return !!getParentWidget(widgets, widgetIndex);
}

export function isParentWidget(widget: WidgetLayoutData | undefined): boolean {
  return (
    !!widget &&
    !!widget.children &&
    !widget.children.isEmpty() &&
    (widget.widget.childCount ?? 0) > 0
  );
}

export function getChildWidgetListIndex(
  layoutData: WidgetLayoutData,
  selectedChildIndex: number,
  parentWidgetListIndex: number
) {
  return (
    parentWidgetListIndex +
    1 +
    layoutData
      .children!.slice(0, selectedChildIndex)
      .reduce((acc, data) => acc + 1 + (data.children?.size || 0), 0)
  );
}

export function getFlattenedWidgetDataTree(
  layoutData: List<WidgetLayoutData>
): ReadonlyArray<WidgetData<unknown>> {
  return layoutData.reduce(
    (acc: ReadonlyArray<WidgetData<unknown>>, item) => [
      ...acc,
      item.data,
      ...(item.children ? getFlattenedWidgetDataTree(item.children) : []),
    ],
    []
  );
}

export function getFlattenedWidgetTree(
  layoutData: List<WidgetLayoutData>
): ReadonlyArray<Types.Widget> {
  return layoutData.reduce(
    (acc: ReadonlyArray<Types.Widget>, item) => [
      ...acc,
      item.widget,
      ...(item.children ? getFlattenedWidgetTree(item.children) : []),
    ],
    []
  );
}

// Maps the given linear index to the index of the parent widget.
export function getParentWidgetIndex(layoutData: List<WidgetLayoutData>, actualIndex: number) {
  const childOffsets = layoutData
    .map(item => (item.children ? item.children.size : 0))
    .reduce(
      (acc: ReadonlyArray<number>, item) => [...acc, item + (!acc.length ? 0 : last(acc))],
      []
    );
  const parentIndexOffsets = layoutData.map((_, index) => index + childOffsets[index]);
  return parentIndexOffsets.findIndex(value => actualIndex <= value);
}

export function getWidgetVisibilityStyle(visible: boolean): React.CSSProperties {
  return {
    display: visible ? 'inherit' : 'none',
    visibility: visible ? 'inherit' : 'hidden',
  };
}

export function applyDefaultLayoutParams(
  otherWidgets: ReadonlyArray<Types.Widget>,
  newWidget: Types.Widget
): Types.Widget {
  const tree = getWidgetTreeWithoutData(otherWidgets);
  const y =
    tree
      .map(data =>
        data.widget.layoutParams ? data.widget.layoutParams.y + data.widget.layoutParams.h : 0
      )
      .max() ?? 0;
  return {
    ...newWidget,
    layoutParams: {
      x: 0,
      y,
      w: GRID_LAYOUT_COLUMNS / 2,
      h: Math.floor(GRID_LAYOUT_DEFAULT_ROWS / 2),
    },
  };
}

export function constrainToFixedHeight(layoutItem: ReactGridLayout.Layout): ReactGridLayout.Layout {
  const h = Math.min(GRID_LAYOUT_DEFAULT_ROWS, layoutItem.h);
  const bottom = layoutItem.y + h;
  const y = bottom <= GRID_LAYOUT_DEFAULT_ROWS ? layoutItem.y : GRID_LAYOUT_DEFAULT_ROWS - h;

  return {
    ...layoutItem,
    h,
    y,
  };
}

export function treeIndexToActualIndex(tree: List<WidgetLayoutData>, treeIndex: number): number {
  return tree
    .slice(0, treeIndex)
    .reduce((indexAcc, item) => indexAcc + (item.children?.size || 0) + 1, 0);
}

export function getWidgetsWithChrome(
  allWidgets: ReadonlyArray<Types.Widget>,
  newSidebarWidget?: Types.Widget | null
) {
  const normalWidgets = allWidgets.filter(widget => !isChromeWidget(widget));
  const sidebarWidget =
    newSidebarWidget !== undefined ? newSidebarWidget : allWidgets.filter(isSidebarWidget)[0];
  return [sidebarWidget, ...normalWidgets].filter(isTruthy);
}

export function createDefaultGridViewLayout(
  tree: List<WidgetLayoutData>
): ReadonlyArray<Types.Widget> {
  const getWidget = (index: number, x: number, y: number, w: number, h: number) => ({
    ...tree.get(index)!.widget,
    layoutParams: {x, y, w, h},
  });

  if (tree.size === 1) {
    const widget = getWidget(0, 0, 0, GRID_LAYOUT_COLUMNS, GRID_LAYOUT_DEFAULT_ROWS);
    return getFlattenedWidgetTree(tree.set(0, {...tree.first(), widget}));
  } else if (tree.size === 2) {
    const topWidget = getWidget(0, 0, 0, GRID_LAYOUT_COLUMNS, GRID_LAYOUT_DEFAULT_ROWS / 2 - 1);
    const bottomWidget = getWidget(1, 0, 2, GRID_LAYOUT_COLUMNS, GRID_LAYOUT_DEFAULT_ROWS / 2 + 1);
    return getFlattenedWidgetTree(
      tree
        .set(0, {...tree.first(), widget: topWidget})
        .set(1, {...tree.last(), widget: bottomWidget})
    );
  } else if (tree.size === 3) {
    const topHeight = GRID_LAYOUT_DEFAULT_ROWS / 2 - 1;
    const leftTop = getWidget(0, 0, 0, GRID_LAYOUT_COLUMNS / 2, topHeight);
    const rightTop = getWidget(1, GRID_LAYOUT_COLUMNS / 2, 0, GRID_LAYOUT_COLUMNS / 2, topHeight);
    const bottom = getWidget(2, 0, 2, GRID_LAYOUT_COLUMNS, GRID_LAYOUT_DEFAULT_ROWS / 2 + 1);
    return getFlattenedWidgetTree(
      tree
        .set(0, {...tree.get(0)!, widget: leftTop})
        .set(1, {...tree.get(1)!, widget: rightTop})
        .set(2, {...tree.get(2)!, widget: bottom})
    );
  } else {
    return getFlattenedWidgetTree(
      tree.map((data, i) => ({
        ...data,
        widget: {
          ...data.widget,
          layoutParams: {
            h: Math.floor(GRID_LAYOUT_DEFAULT_ROWS / 2),
            w: GRID_LAYOUT_COLUMNS / 2,
            x: i % 2 ? GRID_LAYOUT_COLUMNS / 2 : 0,
            y: Math.floor(i / 2) * Math.floor(GRID_LAYOUT_DEFAULT_ROWS / 2),
          },
        },
      }))
    );
  }
}

function getWidgetsForLayoutWithoutChrome(
  widgets: ReadonlyArray<Types.Widget>,
  newLayout: Types.LayoutType,
  oldView: Types.View,
  isFixedHeight: boolean
): ReadonlyArray<Types.Widget> {
  const tree = getWidgetTreeWithoutData(widgets).filter(item => !isChromeWidget(item.widget));
  const isNowGridLike = isGridLikeLayout(newLayout);
  const wasGridLike = isGridLikeLayout(oldView.layoutType);
  if (isNowGridLike && wasGridLike) {
    return isFixedHeight && !oldView.isFixedHeight ? createDefaultGridViewLayout(tree) : widgets;
  } else if (isNowGridLike && !wasGridLike) {
    return createDefaultGridViewLayout(tree);
  }
  return getFlattenedWidgetTree(
    tree.map(data => ({...data, widget: {...data.widget, layoutParams: null}}))
  );
}

export function getWidgetsForLayout(
  widgets: ReadonlyArray<Types.Widget>,
  newLayout: Types.LayoutType,
  oldView: Types.View,
  isFixedHeight: boolean
): ReadonlyArray<Types.Widget> {
  const sidebarWidget = widgets.filter(isSidebarWidget)[0];
  return getWidgetsWithChrome(
    getWidgetsForLayoutWithoutChrome(widgets, newLayout, oldView, isFixedHeight),
    sidebarWidget
  );
}

export function getWidgetLayoutTitle(layoutData: WidgetLayoutData) {
  const widget = layoutData.children
    ? layoutData.children.filter(child => !Widgets[child.widget.type].isContainer).first()!.widget
    : layoutData.widget;

  return getWidgetTitle(widget);
}

export const getWidgetKey = (layoutData: WidgetLayoutData, treeIndex: number) => {
  return layoutData.widget.id
    ? `${layoutData.widget.id}-${treeIndex}-${layoutData.widget.type}`
    : `${JSON.stringify({...layoutData.widget, layoutParams: null})}-${treeIndex}-${
        layoutData.widget.type
      }`;
};

export const getWidgetCountInTree = (tree: List<WidgetLayoutData>, endIndex: number) =>
  tree
    .slice(0, endIndex)
    .reduce((acc, widget) => acc + 1 + (widget.children ? widget.children.size : 0), 0);

export function isOwnIndexOrThatOfAChild(
  layoutData: WidgetLayoutData,
  widgetIndex: number,
  indexToCompare: number
) {
  const childCount = layoutData.children ? layoutData.children.size : 0;
  return indexToCompare >= widgetIndex && indexToCompare <= widgetIndex + childCount;
}

export function getLayoutWidgetProps(
  layoutProps: LayoutProps,
  layoutData: WidgetLayoutData,
  currentUser: CurrentUser,
  widgetListIndex: number,
  treeIndex: number
) {
  const {
    activeView,
    environment,
    onChildSelected,
    onConvertToContainerWidget,
    onMoveChild,
    onDelete,
    onEdit,
    onExpand,
    onOpenProductMappings,
    onSelectionFiltersChangedForWidget,
    onSetSelectedCalendarDate,
    onSubscribeToEmails,
    onViewWidgetFiles,
    onUpdateEvent,
    onWidgetTreeChanged,
    selectedExperiment,
    reloadWidget,
    onSetConfig,
    onSetConfigWithoutUpdates,
  } = layoutProps;

  const actualWidgetIndex = widgetListIndex + layoutProps.widgetIndexOffset;

  const childCount = getFlattenedWidgetTree(layoutData.children || List.of()).length;

  const hasExpandedWidget = activeView.expandedWidgetIndex !== null;
  const isOnlyWidget = layoutProps.layoutData.size === 1;

  const isEditing = activeView.editingWidgetIndex !== null;
  const isSelectionEnabled = activeView.isSelectionEnabled && !isEditing;
  const selectionBehavior: SelectionBehavior =
    layoutData.widget.type === Types.WidgetType.FILTER_BAR
      ? SelectionBehavior.MULTI
      : !isOnlyWidget && isSelectionEnabled
        ? SelectionBehavior.MULTI
        : SelectionBehavior.NONE;

  const isExpanded =
    activeView.expandedWidgetIndex !== null &&
    isOwnIndexOrThatOfAChild(layoutData, actualWidgetIndex, activeView.expandedWidgetIndex);

  return {
    calendar: activeView.view.calendar,
    calendarProperties: assertTruthy(layoutProps.allCalendars.get(activeView.view.calendar)),
    contextMenuActions: layoutProps.contextMenuActions,
    weekFormat: activeView.view.weekFormat,
    defaultEvaluationPeriod: activeView.view.evaluationPeriod,
    displayMode: 'normal' as WidgetDisplayMode,
    defaultAttributeHierarchies: layoutProps.defaultAttributeHierarchies,
    enableOrderPreviewLinks: activeView.view.enableOrderPreviewLinks,
    environment,
    experiment: selectedExperiment ?? null,
    evaluationDate:
      activeView.evaluationDate ??
      getVendorEvaluationDate(currentUser.settings.analysisSettings.evaluationDate),
    insightColorVariant: InsightColorVariant.DEFAULT,
    insightTextSize: InsightTextSize.XL,
    interactivityMode: WidgetInteractivityMode.FULL,
    isEditing,
    isEditable: layoutProps.isEditable,
    isExpandable: !isOnlyWidget,
    isExpanded,
    isMenuButtonVisible: layoutProps.isMenuButtonVisible ?? layoutProps.isEditable,
    layoutData,
    onChildSelected,
    onConvertToContainerWidget,
    onMoveChild,
    onDelete,
    onEdit,
    onExpand,
    onOpenProductMappings,
    onSelectionFiltersChangedForWidget,
    onSetSelectedCalendarDate,
    onSubscribeToEmails,
    onViewWidgetFiles,
    onUpdateEvent,
    onWidgetTreeChanged,
    reloadWidget,
    onSetConfig,
    onSetConfigWithoutUpdates,
    removeDoubleCountingPartners: activeView.view.removeDoubleCounting,
    selectionBehavior,
    selectedFilters: activeView.selectionFiltersByWidgetIndex.get(actualWidgetIndex, []),
    otherFilters: flattenFilterMap(
      activeView.selectionFiltersByWidgetIndex.filter(
        (_, widgetIndex) => widgetIndex !== actualWidgetIndex
      )
    ),
    filtersSelectedByChildren: activeView.selectionFiltersByWidgetIndex
      .filter((_, index) => index > actualWidgetIndex && index <= actualWidgetIndex + childCount)
      .mapKeys(index => index - actualWidgetIndex - 1),
    style: getWidgetVisibilityStyle(!hasExpandedWidget || isExpanded),
    treeIndex,
    viewFilters: activeView.view.filters,
    viewId: activeView.view.id,
    viewLinks: isEditing ? emptyViewLinks : layoutProps.viewLinks,
    viewSlugs: layoutProps.viewSlugs,
    viewUpdateMode: layoutProps.activeView.view.updateMode,
    viewUrlParams: layoutProps.viewUrlParams,
    widgetIndexOffset: layoutProps.widgetIndexOffset,
    widgetListIndex,
    currency: activeView.view.currency,
  };
}

export function getContainerWidgetProps(
  containerProps: LayoutWidgetProps,
  layoutData: WidgetLayoutData,
  widgetListIndex: number,
  treeIndex: number
) {
  return {
    ...containerProps,
    layoutData,
    treeIndex,
    widgetListIndex,
  };
}
