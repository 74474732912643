import './NewViewDialog.scss';

import classNames from 'classnames';
import {List} from 'immutable';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {connect} from 'react-redux';

import * as Api from 'api';
import {IAnalysisDataActions} from 'redux/actions/analysis';
import {withAnalysisDataActionCreators} from 'redux/context/analysis';
import {CurrentUserProps, withCurrentUser} from 'redux/context/user';
import {RootState} from 'redux/reducers';
import {getAllMetricsWithAnyData, getAvailableViews} from 'redux/selectors/analysis';
import {AttributeHierarchies, AttributesById} from 'toolkit/attributes/types';
import {withModalBoundary} from 'toolkit/components/Boundary';
import HoverPopover from 'toolkit/components/HoverPopover';
import Icon from 'toolkit/components/Icon';
import Loader from 'toolkit/components/Loader';
import ModalDialog from 'toolkit/components/ModalDialog';
import SupportLink from 'toolkit/components/SupportLink';
import {MetricsByName} from 'toolkit/metrics/types';
import {getEffectiveMetric} from 'toolkit/metrics/utils';
import {getEntityIdsByTag} from 'toolkit/tags/utils';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';
import {ThinViewsById} from 'toolkit/views/types';
import {createDefaultView, getNextAvailableName} from 'toolkit/views/utils';
import * as Types from 'types';
import {ascendingBy} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';
import {getCombinedStatus, Status} from 'utils/status';
import {useApi, useResult} from 'utils/useApi';
import {withApi} from 'utils/withApi';
import {getWidgetMetricInstances} from 'widgets/utils';

const ViewWithDescription: React.FunctionComponent<ViewWithDescriptionProps> = ({
  availableMetrics,
  isSelected,
  onClick,
  thinView,
}) => {
  const view = useResult(useApi(Api.Views.getViewById.getResource(assertTruthy(thinView.id))));

  const unsupportedMetrics = view.widgets
    .flatMap(getWidgetMetricInstances)
    .map(getEffectiveMetric)
    .filter(metric => !availableMetrics.has(metric!.name));

  return (
    <div
      className={classNames(
        'ViewWithDescription',
        {selected: isSelected},
        {unsupported: unsupportedMetrics.length > 0}
      )}
      role="button"
      tabIndex={0}
      onClick={() => onClick(view)}
    >
      <div className="text-content">
        <div className="title">{view.name}</div>
        {view.description && <div className="description">{view.description}</div>}
      </div>
      {unsupportedMetrics.length > 0 && (
        <div className="warning-icon">
          <HoverPopover
            hideDelay={0}
            popoverClassName="unsupported-view-popover"
            target={<Icon icon="exclamation-triangle" />}
          >
            <p>This dashboard uses metrics that Alloy.ai does not have for your data.</p>
            <p>
              <SupportLink>Contact support</SupportLink> if you have issues setting up this view.
            </p>
          </HoverPopover>
        </div>
      )}
    </div>
  );
};
interface ViewWithDescriptionProps {
  availableMetrics: MetricsByName;
  isSelected: boolean;
  onClick: (view: Types.ThinView) => void;
  thinView: Types.ThinView;
}

class NewViewChooser extends React.PureComponent<NewViewChooserProps> {
  componentDidMount() {
    this.selectFirstTagIfNoneSelectedYet();
  }

  componentDidUpdate() {
    this.selectFirstTagIfNoneSelectedYet();
  }

  selectFirstTagIfNoneSelectedYet = () => {
    if (!this.props.selectedTag && this.props.publicTags.length > 0) {
      const sortedTags = this.props.publicTags.slice().sort(ascendingBy(tag => tag.name));
      this.props.onSelectTag(sortedTags[0].name);
    }
  };

  getTagItemContent = (tag: Types.Tag) => {
    const handleClick = () => this.props.onSelectTag(tag.name);
    return (
      <div
        key={tag.id}
        className={classNames('tagged-item', {
          selected: this.props.selectedTag === tag.name,
        })}
        role="button"
        tabIndex={0}
        onClick={handleClick}
      >
        <div className="title">{tag.name}</div>
      </div>
    );
  };

  getTagItem = (tag: Types.Tag) =>
    tag.description ? (
      <OverlayTrigger
        {...defaultOverlayTriggerTooltipProps}
        key={tag.id}
        overlay={<Tooltip id="tooltip">{tag.description}</Tooltip>}
        placement="right"
      >
        {this.getTagItemContent(tag)}
      </OverlayTrigger>
    ) : (
      this.getTagItemContent(tag)
    );

  getViewsForTag = (tag: string) =>
    List(getEntityIdsByTag(this.props.publicThinViews.map(view => view.tags)).get(tag) || [])
      .map(viewId => this.props.publicThinViews.get(viewId))
      .filter(isTruthy)
      .sortBy(view => view.name);

  getContent = () => (
    <div className="content">
      <div className="body">
        <div className="side-pane">
          {this.props.publicTags
            .filter(tag => !this.getViewsForTag(tag.name).isEmpty())
            .sort(ascendingBy(tag => tag.name))
            .map(tag => this.getTagItem(tag))}
        </div>
        <div className="view-listing">
          {this.props.selectedTag
            ? this.getViewsForTag(this.props.selectedTag)
                .toList()
                .map(thinView => (
                  <ViewWithDescription
                    key={thinView.id}
                    availableMetrics={this.props.availableMetrics}
                    isSelected={thinView.id === this.props.selectedViewId}
                    thinView={thinView}
                    onClick={this.props.onSelectView}
                  />
                ))
            : null}
        </div>
      </div>
    </div>
  );

  isLoading = () =>
    getCombinedStatus(
      this.props.fetchingThinViewsStatus,
      this.props.fetchingAllGroupingsStatus,
      this.props.fetchingMetricsStatus
    ) !== Status.succeeded;

  render() {
    return (
      <div className="NewViewChooser">
        {this.isLoading() ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          this.getContent()
        )}
      </div>
    );
  }
}
type NewViewChooserProps = CurrentUserProps & {
  availableMetrics: MetricsByName;
  defaultFilterAttributes: ReadonlyArray<Types.Attribute>;
  fetchingAllGroupingsStatus: Status;
  fetchingMetricsStatus: Status;
  fetchingThinViewsStatus: Status;
  onSelectTag: (tag: string) => void;
  onSelectView: (view: Types.ThinView) => void;
  publicThinViews: ThinViewsById;
  publicTags: ReadonlyArray<Types.Tag>;
  selectedTag: string | null;
  selectedViewId: number | null;
};

class NewViewDialog extends React.PureComponent<NewViewDialogProps, NewViewDialogState> {
  static defaultProps = {
    isDialogOpen: false,
  };

  constructor(props: NewViewDialogProps) {
    super(props);
    this.state = {
      selectedTag: null,
      selectedViewId: null,
    };
  }

  createDefaultView = () => {
    const view = createDefaultView(
      this.props.currentUser,
      this.props.currentUser.settings.analysisSettings,
      this.props.defaultAttributeHierarchies,
      this.props.availableMetrics,
      this.props.allGroupings,
      this.props.defaultFilterAttributes
    );
    this.props.onCreateView(view);
  };

  handleConfirm = async () => {
    const templateView = assertTruthy(
      await Api.Views.getViewById(assertTruthy(this.state.selectedViewId))
    );
    const view: Types.View = {
      ...templateView,
      name: getNextAvailableName(templateView.name, this.props.availableThinViews),
      ownerId: this.props.currentUser.user.id,
      id: null,
    };
    this.props.onCreateView(view);
  };

  selectTag = (selectedTag: string) => {
    this.setState({selectedTag});
    if (selectedTag !== this.state.selectedTag) {
      this.setState({selectedViewId: null});
    }
  };

  selectView = (selectedView: Types.ThinView) => {
    this.setState({
      selectedViewId: selectedView.id,
    });
  };

  render() {
    return (
      <ModalDialog
        cancelActionName="Close"
        confirmActionName="Create"
        dialogClassName="NewViewDialog"
        isConfirmEnabled={!!this.state.selectedViewId}
        isOpen={this.props.isDialogOpen}
        linkActionName="Create Default Dashboard"
        title="Create Dashboard"
        topBar={
          <div className="header">
            {this.props.publicThinViews.isEmpty()
              ? 'Click below to create a default dashboard.'
              : 'Choose a template from the categories on the left, or start with a blank template.'}
          </div>
        }
        zeroPadding
        onClose={this.props.onClose}
        onConfirm={this.handleConfirm}
        onLinkClick={this.createDefaultView} // it is a two-pane layout, each has its scroll bar. should have no padding
      >
        {this.props.isDialogOpen && (
          <NewViewChooser
            {...this.props}
            selectedTag={this.state.selectedTag}
            selectedViewId={this.state.selectedViewId}
            onSelectTag={this.selectTag}
            onSelectView={this.selectView}
          />
        )}
      </ModalDialog>
    );
  }
}
type NewViewDialogProps = IAnalysisDataActions &
  CurrentUserProps & {
    allGroupings: AttributesById;
    availableMetrics: MetricsByName;
    availableThinViews: ThinViewsById;
    defaultAttributeHierarchies: AttributeHierarchies;
    defaultFilterAttributes: ReadonlyArray<Types.Attribute>;
    isDialogOpen?: boolean;
    fetchingAllGroupingsStatus: Status;
    fetchingMetricsStatus: Status;
    fetchingThinViewsStatus: Status;
    onClose: () => void;
    onCreateView: (view: Types.View) => void;
    publicThinViews: ThinViewsById;
    publicTags: ReadonlyArray<Types.Tag>;
  };
interface NewViewDialogState {
  selectedTag: string | null;
  selectedViewId: number | null;
}

function mapStateToProps(state: RootState) {
  return {
    allGroupings: state.analysis.data.allGroupings,
    availableMetrics: getAllMetricsWithAnyData(state),
    availableThinViews: getAvailableViews(state),
    defaultAttributeHierarchies: state.analysis.data.defaultAttributeHierarchies,
    fetchingAllGroupingsStatus: state.analysis.data.fetchingAllGroupingsStatus,
    fetchingThinViewsStatus: state.analysis.data.fetchingThinViewsStatus,
    fetchingMetricsStatus: state.analysis.data.fetchingMetricsStatus,
    publicThinViews: state.analysis.data.publicThinViews,
  };
}

export default connect(mapStateToProps)(
  withModalBoundary(
    withApi(
      () => ({
        publicTags: useApi(Api.Tags.getPublicTags.getResource()),
        defaultFilterAttributes: useApi(Api.Attributes.getDefaultFilterAttributes.getResource()),
      }),
      withAnalysisDataActionCreators(withCurrentUser(NewViewDialog))
    )
  )
);
