import './ExternalLink.scss';

import classNames from 'classnames';
import React from 'react';

import Icon from 'toolkit/components/Icon';

export const externalLinkProps = {
  rel: 'noopener noreferrer',
  target: '_blank', // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
} satisfies React.JSX.IntrinsicElements['a'];

const ExternalLink: React.FunctionComponent<Props> = ({
  className,
  iconPosition = 'left',
  shape = 'link',
  showIcon = true,
  children,
  ...rest
}) => (
  <a className={classNames('ExternalLink', className, shape)} {...externalLinkProps} {...rest}>
    {showIcon && iconPosition === 'left' && (
      <>
        <Icon className="link-icon" icon="external-link-alt" />{' '}
      </>
    )}
    {children}
    {showIcon && iconPosition === 'right' && (
      <>
        {' '}
        <Icon className="link-icon" icon="external-link-alt" />
      </>
    )}
  </a>
);
export default ExternalLink;
ExternalLink.displayName = 'ExternalLink';

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  className?: string;
  shape?: 'button' | 'link';
  showIcon?: boolean;
  iconPosition?: 'left' | 'right';
}
