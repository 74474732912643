import downRightIcon from 'toolkit/components/images/arrow-down-right.svg';
import arrowTrendDownIcon from 'toolkit/components/images/arrow-trend-down.svg';
import arrowTrendUpIcon from 'toolkit/components/images/arrow-trend-up.svg';
import upRightIcon from 'toolkit/components/images/arrow-up-right.svg';
import amazonS3Icon from 'toolkit/components/images/icon-amazon-s3.svg';
import bigQueryIcon from 'toolkit/components/images/icon-bigquery.svg';
import googleGcsIcon from 'toolkit/components/images/icon-google-gcs.svg';
import partnerDataIssueIcon from 'toolkit/components/images/icon-partner-data-issue.svg';
import redshiftIcon from 'toolkit/components/images/icon-redshift.svg';
import shareIcon from 'toolkit/components/images/icon-share.svg';
import snowflakeIcon from 'toolkit/components/images/icon-snowflake.svg';
import phantomInventoryIcon from 'toolkit/components/images/phantom-inventory.svg';
import promoAnalysisIcon from 'toolkit/components/images/promo-analysis-icon.svg';
import retailerPriceTrackingIcon from 'toolkit/components/images/retail-price-tracking.svg';
import retailerForecastMonitoring from 'toolkit/components/images/retailer-forecast-monitoring.svg';
import WalmartNovaRecommendationsIcon from 'toolkit/components/images/walmart-nova-recommendations-icon.svg';

import {IconSpec} from './types';

export const PARTNER_DATA_ISSUE_ICON: IconSpec = {
  alloyIcon: partnerDataIssueIcon,
};

export const SHARE_ICON: IconSpec = {
  alloyIcon: shareIcon,
};

export const UP_RIGHT_ICON: IconSpec = {
  alloyIcon: upRightIcon,
};

export const DOWN_RIGHT_ICON: IconSpec = {
  alloyIcon: downRightIcon,
};

export const WALMART_NOVA_RECOMMENDATIONS_WORKFLOW_ICON = {
  alloyIcon: WalmartNovaRecommendationsIcon,
};

export const PROMO_ANALYSIS_WORKFLOW_ICON = {
  alloyIcon: promoAnalysisIcon,
};

export const PHANTOM_INVENTORY_TEMPLATE_ICON = {
  alloyIcon: phantomInventoryIcon,
};

export const RETAILER_FORECAST_MONITORING_TEMPLATE_ICON = {
  alloyIcon: retailerForecastMonitoring,
};

export const RETAILER_PRICE_TRACKING_TEMPLATE_ICON = {
  alloyIcon: retailerPriceTrackingIcon,
};

// can be removed when we upgrade to font-awesome v6
export const ARROW_TREND_DOWN_ICON = {
  alloyIcon: arrowTrendDownIcon,
};

// can be removed when we upgrade to font-awesome v6
export const ARROW_TREND_UP_ICON = {
  alloyIcon: arrowTrendUpIcon,
};

export const BIGQUERY_ICON = {
  alloyIcon: bigQueryIcon,
};

export const AMAZON_S3_ICON = {
  alloyIcon: amazonS3Icon,
};

export const GOOGLE_GCS_ICON = {
  alloyIcon: googleGcsIcon,
};

export const REDSHIFT_ICON = {
  alloyIcon: redshiftIcon,
};

export const SNOWFLAKE_ICON = {
  alloyIcon: snowflakeIcon,
};
