import './NavTopBar.scss';

import classNames from 'classnames';
import History from 'history';
import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';

import NewViewDialog from 'dashboard/top-bar/menu/NewViewDialog';
import GlobalSearchIcon, {GlobalSearchRef} from 'nav/GlobalSearchIcon';
import NavTopBarLinks from 'nav/NavTopBarLinks';
import NavTopBarLogo from 'nav/NavTopBarLogo';
import NavBarPlusMenu from 'nav/NavTopBarPlusMenu';
import NavTopBarUserMenu from 'nav/NavTopBarUserMenu';
import {storeAndGoToView} from 'redux/actions/analysis';
import {useCurrentUser} from 'redux/context/user';
import useDispatch from 'redux/useDispatch';
import Icon from 'toolkit/components/Icon';
import Tooltip from 'toolkit/components/Tooltip';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';
import {getHomeUrl} from 'toolkit/views/utils';

import CreateCalendarEventDialog from './CreateCalendarEventDialog';

const NavTopBar: React.FC<Props> = ({location, searchInputRef}: Props) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const [isCreateCalendarEventDialogOpen, setCreateCalendarEventDialogOpen] = useState(false);
  const [isNewViewDialogOpen, setNewViewDialogOpen] = useState(false);

  return (
    <>
      <nav className="NavTopBar">
        <div className={classNames('left', getHideOnPhonesClassName())}>
          <NavTopBarLogo to={getHomeUrl(currentUser.vendor.name)} />
        </div>
        <div className="center">
          <NavTopBarLinks />
        </div>
        <div className="right">
          <GlobalSearchIcon ref={searchInputRef} />
          <NavBarPlusMenu
            setCreateCalendarEventDialogOpen={setCreateCalendarEventDialogOpen}
            setNewViewDialogOpen={setNewViewDialogOpen}
          />
          <Tooltip
            placement="bottom-end"
            tooltip={
              <>
                Help Center <Icon icon="external-link-alt" />
              </>
            }
          >
            <NavLink
              className={classNames('icon-link', 'help-center', getHideOnPhonesClassName())}
              target="_blank"
              to="/help"
            >
              <Icon icon={['far', 'question-circle']} />
            </NavLink>
          </Tooltip>
          <NavTopBarUserMenu />
        </div>
      </nav>
      {isCreateCalendarEventDialogOpen && (
        <CreateCalendarEventDialog
          location={location}
          onClose={() => setCreateCalendarEventDialogOpen(false)}
        />
      )}
      {isNewViewDialogOpen && (
        <NewViewDialog
          isDialogOpen={isNewViewDialogOpen}
          onClose={() => setNewViewDialogOpen(false)}
          onCreateView={view => {
            dispatch(storeAndGoToView(view));
            setNewViewDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

NavTopBar.displayName = 'NavTopBar';
interface Props {
  location: History.Location;
  searchInputRef: React.RefObject<GlobalSearchRef>;
}

export default NavTopBar;

export const NavTopBarEmpty: React.FC = () => {
  return (
    <nav className="NavTopBar">
      <NavTopBarLogo to="/" />

      <div className="spacer" />
    </nav>
  );
};

NavTopBarEmpty.displayName = 'NavTopBarEmpty';
