import './DashboardManagementPage.scss';

import classNames from 'classnames';
import {push} from 'connected-react-router';
import equals from 'fast-deep-equal';
import {Map} from 'immutable';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import * as Api from 'api';
import Analytics from 'app/analytics/analytics';
import {isMobile} from 'app/browser-utils';
import AnalysisEntityCard from 'dashboard/AnalysisEntityCard';
import AnalysisEntityCardLayout from 'dashboard/AnalysisEntityCardLayout';
import NoViewsPlaceholder from 'dashboard/NoViewsPlaceholder';
import {
  DashboardManagementHeaderCell,
  DateTypeChooser,
  DateTypeChooserParams,
} from 'dashboard/TableComponents';
import {
  AnalysisDataActions,
  archiveView,
  deleteView,
  storeAndGoToNewView,
} from 'redux/actions/analysis';
import {
  deleteExperiment,
  setExperiment,
  setExperimentFavoriteStatus,
  setSelectedExperiment,
} from 'redux/actions/groups';
import {
  setDashboardManagementActiveFilter,
  setDashboardManagementDateSortType,
  setDashboardManagementFilterText,
  setDashboardManagementTableSortModel,
} from 'redux/actions/navigation';
import {UserDataActions} from 'redux/actions/user-data';
import {useCurrentUser} from 'redux/context/user';
import {CurrentUser} from 'redux/reducers/user';
import {getAllMetricsWithAnyData} from 'redux/selectors/analysis';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import {TableTruncatedTooltipProps} from 'toolkit/ag-grid/TableTruncatedTooltip';
import {getTruncatedEntityNameCellOffsetPx} from 'toolkit/ag-grid/truncated-tooltip-utils';
import {TypedColDef} from 'toolkit/ag-grid/types';
import {defaultDateColumnFilterParams} from 'toolkit/ag-grid/utils';
import {
  AnalysisEntity,
  AnalysisEntityType,
  AnalysisManagementEntity,
  AnalysisManagementEntityType,
  DateSortType,
  TemplateAnalysisEntity,
  WorkflowAnalysisEntity,
} from 'toolkit/analysis/types';
import {PartnersById} from 'toolkit/attributes/types';
import Button, {ButtonDisplayVariant} from 'toolkit/components/Button';
import CanvasBlock from 'toolkit/components/CanvasBlock';
import Carousel from 'toolkit/components/Carousel';
import Centered from 'toolkit/components/Centered';
import ConfirmDialog from 'toolkit/components/ConfirmDialog';
import {EntityProperties, EntityProperty} from 'toolkit/components/EntitiesFilter';
import EntityManagementSidebar, {
  useFilteredEntities,
} from 'toolkit/components/EntityManagementSidebar';
import EntityTable from 'toolkit/components/EntityTable';
import Loader from 'toolkit/components/Loader';
import SearchBar from 'toolkit/components/SearchBar';
import {
  DashboardConfigMenuCell,
  DashboardConfigMenuCellParams,
  DateCell,
  DateCellParams,
  NameCell,
  NameCellParams,
  RouterLinkCell,
  TagListCell,
  TagListCellParams,
} from 'toolkit/entities/EntityTableCells';
import {
  EntityTableColumn,
  SelectableValues,
  SelectableValuesByProperty,
} from 'toolkit/entities/types';
import {capitalize} from 'toolkit/format/text';
import {EXPERIMENT_ICON, getExperimentAnalysisEntities} from 'toolkit/groups/utils';
import {
  PHANTOM_INVENTORY_TEMPLATE_ICON,
  PROMO_ANALYSIS_WORKFLOW_ICON,
  RETAILER_FORECAST_MONITORING_TEMPLATE_ICON,
  RETAILER_PRICE_TRACKING_TEMPLATE_ICON,
  WALMART_NOVA_RECOMMENDATIONS_WORKFLOW_ICON,
} from 'toolkit/icons/alloy-icons';
import {IconSpec} from 'toolkit/icons/types';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';
import {useSortedViewTagNames} from 'toolkit/tags/utils';
import NewTopBar from 'toolkit/top-bar/NewTopBar';
import {
  canSeeView,
  getUserTitle,
  hasAnyPermission,
  hasPermission,
  isAtLeast,
} from 'toolkit/users/utils';
import focusOnMount from 'toolkit/utils/focusOnMount';
import {ThinViewsById} from 'toolkit/views/types';
import {
  createDefaultView,
  DASHBOARD_ICON,
  getDashboardAnalysisEntities,
  getDeletionMessage,
  getTemplateAnalysisEntities,
  getTemplateIcon,
  isLaunchpadView,
  isSharedView,
  isWorkflowEntity,
  isWorkflowView,
} from 'toolkit/views/utils';
import * as Types from 'types';
import {NoopResource} from 'utils/api';
import {ascendingBy} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isTruthy, noop} from 'utils/functions';
import {getCombinedStatus, Status} from 'utils/status';
import {useApi, useResult} from 'utils/useApi';
import FavoritesListEmpty from 'widgets/landing/FavoritesListEmpty';
import {MAX_WIDGETS_PER_DASHBOARD} from 'widgets/utils';
import {getWidgetTree} from 'widgets/widget-layout';
import {
  getWorkflowUrl,
  isNovaRecommendationsEnabled,
  isPromoAnalysisEnabled,
} from 'workflows/utils';

export const dashboardProperties = ['tags', 'type', 'archived', 'launchpad'] as const;

const EMPTY_ARRAY = [] as const;

function getDashboardTimestamps(
  dateSortType: DateSortType,
  views: ThinViewsById,
  viewTimestampsForUser: ReadonlyArray<Types.VisitedTimestamp>,
  viewTimestampsForVendor: ReadonlyArray<Types.VisitedTimestamp>
): Map<number, string | null> {
  switch (dateSortType) {
    case DateSortType.LastOpenedByMe:
      return Map(viewTimestampsForUser.map(timestamp => [timestamp.entityId, timestamp.visited]));
    case DateSortType.LastOpenedByAnyone:
      return Map(viewTimestampsForVendor.map(timestamp => [timestamp.entityId, timestamp.visited]));
    case DateSortType.LastModified:
      return views.map(view => view.lastModifiedAt);
  }
}

function getExperimentTimestamps(
  dateSortType: DateSortType,
  experiments: ReadonlyArray<Types.Experiment>,
  experimentTimestampsForUser: Map<number, string>,
  experimentTimestampsForVendor: Map<number, string>
) {
  switch (dateSortType) {
    case DateSortType.LastOpenedByMe:
      return experimentTimestampsForUser;
    case DateSortType.LastOpenedByAnyone:
      return experimentTimestampsForVendor;
    case DateSortType.LastModified:
      return Map(
        experiments.map<[number, string | null]>(experiment => [
          assertTruthy(experiment.id),
          experiment.lastModifiedAt,
        ])
      );
  }
}

function getDeleteEntityDialogTitle(entity: AnalysisEntity) {
  return entity ? `Delete ${capitalize(entity.type)}` : '';
}

function getEntityDeletionMessage(entity: AnalysisEntity, currentUser: CurrentUser) {
  switch (entity.type) {
    case AnalysisEntityType.DASHBOARD:
    case AnalysisEntityType.TEMPLATE:
      return getDeletionMessage(entity.value, currentUser.user);
    case AnalysisEntityType.EXPERIMENT:
      return `Are you sure you want to delete the experiment "${entity.name}"? You will not be able to undo this action.`;
    default:
      return null;
  }
}

function getWorkflowEntities(
  currentUser: CurrentUser,
  partners: PartnersById
): ReadonlyArray<WorkflowAnalysisEntity> {
  const baseWorkflowEntity: Omit<
    WorkflowAnalysisEntity,
    'name' | 'description' | 'icon' | 'workflow' | 'url'
  > = {
    id: null,
    isFavorite: false,
    lastVisited: null,
    isPrivate: false,
    ownerId: null,
    tags: [],
    type: AnalysisEntityType.WORKFLOW,
  };
  return [
    isPromoAnalysisEnabled(currentUser)
      ? {
          ...baseWorkflowEntity,
          name: 'Promotion Analysis',
          description: 'Real time measurement of in-flight and past promotions',
          workflow: Types.Workflow.PROMOTION_ANALYSIS,
          icon: PROMO_ANALYSIS_WORKFLOW_ICON,
          url: getWorkflowUrl(Types.Workflow.PROMOTION_ANALYSIS, currentUser.vendor.name),
        }
      : null,
    isNovaRecommendationsEnabled(currentUser, partners)
      ? {
          ...baseWorkflowEntity,
          name: 'Walmart NOVA Recommendations',
          description: 'Highlight Walmart replenishment needs and create NOVA orders',
          workflow: Types.Workflow.WALMART_NOVA_RECOMMENDATIONS,
          icon: WALMART_NOVA_RECOMMENDATIONS_WORKFLOW_ICON,
          url: getWorkflowUrl(Types.Workflow.WALMART_NOVA_RECOMMENDATIONS, currentUser.vendor.name),
        }
      : null,
  ].filter(isTruthy);
}

function getDiscoverEntities(
  workflowEntities: ReadonlyArray<WorkflowAnalysisEntity>,
  templateEntities: ReadonlyArray<TemplateAnalysisEntity>,
  currentUser: CurrentUser
) {
  const workflowEntitiesByType = Map(
    workflowEntities.map(workflow => [workflow.workflow, workflow])
  );
  const templateEntitiesByName = Map(templateEntities.map(template => [template.name, template]));
  return [
    hasPermission(currentUser, Types.PermissionKey.WORKFLOW_USE_PROMOTION_ANALYSIS) &&
      workflowEntitiesByType.get(Types.Workflow.PROMOTION_ANALYSIS),
    hasPermission(currentUser, Types.PermissionKey.WORKFLOW_USE_WALMART_NOVA_RECOMMENDATIONS) &&
      workflowEntitiesByType.get(Types.Workflow.WALMART_NOVA_RECOMMENDATIONS),
    hasPermission(currentUser, Types.PermissionKey.WORKFLOW_USE_PHANTOM_INVENTORY) &&
    templateEntitiesByName.has('Phantom Inventory')
      ? ({
          ...templateEntitiesByName.get('Phantom Inventory')!,
          icon: PHANTOM_INVENTORY_TEMPLATE_ICON,
        } satisfies TemplateAnalysisEntity)
      : null,
    templateEntitiesByName.has('Retail Price Tracking')
      ? ({
          ...templateEntitiesByName.get('Retail Price Tracking')!,
          icon: RETAILER_PRICE_TRACKING_TEMPLATE_ICON,
        } satisfies TemplateAnalysisEntity)
      : null,
    templateEntitiesByName.has('Retailer Forecast Monitoring')
      ? ({
          ...templateEntitiesByName.get('Retailer Forecast Monitoring')!,
          icon: RETAILER_FORECAST_MONITORING_TEMPLATE_ICON,
        } satisfies TemplateAnalysisEntity)
      : null,
  ].filter(isTruthy);
}

const hiddenMobileColumns: ReadonlyArray<EntityTableColumn> = [
  EntityTableColumn.OWNER,
  EntityTableColumn.TAGS,
  EntityTableColumn.TYPE,
  EntityTableColumn.LAST_VIEWED,
  EntityTableColumn.CONFIG_MENU,
];

const getEmptyPlaceholder = (values: SelectableValuesByProperty<string>) => {
  if (equals(values.type, ['favorites'])) {
    return (
      <div className="empty-placeholder">
        <div>
          <FavoritesListEmpty />
        </div>
      </div>
    );
  }
  return null;
};

function getConfigColumnWidth(numberOfButtons: number): number {
  const buttonWidth = 30;
  const buttonGap = 4;
  const paddingWidth = 8;
  const borderWidth = 1;
  return (
    numberOfButtons * buttonWidth +
    (numberOfButtons - 1) * buttonGap +
    2 * paddingWidth +
    2 * borderWidth
  );
}

const DashboardManagementPage: React.FC = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const allUserInfos = useSelector(state => state.userData.allUserInfos);
  const allExperiments = useSelector(state => state.groups.experiments);
  const allGroupings = useSelector(state => state.analysis.data.allGroupings);
  const allUsersById = useSelector(state => state.userData.allUsersById);
  const availableMetrics = useSelector(getAllMetricsWithAnyData);
  const viewsCache = useSelector(state => state.analysis.data.viewsCache);
  const vendorThinViews = useSelector(state => state.analysis.data.vendorThinViews);
  const dateSortType = useSelector(state => state.navigation.dashboardManagementState.dateSortType);
  const sortModel = useSelector(state => state.navigation.dashboardManagementState.sortModel);
  const partners = useSelector(state => state.analysis.data.partners);
  const [entityToDelete, setEntityToDelete] = useState<AnalysisManagementEntity | null>(null);
  const [entityToArchive, setEntityToArchive] = useState<AnalysisManagementEntity | null>(null);

  const defaultAttributeHierarchies = useSelector(
    state => state.analysis.data.defaultAttributeHierarchies
  );
  const experimentVisitedTimestampsForUser = useSelector(
    state => state.groups.visitedTimestampsForUser
  );
  const experimentVisitedTimestampsForVendor = useSelector(
    state => state.groups.visitedTimestampsForVendor
  );
  const favoriteExperimentIds = useSelector(state => state.groups.favoriteExperimentIds);
  const favoriteViewIds = useSelector(state => state.analysis.data.favoriteViews);

  const canViewDashboards = hasPermission(
    currentUser,
    Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD
  );

  const canViewEntities = hasAnyPermission(
    currentUser,
    Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD,
    Types.PermissionKey.EXPERIMENT_VIEW_EXPERIMENT
  );
  const canCreateDashboards = hasPermission(
    currentUser,
    Types.PermissionKey.DASHBOARD_CREATE_DASHBOARD
  );

  const viewTimestampsForUserResource = useApi(
    canViewDashboards ? Api.Views.getVisitedViewTimestampsForUser.getResource() : NoopResource
  );
  const viewTimestampsForVendorResource = useApi(
    canViewDashboards ? Api.Views.getVisitedViewTimestampsForVendor.getResource() : NoopResource
  );
  const workflowViewIdsResource = useApi(
    isAtLeast(currentUser.user.role, Types.Role.ROOT)
      ? Api.Views.getWorkflowViewIds.getResource()
      : NoopResource
  );
  const viewTimestampsForUser = useResult(viewTimestampsForUserResource) ?? EMPTY_ARRAY;
  const viewTimestampsForVendor = useResult(viewTimestampsForVendorResource) ?? EMPTY_ARRAY;
  const workflowViewIds = useResult(workflowViewIdsResource);
  const selectedValuesByProperty = useSelector(
    state => state.navigation.dashboardManagementState.searchState.selectedValuesByProperty
  );
  const filterText = useSelector(
    state => state.navigation.dashboardManagementState.searchState.filterText
  );
  const defaultAttributes = useResult(
    useApi(Api.Attributes.getDefaultFilterAttributes.getResource())
  );

  useEffect(() => {
    if (allUsersById.isEmpty()) {
      dispatch(UserDataActions.fetchAllUserData());
    }
  }, [dispatch, allUsersById]);

  const createDefaultDashboard = useCallback(() => {
    if (!canCreateDashboards) {
      return;
    }
    dispatch(
      storeAndGoToNewView(
        createDefaultView(
          currentUser,
          currentUser.settings.analysisSettings,
          defaultAttributeHierarchies,
          availableMetrics,
          allGroupings,
          defaultAttributes
        )
      )
    );
  }, [
    canCreateDashboards,
    dispatch,
    allGroupings,
    availableMetrics,
    currentUser,
    defaultAttributeHierarchies,
    defaultAttributes,
  ]);

  const createExperiment = useCallback(() => {
    dispatch(push(`/${currentUser.vendor.name}/experiments/new-experiment`));
  }, [dispatch, currentUser]);

  const allViews = useMemo(
    () =>
      vendorThinViews
        .sortBy(view => view.name.toLocaleLowerCase())
        .filter(view => canSeeView(currentUser, view) && !!view.id),
    [vendorThinViews, currentUser]
  );

  const dashboardTimestamps = useMemo(
    () =>
      getDashboardTimestamps(
        dateSortType,
        vendorThinViews,
        viewTimestampsForUser,
        viewTimestampsForVendor
      ),
    [dateSortType, vendorThinViews, viewTimestampsForUser, viewTimestampsForVendor]
  );
  const experimentTimestamps = getExperimentTimestamps(
    dateSortType,
    allExperiments,
    experimentVisitedTimestampsForUser,
    experimentVisitedTimestampsForVendor
  );

  const workflowTypesByViewId: Map<number, ReadonlyArray<Types.Workflow>> = useMemo(
    () =>
      Map(workflowViewIds || {})
        .mapKeys(viewId => parseInt(viewId, 10))
        .map(workflows =>
          workflows.map(workflow => Types.Workflow[workflow as keyof typeof Types.Workflow])
        ),
    [workflowViewIds]
  );

  const templateEntities = getTemplateAnalysisEntities(
    allViews,
    favoriteViewIds,
    dashboardTimestamps,
    currentUser
  );
  const workflowEntities = useMemo(
    () => getWorkflowEntities(currentUser, partners),
    [currentUser, partners]
  );
  const experimentEntities = hasPermission(
    currentUser,
    Types.PermissionKey.EXPERIMENT_VIEW_EXPERIMENT
  )
    ? getExperimentAnalysisEntities(
        allExperiments,
        experimentTimestamps,
        favoriteExperimentIds,
        currentUser.vendor.name
      )
    : [];
  const dashboardEntities = hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD)
    ? getDashboardAnalysisEntities(
        allViews,
        favoriteViewIds,
        dashboardTimestamps,
        currentUser,
        workflowTypesByViewId
      )
    : [];
  const entities = [
    ...dashboardEntities,
    ...experimentEntities,
    ...templateEntities,
    ...workflowEntities,
  ];

  const entitiesFetchStatus = useSelector(state =>
    getCombinedStatus(
      state.analysis.data.fetchingThinViewsStatus,
      state.groups.experimentsFetchStatus
    )
  );

  const allTags = useSortedViewTagNames();

  const typeProperty: EntityProperty<AnalysisManagementEntity, 'type'> = {
    name: 'type',
    getLabel: (label: string) => {
      switch (label) {
        case 'experiments':
          return 'Experiments';
        case 'dashboards':
          return 'Dashboards';
        case 'favorites':
          return 'Favorites';
        case 'templates':
          return 'Templates';
        case 'workflows':
          return 'Workflows';
        default:
          return '';
      }
    },
    getValues: (entity: AnalysisManagementEntity) => {
      switch (entity.type) {
        case AnalysisEntityType.EXPERIMENT:
          return ['experiments', ...(entity.isFavorite ? ['favorites'] : [])];
        case AnalysisEntityType.DASHBOARD:
          if (
            entity.value.archivedAt ||
            (isSharedView(entity.value) && !entity.value.isPublished)
          ) {
            // return a null here to remove the entity from the list
            return [null];
          }
          return ['dashboards', ...(entity.isFavorite ? ['favorites'] : [])];
        case AnalysisEntityType.TEMPLATE:
          if (!entity.value.isPublished) {
            return [null];
          }
          return ['templates', ...(entity.isFavorite ? ['favorites'] : [])];
        case AnalysisEntityType.WORKFLOW:
          return ['workflows'];
      }
    },
    getIcon: (label: string): IconSpec => {
      switch (label) {
        case 'experiments':
          return EXPERIMENT_ICON;
        case 'dashboards':
          return DASHBOARD_ICON;
        case 'favorites':
          return 'star';
        case 'templates':
          return getTemplateIcon();
        case 'workflows':
          return 'stream';
        default:
          return null;
      }
    },
    allowSelectAll: true,
    allValues: [
      hasPermission(currentUser, Types.PermissionKey.EXPERIMENT_VIEW_EXPERIMENT) && 'experiments',
      hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD) && 'dashboards',
      canViewEntities && 'favorites',
      hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD) && 'templates',
      'workflows',
    ].filter(isTruthy),
  };

  const tagProperty: EntityProperty<AnalysisManagementEntity, 'tags'> = {
    name: 'tags',
    getLabel: (tag: string) => tag,
    getValues: (entity: AnalysisManagementEntity) =>
      entity.type === AnalysisEntityType.DASHBOARD && entity.value.archivedAt
        ? [null]
        : entity.tags,
    showTitle: true,
    allValues: allTags,
  };

  const archivedProperty: EntityProperty<AnalysisManagementEntity, 'archived'> = {
    name: 'archived',
    showTitle: true,
    getValues: (entity: AnalysisManagementEntity) =>
      entity.type === AnalysisEntityType.DASHBOARD && entity.value.archivedAt
        ? ['dashboards']
        : [null],
    getIcon: () => 'archive',
  };

  const launchpadProperty: EntityProperty<AnalysisManagementEntity, 'launchpad'> = {
    name: 'launchpad',
    showTitle: true,
    getLabel: (label: string) => {
      switch (label) {
        case 'launchpads':
          return 'Launchpads';
        case 'templates':
          return 'Templates';
        case 'super_admin_workflows':
          return 'Workflows';
        default:
          return '';
      }
    },
    getValues: (entity: AnalysisManagementEntity) => {
      if (entity.type === AnalysisEntityType.DASHBOARD) {
        if (isLaunchpadView(entity.value)) {
          return ['launchpads'];
        } else if (isWorkflowView(entity.value)) {
          return ['super_admin_workflows'];
        }
      } else if (entity.type === AnalysisEntityType.TEMPLATE) {
        return ['templates'];
      }
      return [null];
    },
    getIcon: value => {
      switch (value) {
        case 'launchpads':
          return 'house-user';
        case 'super_admin_workflows':
          return 'stream';
        case 'templates':
        default:
          return DASHBOARD_ICON;
      }
    },
    isHidden: () => !isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN),
  };

  const saveEditedTags = useCallback(
    (item: AnalysisEntity, tagsToSave: ReadonlyArray<string>) => {
      if (item.type === AnalysisEntityType.DASHBOARD || item.type === AnalysisEntityType.TEMPLATE) {
        Api.Views.getViewById(assertTruthy(item.value.id)).then(view => {
          Api.Views.saveView({...view, tags: tagsToSave}).then(savedView =>
            dispatch(AnalysisDataActions.setView(savedView))
          );
        });
      } else if (item.type === AnalysisEntityType.EXPERIMENT) {
        const experimentToSave: Types.Experiment = {
          ...item.value,
          tags: tagsToSave,
        };
        Api.Experiments.saveExperiment(experimentToSave).then(savedExperiment => {
          dispatch(setExperiment(savedExperiment));
          dispatch(setSelectedExperiment(savedExperiment));
        });
      } else {
        throw new Error('Unexpected entity type ' + item.type);
      }
    },
    [dispatch]
  );

  const toggleDashboardManagementTag = useCallback(
    (tagName: string) => {
      dispatch(setDashboardManagementActiveFilter('tags', [tagName]));
    },
    [dispatch]
  );

  const onDateSortTypeChanged = useCallback(
    (dateSortType: DateSortType) => dispatch(setDashboardManagementDateSortType(dateSortType)),
    [dispatch]
  );

  const getDeleteEntityCallback = useCallback(
    (type: AnalysisManagementEntityType): ((id: number) => void) => {
      switch (type) {
        case AnalysisEntityType.DASHBOARD:
        case AnalysisEntityType.TEMPLATE:
          return (viewId: number) => dispatch(deleteView(viewId));
        case AnalysisEntityType.EXPERIMENT:
          return (experimentId: number) => dispatch(deleteExperiment(experimentId));
        default:
          return noop;
      }
    },
    [dispatch]
  );

  const widgetCountsByViewId = useMemo(() => {
    if (!currentUser.isDevelopmentMode) {
      return Map();
    }
    return viewsCache.map(view => getWidgetTree(view.widgets, []).size);
  }, [viewsCache, currentUser.isDevelopmentMode]);

  const onChangeFavoriteStatus = useCallback(
    (entity: AnalysisEntity) => {
      Analytics.track('Analysis: Change Favorite Status', {isFavorite: entity.isFavorite});
      switch (entity.type) {
        case AnalysisEntityType.DASHBOARD:
        case AnalysisEntityType.TEMPLATE:
          return dispatch(
            AnalysisDataActions.setViewFavoriteStatus(entity.value, !entity.isFavorite)
          );
        case AnalysisEntityType.EXPERIMENT:
          return dispatch(setExperimentFavoriteStatus(entity.value, !entity.isFavorite));
        default:
          throw new Error(`Cannot change favorite status for entity type ${entity.type}`);
      }
    },
    [dispatch]
  );

  const columnDefs = useMemo((): TypedColDef<AnalysisEntity>[] => {
    const hiddenColumns = isMobile() ? hiddenMobileColumns : [];
    const configColumnWidth = getConfigColumnWidth(2);
    const defaultColDef = {
      headerComponentFramework: DashboardManagementHeaderCell,
    };
    return [
      {
        ...defaultColDef,
        cellRendererFramework: RouterLinkCell,
        colId: EntityTableColumn.ID,
        headerName: 'ID',
        hide: !currentUser.isDevelopmentMode,
        comparator: ascendingBy(id => id),
        getQuickFilterText: params => `${params.data.id}`,
        valueGetter: params => params.data.id,
        maxWidth: 75,
        width: 75,
      },
      {
        ...defaultColDef,
        cellRendererFramework: NameCell,
        cellRendererParams: {
          onChangeFavoriteStatus,
          showTypeIcons: false,
          wrapWithLink: true,
        } satisfies NameCellParams,
        colId: EntityTableColumn.NAME,
        headerName: 'Name',
        filter: false,
        hide: hiddenColumns.includes(EntityTableColumn.NAME),
        minWidth: 255,
        sort: 'asc',
        comparator: ascendingBy(name => name),
        valueGetter: params => params.data.name,
        valueFormatter: params => params.value as string,
        width: 255,
        tooltipComponentParams: {
          getOffsetInPx: getTruncatedEntityNameCellOffsetPx,
        } satisfies TableTruncatedTooltipProps<AnalysisEntity>,
      },
      {
        ...defaultColDef,
        cellRendererFramework: TagListCell,
        cellRendererParams: {
          onItemClick: toggleDashboardManagementTag,
          wrapWithLink: true,
        } satisfies TagListCellParams,
        colId: EntityTableColumn.TAGS,
        headerName: 'Tags',
        filter: false,
        minWidth: 195,
        valueGetter: params => params.data.tags,
        width: 195,
        tooltipValueGetter: undefined,
      },
      {
        ...defaultColDef,
        cellRendererFramework: RouterLinkCell,
        colId: EntityTableColumn.TYPE,
        headerName: 'Type',
        filter: false,
        minWidth: 85,
        valueGetter: params => capitalize(params.data.type),
        width: 85,
      },
      {
        ...defaultColDef,
        cellRendererFramework: RouterLinkCell,
        colId: EntityTableColumn.OWNER,
        headerName: 'Owner',
        hide: hiddenColumns.includes(EntityTableColumn.OWNER),
        minWidth: 195,
        valueGetter: params => {
          const user = allUsersById.get(params.data.ownerId);
          const userInfo = user ? allUserInfos.get(user.email) : null;
          return getUserTitle(user, userInfo, {defaultTitle: 'Alloy.ai'});
        },
        width: 195,
      },
      {
        cellRendererFramework: DateCell,
        cellRendererParams: {
          noDatePlaceholder: dateSortType === DateSortType.LastModified ? 'Unknown' : 'Never',
        } satisfies DateCellParams,
        headerComponentFramework: DateTypeChooser,
        headerComponentParams: {
          dateSortType,
          onDateSortTypeChanged,
        } satisfies DateTypeChooserParams,
        colId: EntityTableColumn.LAST_VIEWED,
        headerName: 'Last Viewed',
        hide: hiddenColumns.includes(EntityTableColumn.LAST_VIEWED),
        minWidth: 195,
        valueGetter: params => params.data.lastVisited,
        width: 195,
        filter: 'agDateColumnFilter',
        filterParams: defaultDateColumnFilterParams,
      },
      {
        ...defaultColDef,
        cellRendererFramework: RouterLinkCell,
        headerName: 'Widget #',
        colId: EntityTableColumn.WIDGET_COUNT,
        cellClass: params =>
          ((params.value as number) ?? 0) > MAX_WIDGETS_PER_DASHBOARD
            ? 'widget-count-warning-cell'
            : '',
        hide: !currentUser.isDevelopmentMode,
        valueGetter: params => widgetCountsByViewId.get(params.data.id),
        width: 75,
        maxWidth: 75,
        minWidth: 75,
      },
      {
        headerName: '',
        colId: EntityTableColumn.CONFIG_MENU,
        cellRendererFramework: DashboardConfigMenuCell,
        cellRendererParams: {
          onDelete: setEntityToDelete,
          onArchive: setEntityToArchive,
          onEditTags: saveEditedTags,
          allTags,
          currentUser,
        } satisfies DashboardConfigMenuCellParams<AnalysisManagementEntity>,
        hide: hiddenColumns.includes(EntityTableColumn.CONFIG_MENU),
        maxWidth: configColumnWidth,
        minWidth: configColumnWidth,
        width: configColumnWidth,
        sortable: false,
        filter: false,
      },
    ];
  }, [
    currentUser,
    onChangeFavoriteStatus,
    toggleDashboardManagementTag,
    dateSortType,
    onDateSortTypeChanged,
    saveEditedTags,
    allTags,
    allUsersById,
    allUserInfos,
    widgetCountsByViewId,
  ]);

  const setFilterText = useCallback(
    (filterText: string) => dispatch(setDashboardManagementFilterText(filterText)),
    [dispatch]
  );

  const entityProperties: EntityProperties<
    AnalysisManagementEntity,
    (typeof dashboardProperties)[number]
  > = {
    type: typeProperty,
    launchpad: launchpadProperty,
    tags: tagProperty,
    archived: archivedProperty,
  };

  const [topmostItem, setTopmostItem] = useState<AnalysisManagementEntity | null>(null);

  const filteredEntities = useFilteredEntities(
    entityProperties,
    entities,
    selectedValuesByProperty
  );
  const filteredEntitiesWithoutWorkflows = filteredEntities.filter(
    entity => !isWorkflowEntity(entity)
  );
  const discoverEntities = useMemo(
    () => getDiscoverEntities(workflowEntities, templateEntities, currentUser),
    [templateEntities, workflowEntities, currentUser]
  );

  const handleEnterPress = () => {
    if (topmostItem) {
      handleEnterPressOnSearch(topmostItem);
    }
  };

  const handleEnterPressOnSearch = (entity: AnalysisManagementEntity) => {
    dispatch(push(entity.url));
  };

  const loadingStatus = useSelector(state =>
    getCombinedStatus(
      state.userData.fetchingAllUserInfoStatus,
      state.userData.fetchingAllUsersStatus
    )
  );

  const noEntitiesPlaceHolder =
    !canViewDashboards || entitiesFetchStatus === Status.succeeded ? (
      <NoViewsPlaceholder onClick={createDefaultDashboard} />
    ) : (
      <Loader />
    );

  const actionButtons = (
    <>
      {hasPermission(currentUser, Types.PermissionKey.EXPERIMENT_CREATE_EXPERIMENT) && (
        <Button displayVariant={ButtonDisplayVariant.SECONDARY} onClick={createExperiment}>
          New Experiment
        </Button>
      )}
      {canCreateDashboards && (
        <Button displayVariant={ButtonDisplayVariant.SECONDARY} onClick={createDefaultDashboard}>
          New Dashboard
        </Button>
      )}
    </>
  );

  const handleSidebarSelectionChange = (
    property: (typeof dashboardProperties)[number],
    values: SelectableValues
  ) => dispatch(setDashboardManagementActiveFilter(property, values));

  const emptyState = getEmptyPlaceholder(selectedValuesByProperty);
  const content =
    loadingStatus !== Status.succeeded ? null : (
      <div className="DashboardManagementPage">
        <NewTopBar>
          <h2>Intelligence</h2>
          <div className="button-container">{actionButtons}</div>
        </NewTopBar>
        <div className="dashboard-management-page-content">
          {canViewEntities && (
            <div className={classNames('sidebar-content', getHideOnPhonesClassName())}>
              <EntityManagementSidebar<
                AnalysisManagementEntity,
                (typeof dashboardProperties)[number]
              >
                className="dashboard-management-sidebar"
                entities={entities}
                entityProperties={entityProperties}
                selectedValuesByProperty={selectedValuesByProperty}
                onSelectionChange={(property, values) =>
                  handleSidebarSelectionChange(
                    property as (typeof dashboardProperties)[number],
                    values
                  )
                }
              />
            </div>
          )}
          <div className="content">
            {!entities.length ? (
              noEntitiesPlaceHolder
            ) : equals(selectedValuesByProperty.type, ['workflows']) ? (
              <div className="workflows">
                <h4 className="header">Workflows</h4>
                {workflowEntities.length ? (
                  <AnalysisEntityCardLayout analysisEntities={workflowEntities} />
                ) : (
                  <Centered className="empty-workflows">No workflows available.</Centered>
                )}
              </div>
            ) : (
              <>
                {discoverEntities.length > 0 && (
                  <div className="discover">
                    <h4 className="header">Discover</h4>
                    <Carousel
                      items={discoverEntities.map(entity => (
                        <AnalysisEntityCard key={entity.name} entity={entity} />
                      ))}
                      // keep in sync with AnalysisEntityCard's width in AnalysisEntityCard.scss
                      itemWidthRem={35}
                    />
                  </div>
                )}
                {canViewEntities && (
                  <div className="table-content">
                    <CanvasBlock bordered={false} className="table-container" padded={false} gapped>
                      <SearchBar
                        ref={focusOnMount}
                        className="search-bar"
                        placeholder="Search…"
                        value={filterText}
                        onChange={setFilterText}
                        onEnterPressed={handleEnterPress}
                      />
                      {filteredEntitiesWithoutWorkflows.length === 0 && !!emptyState ? (
                        emptyState
                      ) : (
                        <EntityTable
                          columnDefs={columnDefs}
                          entities={filteredEntitiesWithoutWorkflows}
                          filterText={filterText}
                          sortModel={sortModel}
                          onSortModelChanged={sortModel =>
                            dispatch(setDashboardManagementTableSortModel(sortModel))
                          }
                          onTopmostItemChanged={setTopmostItem}
                        />
                      )}
                    </CanvasBlock>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );

  return (
    <>
      <Helmet title="Intelligence" />
      <Loader status={loadingStatus}>{content}</Loader>
      {entityToDelete && (
        <ConfirmDialog
          confirmActionName={getDeleteEntityDialogTitle(entityToDelete)}
          confirmButtonDisplayVariant={ButtonDisplayVariant.DESTRUCTIVE}
          title={getDeleteEntityDialogTitle(entityToDelete)}
          onClose={() => setEntityToDelete(null)}
          onConfirm={
            isWorkflowEntity(entityToDelete)
              ? noop
              : () => {
                  getDeleteEntityCallback(entityToDelete.type)(
                    assertTruthy(entityToDelete.value.id)
                  );
                  setEntityToDelete(null);
                }
          }
        >
          {getEntityDeletionMessage(entityToDelete, currentUser)}
        </ConfirmDialog>
      )}
      {entityToArchive && (
        <ConfirmDialog
          confirmButtonDisplayVariant={ButtonDisplayVariant.DESTRUCTIVE}
          title="Archive dashboard"
          onClose={() => setEntityToArchive(null)}
          onConfirm={
            isWorkflowEntity(entityToArchive)
              ? noop
              : () => {
                  dispatch(archiveView(entityToArchive.value.id!)).then(view => {
                    if (view) {
                      dispatch(AnalysisDataActions.setView(view));
                    }
                  });
                  setEntityToArchive(null);
                }
          }
        >
          <div>
            You will no longer be able to edit this dashboard and it will be automatically deleted
            at some point in the future based on your Dashboard Policy.
          </div>
          <div>{`Are you sure you want to archive the dashboard "${entityToArchive.name}"?`}</div>
        </ConfirmDialog>
      )}
    </>
  );
};

export default DashboardManagementPage;
