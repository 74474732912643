import './Table.scss';

import {
  CellClickedEvent,
  GridApi,
  GridReadyEvent,
  GridOptions,
  SelectionChangedEvent,
  RowNode,
  ColumnApi,
  AgGridEvent,
} from 'ag-grid-community';
import {AgGridReactProps} from 'ag-grid-react';
import classNames from 'classnames';
import equal from 'fast-deep-equal';
import React from 'react';

import {showAlert} from 'app/alerts';
import Analytics from 'app/analytics/analytics';
import * as Globals from 'app/globals';
import LazyAgGrid from 'toolkit/ag-grid/LazyAgGrid';
import {
  AgGridResizer,
  toggleRowExpandedOnClick,
  AgGridColResizeMethod,
  resizeAgGridColumns,
} from 'toolkit/ag-grid/utils';
import Suspenseful from 'toolkit/components/Suspenseful';
import {isNonNullish, noop} from 'utils/functions';
import {ConstructorOf} from 'utils/types';
import TableHeaderCell from 'widgets/tables/impl/TableHeaderCell';

import BaseTruncatedCellRenderer from './cell-renderers/BaseTruncatedCellRenderer';
import TableTruncatedTooltip, {TooltipValue} from './TableTruncatedTooltip';
import {TypedColDef, SortModel, UntypedColGroupDef, TypedTooltipValueGetterParams} from './types';

const sortingOrder = ['desc', 'asc'];

const compactAgGridProps = {
  headerHeight: 20,
  rowHeight: 29,
};

const defaultAgGridProps = {
  cacheQuickFilter: true,
  defaultColDef: {
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    headerComponentFramework: TableHeaderCell,
    tooltipComponentFramework: TableTruncatedTooltip,
    tooltipValueGetter: ({valueFormatted, value}: TypedTooltipValueGetterParams): TooltipValue => {
      if (isNonNullish(valueFormatted)) {
        return {formatted: valueFormatted};
      } else if (typeof value === 'number') {
        return {formatted: value.toString()};
      } else if (typeof value === 'string') {
        return {formatted: value};
      } else {
        return {formatted: ''};
      }
    },
    cellRendererFramework: BaseTruncatedCellRenderer,
  },
  // This is for working around an ag-grid-react bug that causes flickering of duplicate
  // cell content. This bug is only fixed in ag-grid 24. Workaround from
  // https://github.com/ag-grid/ag-grid/issues/3731
  disableStaticMarkup: true,
  enableBrowserTooltips: false,
  gridOptions: {
    enableCellTextSelection: true,
  },
  groupHeaderHeight: 24,
  headerHeight: 36,
  reactNext: true,
  rowHeight: 40,
  sortingOrder,
  suppressCellSelection: true,
  suppressContextMenu: true,
  suppressLoadingOverlay: true,
  suppressMovableColumns: true,
  suppressNoRowsOverlay: true,
  suppressRowClickSelection: true,
  suppressScrollOnNewData: true,
  tooltipShowDelay: 0,
  tooltipMouseTrack: true,
};

// It should be safe to update the column defs if only cellRendererParams changed,
// so we exclude cellRendererParams when deciding whether to remount. Since remounting results in
// resetting the scroll position, we want to avoid it where possible.
function getSimplifiedColDefsForKey(
  colDefs: ReadonlyArray<TypedColDef<any, any, any, any> | UntypedColGroupDef> | undefined
) {
  return colDefs?.map(colDef => ({
    ...colDef,
    cellRendererParams: null as any,
    headerComponentParams: null as any,
  }));
}

function shallowMerge(props: any, name: 'defaultColDef' | 'gridOptions') {
  const newPropObject = (props && props[name]) || {};
  const defaultPropObject = defaultAgGridProps[name];
  return {...defaultPropObject, ...newPropObject};
}

type CustomAgGridProps<T, ContextValueType> = Omit<AgGridReactProps, 'columnDefs'> & {
  columnDefs: ColDefs<T, ContextValueType> | undefined;
};

function mergeAgGridProps<T, ContextValueType>(
  props: CustomAgGridProps<T, ContextValueType>,
  isCompact: boolean
): AgGridReactProps {
  // Merge object options explicitly. A shallow merge of props & defaultAgGridProps just replaces
  // each object member without merging.
  const defaultColDef = shallowMerge(props, 'defaultColDef');
  const gridOptions = shallowMerge(props, 'gridOptions');
  const compactProps = isCompact ? compactAgGridProps : {};

  const mergedProps: CustomAgGridProps<T, ContextValueType> = {
    ...defaultAgGridProps,
    ...compactProps,
    ...props,
    defaultColDef,
    gridOptions,
  };

  // FIXME: cast out all the bad words. Ag-grid's typing for cellRendererSelector is incorrect.
  return mergedProps as any as AgGridReactProps;
}

export default class Table<T, ContextValueType = unknown> extends React.Component<
  Props<T, ContextValueType>,
  State
> {
  static defaultProps = {
    autoResize: true,
    borderless: false,
    grouped: true,
    hoverable: true,
    interactive: true,
    suspendWhileLoading: true,
    onGridReady: noop,
    onGridUnmounted: noop,
    onSortModelChanged: noop,
    rowSelection: 'single',
  };

  private gridApi: GridApi | undefined = undefined;
  private columnApi: ColumnApi | undefined = undefined;
  private agGridResizer: AgGridResizer | undefined = undefined;
  private gridWrapper: HTMLDivElement | null = null;

  private lastColumnDefsKey = 0;
  private lastSimplifiedColumnDefs: any = null;
  private unmountCount = 0;

  constructor(props: Props<T, ContextValueType>) {
    super(props);
    this.state = {
      columnDefsKey: 0,
      lastSelectedRows: [],
    };
    if (props.columnDefs) {
      this.lastSimplifiedColumnDefs = getSimplifiedColDefsForKey(props.columnDefs);
    }
  }

  shouldComponentUpdate(nextProps: Props<T, ContextValueType>, nextState: State) {
    return (
      !equal(
        {
          ...this.props,
          columnDefs: this.props.doNotUpdateOnColumnDefChange ? null : this.props.columnDefs,
        },
        {
          ...nextProps,
          columnDefs: this.props.doNotUpdateOnColumnDefChange ? null : nextProps.columnDefs,
        }
      ) || !equal(this.state, nextState)
    );
  }

  componentDidMount() {
    if (this.gridWrapper) {
      this.gridWrapper.addEventListener('wheel', this.handleWheelScroll, true);
    }
  }

  defaultCellClickHandler(event: CellClickedEvent) {
    toggleRowExpandedOnClick(event.node, event.api);
  }

  startGridResizerIfReady() {
    if (this.agGridResizer) {
      return;
    }
    if (this.gridApi && this.columnApi && this.gridWrapper) {
      this.agGridResizer = new AgGridResizer(
        this.gridApi,
        this.columnApi,
        this.gridWrapper,
        this.props.autoResizeMethod ?? AgGridColResizeMethod.FIT_WIDTH
      ).start();
    }
  }

  stopGridResizerIfRunning() {
    if (this.agGridResizer) {
      this.agGridResizer.stop();
      this.agGridResizer = undefined;
    }
  }

  componentWillUnmount() {
    this.stopGridResizerIfRunning();
    if (this.gridWrapper) {
      this.gridWrapper.removeEventListener('wheel', this.handleWheelScroll, true);
    }
    if (this.props.onGridUnmounted) {
      this.props.onGridUnmounted();
    }
    // We may be unmounting due to something suspending (vs actually being permanently unmounted), in which case we
    // force ag-grid to remount via the inclusion of `mountCount` in the key we pass to it, so to avoid using a stale
    // `gridApi` reference we unset it here.
    this.gridApi = undefined;
    // We want to force ag-grid to fully unmount immediately (via the updated key) so that we don't have a stale ag-grid
    // instance hanging around, potentially running into errors.
    this.unmountCount++;
  }

  componentDidUpdate(prevProps: Props<T, ContextValueType>) {
    if (this.props.filterText !== prevProps.filterText) {
      this.setFilterText(this.props.filterText || '');
    } else if (
      this.gridApi &&
      this.props.sortModel !== prevProps.sortModel &&
      this.props.sortModel
    ) {
      this.gridApi.setSortModel(this.props.sortModel);
    }

    if (this.gridApi && this.props.filter !== prevProps.filter) {
      this.gridApi.onFilterChanged();
    }

    if (
      Globals.dev &&
      this.props.grouped &&
      this.props.immutableData &&
      !equal(this.props.rowData, prevProps.rowData) &&
      (equal(this.props.columnDefs, prevProps.columnDefs) ||
        this.props.doNotUpdateOnColumnDefChange)
    ) {
      // Todo: remove this when we stop using ag-grid's deprecated tree data format (sc-194036)
      showAlert(
        'DEV WARNING: this table encountered a data update that will be buggily ignored by ag-grid. To fix this, set grouped=false for this table or force the table to update in some other way.'
      );
    }
  }

  getColumnDefsKey() {
    const current = getSimplifiedColDefsForKey(this.props.columnDefs);
    if (
      !(this.props.areNextColumnDefsCompatible || equal)(current, this.lastSimplifiedColumnDefs)
    ) {
      // We force a remount of ag-grid when the column defs are incompatible. Due to a bug in ag-grid
      // (https://github.com/ag-grid/ag-grid/issues/2141), changing the column defs can result in crashes.
      this.lastSimplifiedColumnDefs = current;
      this.lastColumnDefsKey++;
      this.props.onGridUnmounted?.();
    }
    return this.lastColumnDefsKey;
  }

  setFilterText = (filterText: string | undefined) => {
    if (!this.gridApi) {
      return;
    }

    this.gridApi.setQuickFilter(filterText);
    if (this.props.interactive && !this.props.isFlattened) {
      if (filterText) {
        this.gridApi.expandAll();
      } else {
        this.gridApi.collapseAll();
      }
    }
  };

  handleWheelScroll = (e: Event) => {
    if (!this.props.interactive) {
      e.stopPropagation();
    }
  };

  resizeColumns = (event: AgGridEvent) => {
    // use requestAnimationFrame so that the table finishes rendering before we try to resize the columns
    // or else the resize might be a noop
    requestAnimationFrame(() =>
      resizeAgGridColumns(
        event.api,
        event.columnApi,
        this.gridWrapper,
        this.props.autoResizeMethod ?? AgGridColResizeMethod.FIT_WIDTH
      )
    );
  };

  handleGridReady = (event: GridReadyEvent) => {
    this.stopGridResizerIfRunning();
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.startGridResizerIfReady();

    this.setFilterText(this.props.filterText);

    if (this.props.sortModel) {
      this.gridApi.setSortModel(this.props.sortModel);
    }

    event.api.addEventListener('sortChanged', () => {
      Analytics.track('Table: Change Sort');
      const sortModel = this.gridApi?.getSortModel();
      if (!equal(sortModel, this.props.sortModel)) {
        this.props.onSortModelChanged?.(sortModel!);
      }
    });

    this.props.onGridReady?.(event.api, event.columnApi);

    if (this.props.autoResize) {
      if (this.props.autoResizeMethod === AgGridColResizeMethod.FIT_CONTENT) {
        event.columnApi.autoSizeAllColumns(true);
      }
      this.resizeColumns(event);
    }
  };

  handleCellClick = (event: CellClickedEvent) => {
    if (!event.event?.defaultPrevented && this.props.interactive) {
      (this.props.cellClickHandler ?? this.defaultCellClickHandler)?.(event);
    }
  };

  handleSelectionChanged = (event: SelectionChangedEvent) => {
    const newSelectedRows = event.api.getSelectedNodes().map(row => row.data);
    if (!equal(newSelectedRows, this.state.lastSelectedRows)) {
      this.props.onSelectionChanged?.(event);
      this.setState({
        lastSelectedRows: newSelectedRows,
      });
    }
  };

  render() {
    const {
      autoResize,
      autoResizeMethod,
      borderless,
      className,
      compact,
      filter,
      filterText,
      frameworkComponents,
      grouped,
      hoverable,
      interactive,
      isFlattened,
      suspendWhileLoading,
      onGridReady,
      rowData,
      sortModel,
      ...agGridProps
    } = this.props;
    const augmentedGridOptions: GridOptions = {
      frameworkComponents,
      isExternalFilterPresent(): boolean {
        return !!filter;
      },
      doesExternalFilterPass: filter,
      getNodeChildDetails: grouped ? (row: any) => (row.group ? row : null) : undefined,
    };
    const opts = mergeAgGridProps<T, ContextValueType>(
      {...agGridProps, gridOptions: augmentedGridOptions},
      !!compact
    );
    const agGrid = (
      <LazyAgGrid
        {...opts}
        // Our current version of ag-grid doesn't handle un-suspending properly, and doesn't correctly re-render custom
        // cell renderers when this happens. To work around this, we keep track of the number of times the table has
        // mounted (React treats un-suspending as a remount) and include it in the key to force ag-grid to fully re-render
        // when a table re-mounts. This should all be able to be removed on future versions of ag-grid which claim to
        // handle suspending correctly.
        key={`ag-grid-${this.props.compact || ''}-${this.getColumnDefsKey()}-${this.unmountCount}`}
        rowData={rowData as any[]}
        suppressHorizontalScroll={this.props.compact || this.props.scrollDisabled}
        valueCache
        onCellClicked={this.handleCellClick}
        onFirstDataRendered={this.resizeColumns}
        onGridReady={this.handleGridReady}
        onModelUpdated={this.props.resizeOnModelUpdate ? this.resizeColumns : noop}
        onRowGroupOpened={this.resizeColumns}
        onSelectionChanged={this.handleSelectionChanged}
      />
    );

    return (
      <div
        ref={comp => {
          this.stopGridResizerIfRunning();
          this.gridWrapper = comp;
          this.startGridResizerIfReady();
        }}
        className={classNames('Table', className, {
          compact,
          disabled: !interactive,
          borderless,
          hoverable,
          'auto-height': this.props.domLayout === 'autoHeight',
          'has-header-drop-shadow': this.props.hasHeaderDropShadow ?? true,
          'has-row-divider-lines': this.props.hasRowDividerLines ?? true,
          'no-scroll': this.props.scrollDisabled,
          'has-clickable-rows': !!this.props.onRowClicked || !!this.props.cellClickHandler,
        })}
      >
        {suspendWhileLoading ? <Suspenseful>{agGrid}</Suspenseful> : agGrid}
      </div>
    );
  }
}

export type ColDefs<T, ContextValueType = unknown> = ReadonlyArray<
  TypedColDef<T, any, any, ContextValueType> | UntypedColGroupDef
>;

export type Props<T, ContextValueType = unknown> = Omit<
  AgGridReactProps,
  'columnDefs' | 'getRowClass' | 'onGridReady' | 'rowData' | 'gridOptions' | 'context'
> & {
  areNextColumnDefsCompatible?: (
    currentColDefs: ColDefs<T, ContextValueType> | undefined,
    previousColDefs: ColDefs<T, ContextValueType> | undefined
  ) => boolean;
  autoResize?: boolean;
  autoResizeMethod?: AgGridColResizeMethod;
  borderless?: boolean;
  className?: string;
  cellClickHandler?: (event: CellClickedEvent) => void;
  columnDefs: ColDefs<T, ContextValueType> | undefined;
  compact?: boolean;
  doNotUpdateOnColumnDefChange?: boolean;
  filter?: (node: RowNode) => boolean;
  filterText?: string;
  frameworkComponents?: {
    [p: string]: ConstructorOf<React.ElementType<any>>;
  };
  getRowClass?: (params: {
    data: T;
    node: RowNode;
    api: GridApi;
    [key: string]: any;
  }) => string | string[];
  getRowNodeId?: (data: T) => string;
  grouped?: boolean;
  hasHeaderDropShadow?: boolean;
  hasRowDividerLines?: boolean;
  hoverable?: boolean;
  isFlattened?: boolean;
  suspendWhileLoading?: boolean;
  interactive?: boolean;
  onGridReady?: (gridApi: GridApi, columnApi: ColumnApi) => void;
  onGridUnmounted?: () => void;
  onSortModelChanged?: (sortModel: SortModel) => void;
  resizeOnModelUpdate?: boolean;
  rowData: readonly T[] | T[] | null | undefined;
  scrollDisabled?: boolean;
  sortModel?: SortModel;
  context?: ContextValueType;
};
interface State {
  columnDefsKey: number;
  lastSelectedRows: unknown[];
}
