/* eslint-disable */

/*
  This file is generated from the OpenAPI specification created from pewter; do not edit it
  directly. To update, run `ore codegen`.
*/

import {apiWrapper, apiRequestWrapper, FetchExtras, Resource} from 'utils/api';

import * as Types from './types';

export namespace Assistant {
  export function clearCache(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'assistant/cache/clear', {}, {}, undefined, extras);
  }
  export function converse(
    requestBody: Types.AiConversationRequest,
    extras?: FetchExtras
  ): Promise<Types.ChatSession> {
    return apiRequestWrapper('post', 'assistant/converse', {}, {}, requestBody, extras);
  }
}

export namespace Attributes {
  export function assignValues(
    requestBody: {
      [key: string]: ReadonlyArray<Types.AttributeValue>;
    },
    extras?: FetchExtras
  ): Promise<Types.AttributeValuesUpdateResult> {
    return apiRequestWrapper('put', 'attributes/values', {}, {}, requestBody, extras);
  }
  export function augmentTransactionEvents(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'attributes/transaction/augment', {}, {}, undefined, extras);
  }
  export function autoComplete(
    requestBody: Types.AutoCompleteRequest,
    extras?: FetchExtras
  ): Promise<Types.AutoCompletionResult> {
    return apiRequestWrapper('post', 'attributes/autocomplete', {}, {}, requestBody, extras);
  }
  export function clearCache(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'attributes/clear', {}, {}, undefined, extras);
  }
  export function createPartnerLocationMatchingAttributeSet(
    requestBody: ReadonlyArray<string>,
    partnerId: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'attributes/location/matching-attributes/{partnerId}',
      {partnerId},
      {},
      requestBody,
      extras
    );
  }
  export function deleteAttribute(
    requestBody: Types.Attribute,
    extras?: FetchExtras
  ): Promise<Types.Attribute> {
    return apiRequestWrapper('delete', 'attributes', {}, {}, requestBody, extras);
  }
  export function deleteAttributeDescription(
    requestBody: Types.AttributeDescription,
    extras?: FetchExtras
  ): Promise<boolean> {
    return apiRequestWrapper('delete', 'attributes/descriptions', {}, {}, requestBody, extras);
  }
  export function deletePartnerLocationMatchingAttributeSet(
    requestBody: Types.PartnerLocationMatchingAttributeSet,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'attributes/location/matching-attributes-set',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteProducts(
    requestBody: ReadonlyArray<number>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'attributes/products', {}, {}, requestBody, extras);
  }
  export function deriveLocationAttributes(
    partnerId: number,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'attributes/location/derive/{partnerId}',
      {partnerId},
      {slackRecipient},
      undefined,
      extras
    );
  }
  export function deriveProductAttributes(
    vendorId?: number | null,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'attributes/product/derive',
      {},
      {vendorId, slackRecipient},
      undefined,
      extras
    );
  }
  export const getAllAttributes = apiWrapper(
    (requestBody: Types.Vendor, extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'post',
        'attributes/all',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getAllPopulatedAttributes(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.Attribute>> {
    return apiRequestWrapper('get', 'attributes', {}, {}, undefined, extras, []);
  }
  export const getAllProductIdentifiers = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'get',
        'attributes/product/identifiers/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAlloyIdAttributes = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'get',
        'attributes/alloy-id',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAttribute = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<Types.Attribute> =>
      new Resource<Types.Attribute>('get', 'attributes/{id}', {id}, {}, undefined, extras)
  );
  export const getAttributeDescriptions = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.AttributeDescription;
    }> =>
      new Resource<{
        [key: string]: Types.AttributeDescription;
      }>('get', 'attributes/descriptions', {}, {}, undefined, extras)
  );
  export const getAttributeValues = apiWrapper(
    (
      requestBody: Types.SingleAttributeQuery,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ThinAttributeValue>> =>
      new Resource<ReadonlyArray<Types.ThinAttributeValue>>(
        'post',
        'attributes/values',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getAttributesOfType = apiWrapper(
    (type: Types.AttributeType, extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'get',
        'attributes/type/{type}',
        {type},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAttributesSharingTemplate = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'get',
        'attributes/{id}/sharing-template',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getCrossVendorAttributeInfo = apiWrapper(
    (
      requestBody: ReadonlyArray<string> | null,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.CrossVendorAttributeInfo>> =>
      new Resource<ReadonlyArray<Types.CrossVendorAttributeInfo>>(
        'post',
        'attributes/name-info',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getDefaultAttributes(
    type: Types.AttributeHierarchyType,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.Attribute>> {
    return apiRequestWrapper('get', 'attributes/{type}/default', {type}, {}, undefined, extras, []);
  }
  export const getDefaultFilterAttributes = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'get',
        'attributes/default-filter',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getDerivationMatches = apiWrapper(
    (
      type: Types.ItemType,
      itemId: number,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ThinAttributeValue>> =>
      new Resource<ReadonlyArray<Types.ThinAttributeValue>>(
        'get',
        'attributes/{type}/matches/{itemId}',
        {type, itemId},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getDescriptionAttributeForIdentifier = apiWrapper(
    (requestBody: Types.Attribute, extras?: FetchExtras): Resource<Types.Attribute> =>
      new Resource<Types.Attribute>(
        'post',
        'attributes/product/partner-description-attribute',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getItems = apiWrapper(
    (
      requestBody: ReadonlyArray<Types.AttributeFilter>,
      itemType: Types.ItemType,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ThinAttributeValue>> =>
      new Resource<ReadonlyArray<Types.ThinAttributeValue>>(
        'post',
        'attributes/{itemType}/items',
        {itemType},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getMatchingProductsWithIdentifier = apiWrapper(
    (
      requestBody: ReadonlyArray<number>,
      identifierId: number,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: {
        [key: string]: Types.ThinAttributeValue;
      };
    }> =>
      new Resource<{
        [key: string]: {
          [key: string]: Types.ThinAttributeValue;
        };
      }>(
        'post',
        'attributes/product/matches/with-identifier/{identifierId}',
        {identifierId},
        {},
        requestBody,
        extras
      )
  );
  export const getMultiValueContents = apiWrapper(
    (
      attributeId?: number,
      itemId?: number,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.AttributeValue>> =>
      new Resource<ReadonlyArray<Types.AttributeValue>>(
        'get',
        'attributes/multivalue',
        {},
        {attributeId, itemId},
        undefined,
        extras,
        []
      )
  );
  export const getProductIdentifierSpec = apiWrapper(
    (extras?: FetchExtras): Resource<Types.ProductIdentifierSpec> =>
      new Resource<Types.ProductIdentifierSpec>(
        'get',
        'attributes/PRODUCT/identifiers/data',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getProductMappings = apiWrapper(
    (itemId: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.ProductMapping>> =>
      new Resource<ReadonlyArray<Types.ProductMapping>>(
        'get',
        'attributes/product-mappings/{itemId}',
        {itemId},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getProductMasterIdentifierAttributes = apiWrapper(
    (extras?: FetchExtras): Resource<Types.ProductIdentifierAttributes> =>
      new Resource<Types.ProductIdentifierAttributes>(
        'get',
        'attributes/product-identifier',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getProductsWithoutData = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<number>> =>
      new Resource<ReadonlyArray<number>>(
        'get',
        'attributes/product/deletables',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getSupplyingFiletypesByAttributeName = apiWrapper(
    (
      type: Types.AttributeType,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<string>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<string>;
      }>('get', 'attributes/type/{type}/supplying-filetypes', {type}, {}, undefined, extras)
  );
  export const getValuesByPartner = apiWrapper(
    (
      requestBody: Types.ValuesByPartnerRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.ThinAttributeValue>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.ThinAttributeValue>;
      }>(
        'post',
        'attributes/LOCATION/attribute-values/get-values-by-partner',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const hasDoubleCountedWalmartFulfillmentMethodsData = apiWrapper(
    (extras?: FetchExtras): Resource<boolean> =>
      new Resource<boolean>(
        'get',
        'attributes/LOCATION/double-counted-walmart-fulfillment-methods-data',
        {},
        {},
        undefined,
        extras
      )
  );
  export const query = apiWrapper(
    (
      requestBody: Types.AttributeQuery,
      extras?: FetchExtras
    ): Resource<Types.AttributeQueryResult> =>
      new Resource<Types.AttributeQueryResult>(
        'post',
        'attributes/query',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const queryAttributeValuesByProductId = apiWrapper(
    (
      requestBody: Types.AttributeValuesByProductQuery,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.AttributeValue>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.AttributeValue>;
      }>('post', 'attributes/product/attribute-values-by-product', {}, {}, requestBody, extras)
  );
  export const queryByValueIds = apiWrapper(
    (
      attributeId: number,
      id?: ReadonlyArray<number>,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.AttributeValue>> =>
      new Resource<ReadonlyArray<Types.AttributeValue>>(
        'get',
        'attributes/{attributeId}/values',
        {attributeId},
        {id},
        undefined,
        extras,
        []
      )
  );
  export const queryIdentifierValuesWithIdentifier = apiWrapper(
    (
      requestBody: Types.Attribute,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.AttributeValue;
    }> =>
      new Resource<{
        [key: string]: Types.AttributeValue;
      }>('post', 'attributes/product/identifier-values-by-identifier', {}, {}, requestBody, extras)
  );
  export const queryItem = apiWrapper(
    (
      itemType: Types.ItemType,
      itemId: number,
      extras?: FetchExtras
    ): Resource<Types.SingleAttributeQueryResult> =>
      new Resource<Types.SingleAttributeQueryResult>(
        'get',
        'attributes/{itemType}/{itemId}/values',
        {itemType, itemId},
        {},
        undefined,
        extras
      )
  );
  export const queryOriginalValuesByIdentifier = apiWrapper(
    (
      requestBody: Types.AttributesByIdentifierQuery,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: {
        [key: string]: Types.ThinAttributeValue;
      };
    }> =>
      new Resource<{
        [key: string]: {
          [key: string]: Types.ThinAttributeValue;
        };
      }>('post', 'attributes/product/original-values-by-identifier', {}, {}, requestBody, extras)
  );
  export const queryProductsByIdentifier = apiWrapper(
    (
      requestBody: Types.Attribute,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.Product>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.Product>;
      }>('post', 'attributes/product/products-by-identifier-value', {}, {}, requestBody, extras)
  );
  export const queryValuesByExactStrings = apiWrapper(
    (
      requestBody: Types.ExactValuesQuery,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.AttributeValue>> =>
      new Resource<ReadonlyArray<Types.AttributeValue>>(
        'post',
        'attributes/matching-values',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function saveAttribute(
    requestBody: Types.Attribute,
    extras?: FetchExtras
  ): Promise<Types.Attribute> {
    return apiRequestWrapper('put', 'attributes', {}, {}, requestBody, extras);
  }
  export function saveAttributeDescription(
    requestBody: Types.AttributeDescription,
    extras?: FetchExtras
  ): Promise<Types.AttributeDescription> {
    return apiRequestWrapper('put', 'attributes/descriptions', {}, {}, requestBody, extras);
  }
  export function setDefaultAttributes(
    requestBody: {
      [key: string]: ReadonlyArray<Types.Attribute>;
    },
    defaultFilterProductAttributeId?: number | null,
    defaultFilterLocationAttributeId?: number | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'put',
      'attributes/default',
      {},
      {defaultFilterProductAttributeId, defaultFilterLocationAttributeId},
      requestBody,
      extras
    );
  }
  export function setProductMasterIdentifierAttributes(
    requestBody: Types.ProductIdentifierAttributes,
    extras?: FetchExtras
  ): Promise<Types.ProductIdentifierAttributes> {
    return apiRequestWrapper('put', 'attributes/product-identifier', {}, {}, requestBody, extras);
  }
  export function setProductMatches(
    requestBody: Types.SetProductMatchesRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'attributes/product/set-matches', {}, {}, requestBody, extras);
  }
  export const testGenerateProductAttributeDerivationRegressionResultCSV = apiWrapper(
    (
      includeOnlyVendorToPartnerMatches?: boolean | null,
      includeNewValues?: boolean | null,
      extras?: FetchExtras
    ): Resource<Types.GenerateProductAttributeDerivationRegressionResultMessage> =>
      new Resource<Types.GenerateProductAttributeDerivationRegressionResultMessage>(
        'get',
        'attributes/product/test/derive/generate-csv',
        {},
        {includeOnlyVendorToPartnerMatches, includeNewValues},
        undefined,
        extras
      )
  );
  export function vendorDeriveLocationAttributes(
    vendorId: number,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<string>> {
    return apiRequestWrapper(
      'post',
      'attributes/location/derive/vendor/{vendorId}',
      {vendorId},
      {},
      undefined,
      extras,
      []
    );
  }
  export function willProductsMatch(
    requestBody: Types.WillProductsMatchQuery,
    extras?: FetchExtras
  ): Promise<Types.ProductMatchQueryResult> {
    return apiRequestWrapper('post', 'attributes/product/will-match', {}, {}, requestBody, extras);
  }
}

export namespace Authentication {
  export function activate(
    requestBody: Types.ActivationJson,
    extras?: FetchExtras
  ): Promise<Types.LoginResponse> {
    return apiRequestWrapper('post', 'auth/activate', {}, {}, requestBody, extras);
  }
  export const callbackGet = apiWrapper(
    (extras?: FetchExtras): Resource<void> =>
      new Resource<void>('get', 'auth/callback', {}, {}, undefined, extras)
  );
  export function callbackPost(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'auth/callback', {}, {}, undefined, extras);
  }
  export function checkToken(requestBody: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'auth/check-token', {}, {}, requestBody, extras);
  }
  export function deleteCredential(requestBody: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'auth/credentials', {}, {}, requestBody, extras);
  }
  export function deleteSubscriptionOnlyUser(
    requestBody: Types.SubscriptionOnlyUser,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'auth/subscription-only-users', {}, {}, requestBody, extras);
  }
  export function deleteUser(
    requestBody: Types.DeleteUserRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'auth/user', {}, {}, requestBody, extras);
  }
  export function deleteUsers(
    requestBody: ReadonlyArray<Types.DeleteUserRequest>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'auth/users', {}, {}, requestBody, extras);
  }
  export function forgotPassword(requestBody: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'auth/forgot-password', {}, {}, requestBody, extras);
  }
  export function generateApiToken(extras?: FetchExtras): Promise<Types.JwtToken> {
    return apiRequestWrapper('post', 'auth/tokens', {}, {}, undefined, extras);
  }
  export function generateServiceAccountToken(
    serviceaccount: string,
    extras?: FetchExtras
  ): Promise<Types.JwtToken> {
    return apiRequestWrapper(
      'post',
      'auth/tokens/{service-account}',
      {serviceaccount},
      {},
      undefined,
      extras
    );
  }
  export const getActiveTokens = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.CredentialToken>> =>
      new Resource<ReadonlyArray<Types.CredentialToken>>(
        'get',
        'auth/tokens',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAllUsers = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.User>> =>
      new Resource<ReadonlyArray<Types.User>>(
        'get',
        'auth/users/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getAllowedVendors(extras?: FetchExtras): Promise<ReadonlyArray<Types.Vendor>> {
    return apiRequestWrapper('get', 'auth/vendors', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getCurrentUser(extras?: FetchExtras): Promise<Types.CurrentUserData> {
    return apiRequestWrapper('get', 'auth/me', {}, {}, undefined, extras);
  }
  export const getCurrentUserInfo = apiWrapper(
    (extras?: FetchExtras): Resource<Types.UserInfo> =>
      new Resource<Types.UserInfo>('get', 'auth/me/user-info', {}, {}, undefined, extras)
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getIntercomCredentials(
    extras?: FetchExtras
  ): Promise<Types.UserIntercomCredentials> {
    return apiRequestWrapper('get', 'auth/me/intercom', {}, {}, undefined, extras);
  }
  export const getSubscriptionOnlyUsers = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.SubscriptionOnlyUser>> =>
      new Resource<ReadonlyArray<Types.SubscriptionOnlyUser>>(
        'get',
        'auth/subscription-only-users',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getUserInfos(extras?: FetchExtras): Promise<{
    [key: string]: Types.UserInfo;
  }> {
    return apiRequestWrapper('get', 'auth/users/info', {}, {}, undefined, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getUsers(extras?: FetchExtras): Promise<ReadonlyArray<Types.User>> {
    return apiRequestWrapper('get', 'auth/users', {}, {}, undefined, extras, []);
  }
  export const getZendeskAuthentication = apiWrapper(
    (extras?: FetchExtras): Resource<Types.ZendeskAuthentication> =>
      new Resource<Types.ZendeskAuthentication>('get', 'auth/me/zendesk', {}, {}, undefined, extras)
  );
  export function login(
    requestBody: Types.CredentialJson,
    extras?: FetchExtras
  ): Promise<Types.LoginResponse> {
    return apiRequestWrapper('post', 'auth', {}, {}, requestBody, extras);
  }
  export const loginWithSso = apiWrapper(
    (extras?: FetchExtras): Resource<void> =>
      new Resource<void>('get', 'auth/sso', {}, {}, undefined, extras)
  );
  export function logout(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'auth/logout', {}, {}, undefined, extras);
  }
  export function resendActivationEmail(requestBody: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'auth/resend-activation', {}, {}, requestBody, extras);
  }
  export function resetPassword(
    requestBody: Types.ActivationJson,
    extras?: FetchExtras
  ): Promise<Types.LoginResponse> {
    return apiRequestWrapper('post', 'auth/reset-password', {}, {}, requestBody, extras);
  }
  export function revokeAllTokens(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'auth/tokens', {}, {}, undefined, extras);
  }
  export function revokeToken(tokenId: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'auth/tokens/{tokenId}', {tokenId}, {}, undefined, extras);
  }
  export function saveSubscriptionOnlyUser(
    requestBody: Types.SubscriptionOnlyUser,
    extras?: FetchExtras
  ): Promise<Types.SubscriptionOnlyUser> {
    return apiRequestWrapper('put', 'auth/subscription-only-users', {}, {}, requestBody, extras);
  }
  export function saveUser(requestBody: Types.User, extras?: FetchExtras): Promise<Types.User> {
    return apiRequestWrapper('put', 'auth/user', {}, {}, requestBody, extras);
  }
  export function saveUserInfo(
    requestBody: Types.UserInfo,
    email?: string,
    extras?: FetchExtras
  ): Promise<Types.UserInfo> {
    return apiRequestWrapper('post', 'auth/info', {}, {email}, requestBody, extras);
  }
  export function saveUserInfos(
    requestBody: {
      [key: string]: Types.UserInfo;
    },
    extras?: FetchExtras
  ): Promise<{
    [key: string]: Types.UserInfo;
  }> {
    return apiRequestWrapper('post', 'auth/infos', {}, {}, requestBody, extras);
  }
  export function saveUsers(
    requestBody: ReadonlyArray<Types.User>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.User>> {
    return apiRequestWrapper('put', 'auth/users', {}, {}, requestBody, extras, []);
  }
  export function toggleSubscribedForSubscriptionOnlyUser(
    email?: string,
    extras?: FetchExtras
  ): Promise<boolean> {
    return apiRequestWrapper(
      'put',
      'auth/subscription-only-user/toggle-subscribed',
      {},
      {email},
      undefined,
      extras
    );
  }
}

export namespace Authorization {
  export function createPackage(
    requestBody: Types.ApplicationPackage,
    extras?: FetchExtras
  ): Promise<Types.ApplicationPackage> {
    return apiRequestWrapper('post', 'auth/roles/packages', {}, {}, requestBody, extras);
  }
  export function createRole(
    requestBody: Types.RbacRole,
    extras?: FetchExtras
  ): Promise<Types.RbacRole> {
    return apiRequestWrapper('post', 'auth/roles', {}, {}, requestBody, extras);
  }
  export function deletePackage(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'auth/roles/packages/{id}', {id}, {}, undefined, extras);
  }
  export function deletePermission(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'auth/roles/permissions/{id}', {id}, {}, undefined, extras);
  }
  export function deleteRole(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'auth/roles/{id}', {id}, {}, undefined, extras);
  }
  export const getAllPermissionKeys = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.PermissionKey>> =>
      new Resource<ReadonlyArray<Types.PermissionKey>>(
        'get',
        'auth/roles/permission-keys',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAllPermissions = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Permission>> =>
      new Resource<ReadonlyArray<Types.Permission>>(
        'get',
        'auth/roles/permissions',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAllRoles = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.RbacRole>> =>
      new Resource<ReadonlyArray<Types.RbacRole>>(
        'get',
        'auth/roles/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getApplicationPackages = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ApplicationPackage>> =>
      new Resource<ReadonlyArray<Types.ApplicationPackage>>(
        'get',
        'auth/roles/packages',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getGrants = apiWrapper(
    (granteeId: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.Grant>> =>
      new Resource<ReadonlyArray<Types.Grant>>(
        'get',
        'auth/roles/grants/{granteeId}',
        {granteeId},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getRoles = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.RbacRole>> =>
      new Resource<ReadonlyArray<Types.RbacRole>>(
        'get',
        'auth/roles',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function grantRole(
    requestBody: Types.GrantDefinition,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'auth/roles/grants', {}, {}, requestBody, extras);
  }
  export function revokeRole(
    granteeId: number,
    roleId: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'auth/roles/grants/{granteeId}/{roleId}',
      {granteeId, roleId},
      {},
      undefined,
      extras
    );
  }
  export function updatePackage(
    requestBody: Types.ApplicationPackage,
    id: number,
    extras?: FetchExtras
  ): Promise<Types.ApplicationPackage> {
    return apiRequestWrapper('put', 'auth/roles/packages/{id}', {id}, {}, requestBody, extras);
  }
  export function updatePermission(
    requestBody: Types.Permission,
    id: number,
    extras?: FetchExtras
  ): Promise<Types.Permission> {
    return apiRequestWrapper('put', 'auth/roles/permissions/{id}', {id}, {}, requestBody, extras);
  }
  export function updateRole(
    requestBody: Types.RbacRole,
    id: number,
    extras?: FetchExtras
  ): Promise<Types.RbacRole> {
    return apiRequestWrapper('put', 'auth/roles/{id}', {id}, {}, requestBody, extras);
  }
}

export namespace BigQuery {
  export function exportCurrencyConversionRatesToBigQuery(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper(
      'post',
      'bigquery/currency-conversion-rates/export',
      {},
      {},
      undefined,
      extras
    );
  }
  export function exportGraphPathToBigQuery(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'bigquery/graph-path/export', {}, {}, undefined, extras);
  }
  export function exportLocationAttributesToBigQuery(
    partnerId?: number,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'bigquery/attributes/location/export',
      {},
      {partnerId},
      undefined,
      extras
    );
  }
  export function exportMetricsToBigQuery(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'bigquery/metrics/export', {}, {}, undefined, extras);
  }
  export function exportProductAttributesToBigQuery(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper(
      'post',
      'bigquery/attributes/product/export',
      {},
      {},
      undefined,
      extras
    );
  }
  export function preview(
    requestBody: Types.ComputeRequest,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.QueryJobInfo>> {
    return apiRequestWrapper('post', 'bigquery/query/preview', {}, {}, requestBody, extras, []);
  }
  export function recreateRollupViews(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper(
      'post',
      'bigquery/data/recreate-rollup-views',
      {},
      {},
      undefined,
      extras
    );
  }
}

export namespace CalendarEvents {
  export function bulkDelete(
    requestBody: ReadonlyArray<Types.CalendarEvent>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.CalendarEvent>> {
    return apiRequestWrapper(
      'delete',
      'calendar-events/bulk/delete',
      {},
      {},
      requestBody,
      extras,
      []
    );
  }
  export function bulkEdit(
    requestBody: ReadonlyArray<Types.CalendarEvent>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.CalendarEvent>> {
    return apiRequestWrapper('post', 'calendar-events/bulk/edit', {}, {}, requestBody, extras, []);
  }
  export function deleteEvent(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'calendar-events/{id}', {id}, {}, undefined, extras);
  }
  export function duplicate(
    requestBody: ReadonlyArray<Types.CalendarEvent>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.CalendarEvent>> {
    return apiRequestWrapper(
      'post',
      'calendar-events/bulk/duplicate',
      {},
      {},
      requestBody,
      extras,
      []
    );
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getAllEvents(extras?: FetchExtras): Promise<ReadonlyArray<Types.CalendarEvent>> {
    return apiRequestWrapper('get', 'calendar-events/query-all', {}, {}, undefined, extras, []);
  }
  export const getEvents = apiWrapper(
    (
      requestBody: Types.CalendarEventsRequest,
      extras?: FetchExtras
    ): Resource<Types.CalendarEventResult> =>
      new Resource<Types.CalendarEventResult>(
        'post',
        'calendar-events/query',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const isBaselineSupported = apiWrapper(
    (extras?: FetchExtras): Resource<boolean> =>
      new Resource<boolean>('get', 'calendar-events/baseline-supported', {}, {}, undefined, extras)
  );
  export function saveEvent(
    requestBody: Types.CalendarEvent,
    extras?: FetchExtras
  ): Promise<Types.CalendarEvent> {
    return apiRequestWrapper('put', 'calendar-events', {}, {}, requestBody, extras);
  }
}

export namespace Calendars {
  export const getAllCalendarAliases = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<string>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<string>;
      }>('get', 'calendars/aliases/all', {}, {}, undefined, extras)
  );
  export const getAllCalendars = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.CalendarProperties>> =>
      new Resource<ReadonlyArray<Types.CalendarProperties>>(
        'get',
        'calendars/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAvailableCalendars = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.CalendarProperties>> =>
      new Resource<ReadonlyArray<Types.CalendarProperties>>(
        'get',
        'calendars',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getCalendarPreview = apiWrapper(
    (
      requestBody: Types.CalendarPreviewRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.NamedInterval>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.NamedInterval>;
      }>('post', 'calendars/preview', {}, {}, requestBody, extras)
  );
  export const getIntervals = apiWrapper(
    (
      requestBody: Types.IntervalDisplayNameRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.LocalInterval>> =>
      new Resource<ReadonlyArray<Types.LocalInterval>>(
        'post',
        'calendars/intervals/query',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function setAvailableCalendars(
    requestBody: {
      [key: string]: string;
    },
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'calendars', {}, {}, requestBody, extras);
  }
}

export namespace Compute {
  export function bulkAggregationRequest(
    requestBody: Types.BulkAggregationRequest,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<string>> {
    return apiRequestWrapper('post', 'compute/aggregate/bulk', {}, {}, requestBody, extras, []);
  }
  export function clearCache(
    partnerId?: number | null,
    metricIds?: ReadonlyArray<number>,
    vendorId?: number | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'compute/clear',
      {},
      {partnerId, metricIds, vendorId},
      undefined,
      extras
    );
  }
  export function compute(
    requestBody: Types.ComputeRequest,
    extras?: FetchExtras
  ): Promise<Types.ComputeResult> {
    return apiRequestWrapper('post', 'compute', {}, {}, requestBody, extras);
  }
  export function generateComputeRequest(
    count?: number,
    seed?: number | null,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.ComputeRequest>> {
    return apiRequestWrapper('post', 'compute/generate', {}, {count, seed}, undefined, extras, []);
  }
  export const getAugmentedEdgeData = apiWrapper(
    (
      requestBody: Types.AugmentedEdgeDataRequest,
      extras?: FetchExtras
    ): Resource<Types.AugmentedDataResponse> =>
      new Resource<Types.AugmentedDataResponse>(
        'post',
        'compute/augmented/edge',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getAugmentedNodeData = apiWrapper(
    (
      requestBody: Types.AugmentedNodeDataRequest,
      extras?: FetchExtras
    ): Resource<Types.AugmentedDataResponse> =>
      new Resource<Types.AugmentedDataResponse>(
        'post',
        'compute/augmented/node',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getAugmentedNodeDataset = apiWrapper(
    (
      requestBody: Types.AugmentedNodeDatasetRequest,
      extras?: FetchExtras
    ): Resource<Types.AugmentedDatasetResponse> =>
      new Resource<Types.AugmentedDatasetResponse>(
        'post',
        'compute/augmented/node/dataset',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getComputeRequest = apiWrapper(
    (requestId: string, extras?: FetchExtras): Resource<Types.ComputeRequest> =>
      new Resource<Types.ComputeRequest>(
        'get',
        'compute/requests/{requestId}',
        {requestId},
        {},
        undefined,
        extras
      )
  );
  export const getComputeRequestStatsForRequest = apiWrapper(
    (requestId: string, extras?: FetchExtras): Resource<Types.ComputeRequestStats> =>
      new Resource<Types.ComputeRequestStats>(
        'post',
        'compute/stats/{requestId}',
        {requestId},
        {},
        undefined,
        extras
      )
  );
  export const getEpoch = apiWrapper(
    (extras?: FetchExtras): Resource<string> =>
      new Resource<string>('get', 'compute/epoch', {}, {}, undefined, extras)
  );
  export const getNetworkStats = apiWrapper(
    (
      requestBody: Types.NetworkStatsRequest,
      removeDoubleCountingPartners?: boolean,
      extras?: FetchExtras
    ): Resource<Types.VendorNetworkStats> =>
      new Resource<Types.VendorNetworkStats>(
        'post',
        'compute/network',
        {},
        {removeDoubleCountingPartners},
        requestBody,
        extras
      )
  );
  export const getSortedActiveAttributeValues = apiWrapper(
    (
      requestBody: Types.ActiveAttributeValuesRequest,
      removeDoubleCountingPartners?: boolean,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ThinAttributeValue>> =>
      new Resource<ReadonlyArray<Types.ThinAttributeValue>>(
        'post',
        'compute/network/active-values',
        {},
        {removeDoubleCountingPartners},
        requestBody,
        extras,
        []
      )
  );
  export const getSortedActivePartners = apiWrapper(
    (
      requestBody: Types.NetworkStatsRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ThinAttributeValue>> =>
      new Resource<ReadonlyArray<Types.ThinAttributeValue>>(
        'post',
        'compute/network/active-partners',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getWidgetComputeRequestStats = apiWrapper(
    (
      widgetId: string,
      limit?: number | null,
      includeCacheHits?: boolean | null,
      sinceDate?: string | null,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ComputeRequestStats>> =>
      new Resource<ReadonlyArray<Types.ComputeRequestStats>>(
        'get',
        'compute/widget-requests/{widgetId}',
        {widgetId},
        {limit, includeCacheHits, sinceDate},
        undefined,
        extras,
        []
      )
  );
  export const getWidgetComputeRequests = apiWrapper(
    (
      sinceDate?: string | null,
      limit?: number | null,
      minRequestCount?: number | null,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.WidgetComputeStats>> =>
      new Resource<ReadonlyArray<Types.WidgetComputeStats>>(
        'get',
        'compute/widget-requests',
        {},
        {sinceDate, limit, minRequestCount},
        undefined,
        extras,
        []
      )
  );
  export function plan(
    requestBody: Types.PlanComputeRequest,
    extras?: FetchExtras
  ): Promise<Types.ComputeResult> {
    return apiRequestWrapper('post', 'compute/plan', {}, {}, requestBody, extras);
  }
  export function registerRequest(
    requestBody: Types.ComputeRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'compute/register', {}, {}, requestBody, extras);
  }
  export function runPrecacher(
    requestBody: Types.PrecacheRequest,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper('post', 'compute/precache', {}, {slackRecipient}, requestBody, extras);
  }
  export function storeProductLocations(
    requestBody: ReadonlyArray<number> | null,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<string>> {
    return apiRequestWrapper(
      'post',
      'compute/populate-product-locations',
      {},
      {},
      requestBody,
      extras,
      []
    );
  }
  export function updateEdgeAggregations(
    requestBody: Types.EdgeRequest,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'compute/aggregate/edge',
      {},
      {slackRecipient},
      requestBody,
      extras
    );
  }
  export function updateEpoch(waitForListeners?: boolean, extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper(
      'post',
      'compute/update-epoch',
      {},
      {waitForListeners},
      undefined,
      extras
    );
  }
  export function updateNodeAggregations(
    requestBody: Types.NodeRequest,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<string>> {
    return apiRequestWrapper(
      'post',
      'compute/aggregate/node',
      {},
      {slackRecipient},
      requestBody,
      extras,
      []
    );
  }
  export function validatePopularDashboards(
    requestBody: Types.ViewValidationRequest,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'compute/precache/validate-popular',
      {},
      {slackRecipient},
      requestBody,
      extras
    );
  }
}

export namespace Currency {
  export function clearCache(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'currency/available/clear', {}, {}, undefined, extras);
  }
  export const conversionRatesAvailability = apiWrapper(
    (extras?: FetchExtras): Resource<boolean> =>
      new Resource<boolean>(
        'get',
        'currency/conversion-rates-availability',
        {},
        {},
        undefined,
        extras
      )
  );
  export function deleteConversionRate(
    requestBody: Types.ConversionRateItem,
    extras?: FetchExtras
  ): Promise<Types.ConversionRateItem> {
    return apiRequestWrapper('delete', 'currency/conversion-rates', {}, {}, requestBody, extras);
  }
  export const getAvailableCurrencies = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.CurrencyCode>> =>
      new Resource<ReadonlyArray<Types.CurrencyCode>>(
        'get',
        'currency/available',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getConversionRates = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ConversionRateItem>> =>
      new Resource<ReadonlyArray<Types.ConversionRateItem>>(
        'get',
        'currency/conversion-rates',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function insertConversionRate(
    requestBody: Types.ConversionRateItem,
    extras?: FetchExtras
  ): Promise<Types.ConversionRateItem> {
    return apiRequestWrapper('post', 'currency/conversion-rates', {}, {}, requestBody, extras);
  }
  export function updateConversionRate(
    requestBody: Types.ConversionRateItem,
    extras?: FetchExtras
  ): Promise<Types.ConversionRateItem> {
    return apiRequestWrapper('put', 'currency/conversion-rates', {}, {}, requestBody, extras);
  }
}

export namespace DataManagement {
  export function deleteData(
    requestBody: Types.DataDeletionRequest,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper('post', 'data/management/delete', {}, {}, requestBody, extras);
  }
  export function deleteEdgeAggregations(
    requestBody: Types.EdgeDataDeletionRequest,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'data/management/delete/edge/aggregations',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteNodeAggregations(
    requestBody: Types.NodeDataDeletionRequest,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'data/management/delete/node/aggregations',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deletionPreview(
    requestBody: Types.DataSpecification,
    extras?: FetchExtras
  ): Promise<Types.AffectedDeletionMetrics> {
    return apiRequestWrapper('post', 'data/management/delete/preview', {}, {}, requestBody, extras);
  }
  export function forceToType(
    requestBody: Types.ForceIntervalTypeRequest,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'data/management/ranges/interval-type',
      {},
      {},
      requestBody,
      extras
    );
  }
}

export namespace DataStatus {
  export function backfillAvailableAt(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'data-status/backfill-available-at',
      {},
      {},
      undefined,
      extras
    );
  }
  export function createOrUpdateIntegration(
    requestBody: Types.DataIntegration,
    extras?: FetchExtras
  ): Promise<Types.DataIntegration> {
    return apiRequestWrapper(
      'put',
      'data-status/data-source-integrations',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function createVendorDataSource(
    requestBody: Types.CreateDataSourceRequest,
    extras?: FetchExtras
  ): Promise<Types.CreateDataSourceResponse> {
    return apiRequestWrapper(
      'post',
      'data-status/vendor-data-sources',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteIntegration(
    requestBody: Types.DataIntegration,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'data-status/data-source-integrations',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteVendorDataSource(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'data-status/vendor-data-sources/{id}',
      {id},
      {},
      undefined,
      extras
    );
  }
  export const getAllDataRanges = apiWrapper(
    (partnerId?: number | null, extras?: FetchExtras): Resource<Types.AllDataRangeResponse> =>
      new Resource<Types.AllDataRangeResponse>(
        'get',
        'data-status/ranges',
        {},
        {partnerId},
        undefined,
        extras
      )
  );
  export const getAllEdgePartnerIds = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.OriginDestinationPartner>> =>
      new Resource<ReadonlyArray<Types.OriginDestinationPartner>>(
        'post',
        'data-status/ranges/edge-partner-ids',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAvailableDataIntervalByVdsfId = apiWrapper(
    (
      requestBody: Types.DataStatusPartnerTabRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.PartnerIdentifierMetricInterval>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.PartnerIdentifierMetricInterval>;
      }>('post', 'data-status/available-data', {}, {}, requestBody, extras)
  );
  export const getCountriesByVendorIntegration = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<string>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<string>;
      }>('get', 'data-status/countries-by-vendor-integration', {}, {}, undefined, extras)
  );
  export const getDataAvailableEstimate = apiWrapper(
    (fileId: number, extras?: FetchExtras): Resource<string> =>
      new Resource<string>(
        'get',
        'data-status/data-available-estimate/{fileId}',
        {fileId},
        {},
        undefined,
        extras
      )
  );
  export const getDataCenterDetails = apiWrapper(
    (
      partnerId?: number,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.VendorDataSourceFiletypeWithMetrics>> =>
      new Resource<ReadonlyArray<Types.VendorDataSourceFiletypeWithMetrics>>(
        'get',
        'data-status/data-center/details',
        {},
        {partnerId},
        undefined,
        extras,
        []
      )
  );
  export const getDataCenterStatuses = apiWrapper(
    (
      requestBody: Types.DataStatusPartnerTabDateRangeRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.DataStatusResponse;
    }> =>
      new Resource<{
        [key: string]: Types.DataStatusResponse;
      }>('post', 'data-status/data-center-status', {}, {}, requestBody, extras)
  );
  export const getDataCenterSummaries = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DataCenterSummary>> =>
      new Resource<ReadonlyArray<Types.DataCenterSummary>>(
        'get',
        'data-status/data-center-summaries',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getDataCenterSummaryStatuses = apiWrapper(
    (
      tab?: Types.DataStatusTabType,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.DailyDataStatus;
    }> =>
      new Resource<{
        [key: string]: Types.DailyDataStatus;
      }>('get', 'data-status/data-center-summary-statuses', {}, {tab}, undefined, extras)
  );
  export const getDataDeletions = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DataDeletion>> =>
      new Resource<ReadonlyArray<Types.DataDeletion>>(
        'get',
        'data-status/deletions',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getDataPredictions = apiWrapper(
    (
      requestBody: Types.DataStatusPartnerTabDateRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.PredictionResult>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.PredictionResult>;
      }>('post', 'data-status/data-predictions', {}, {}, requestBody, extras)
  );
  export const getDataSources = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DataSource>> =>
      new Resource<ReadonlyArray<Types.DataSource>>(
        'get',
        'data-status/data-sources',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getDataStatusForRanges = apiWrapper(
    (
      requestBody: Types.DataStatusPartnerTabVdsfDateRangeRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.DataStatusResponse;
    }> =>
      new Resource<{
        [key: string]: Types.DataStatusResponse;
      }>('post', 'data-status/data-center-data-ranges-status', {}, {}, requestBody, extras)
  );
  export const getDataSummaryByFiletype = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.FileTypeDataSummary>> =>
      new Resource<ReadonlyArray<Types.FileTypeDataSummary>>(
        'get',
        'data-status/ranges/filetype',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getFileReceiptStatuses = apiWrapper(
    (
      requestBody: Types.FileReceiptStatusRequest,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.DataStatusResponse;
    }> =>
      new Resource<{
        [key: string]: Types.DataStatusResponse;
      }>('post', 'data-status/file-receipt-status', {}, {}, requestBody, extras)
  );
  export const getFullVendorIntegration = apiWrapper(
    (integrationId: number, extras?: FetchExtras): Resource<Types.FullVendorDataIntegration> =>
      new Resource<Types.FullVendorDataIntegration>(
        'get',
        'data-status/full-vendor-integration/{integrationId}',
        {integrationId},
        {},
        undefined,
        extras
      )
  );
  export const getGroupedVendorDataSources = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DisplayableSource>> =>
      new Resource<ReadonlyArray<Types.DisplayableSource>>(
        'get',
        'data-status/vendor-data-sources/grouped',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getIntegrations = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DataIntegration>> =>
      new Resource<ReadonlyArray<Types.DataIntegration>>(
        'get',
        'data-status/integrations',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getIssue = apiWrapper(
    (dataIssueId: number, extras?: FetchExtras): Resource<Types.IssueResponse> =>
      new Resource<Types.IssueResponse>(
        'get',
        'data-status/issues/{dataIssueId}',
        {dataIssueId},
        {},
        undefined,
        extras
      )
  );
  export const getLastUpdatedByVendorDataSourceFiletypeId = apiWrapper(
    (
      requestBody: ReadonlyArray<number>,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: string;
    }> =>
      new Resource<{
        [key: string]: string;
      }>('post', 'data-status/last-updated', {}, {}, requestBody, extras)
  );
  export const getMarketingDataImages = apiWrapper(
    (extras?: FetchExtras): Resource<Types.MarketingDataImagesResponse> =>
      new Resource<Types.MarketingDataImagesResponse>(
        'get',
        'data-status/integrations/marketing/images',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getMarketingDataIntegrations = apiWrapper(
    (extras?: FetchExtras): Resource<Types.MarketingDataIntegrationsResponse> =>
      new Resource<Types.MarketingDataIntegrationsResponse>(
        'get',
        'data-status/integrations/marketing',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getNextScheduledScraperRuns = apiWrapper(
    (
      vendorDataSourceFiletypeIds?: ReadonlyArray<number>,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.ScraperRun>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.ScraperRun>;
      }>(
        'get',
        'data-status/next-scraper-run',
        {},
        {vendorDataSourceFiletypeIds},
        undefined,
        extras
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getOriginalEdgeDataRanges(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.EdgeDataRange>> {
    return apiRequestWrapper('get', 'data-status/ranges/edge', {}, {}, undefined, extras, []);
  }
  export const getRestatementFiletypes = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<string>> =>
      new Resource<ReadonlyArray<string>>(
        'get',
        'data-status/data-sources/{id}/restatement-filetypes',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScraperInfosByFiletype = apiWrapper(
    (
      integrationId: number,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.Scraper>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.Scraper>;
      }>(
        'post',
        'data-status/integration/{integrationId}/scrapers-by-filetype',
        {integrationId},
        {},
        undefined,
        extras
      )
  );
  export const getVendorDataSources = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VendorDataSource>> =>
      new Resource<ReadonlyArray<Types.VendorDataSource>>(
        'get',
        'data-status/vendor-data-sources',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getVendorIntegrations = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VendorDataIntegration>> =>
      new Resource<ReadonlyArray<Types.VendorDataIntegration>>(
        'get',
        'data-status/vendor-data-integrations',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const hasItemExclusions = apiWrapper(
    (extras?: FetchExtras): Resource<boolean> =>
      new Resource<boolean>('get', 'data-status/has-item-exclusions', {}, {}, undefined, extras)
  );
  export const hasVendorDataSourcesByIntegration = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: boolean;
    }> =>
      new Resource<{
        [key: string]: boolean;
      }>('get', 'data-status/integration-has-vds', {}, {}, undefined, extras)
  );
  export function moveHistoricalPendingRanges(
    requestBody: Types.PartnerMetadata,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'data-status/ranges/pending/historical/move',
      {},
      {},
      requestBody,
      extras
    );
  }
  export const queryAggregateDataStatus = apiWrapper(
    (
      requestBody: Types.AggregateDataStatusQuery,
      extras?: FetchExtras
    ): Resource<Types.DataStatusQueryResult> =>
      new Resource<Types.DataStatusQueryResult>(
        'post',
        'data-status/query/aggregate',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const queryDataStatus = apiWrapper(
    (
      requestBody: Types.DataStatusQuery,
      extras?: FetchExtras
    ): Resource<Types.DataStatusQueryResult> =>
      new Resource<Types.DataStatusQueryResult>(
        'post',
        'data-status/query',
        {},
        {},
        requestBody,
        extras
      )
  );
  export function replaceDataSourceContacts(
    requestBody: ReadonlyArray<Types.UpdateDataSourceContactsRequest>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'put',
      'data-status/data-source-contacts',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function runSummarizeDataStatus(
    vendorId?: number | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'data-status/summarize', {}, {vendorId}, undefined, extras);
  }
  export function saveVendorDataIntegration(
    requestBody: Types.VendorDataIntegration,
    extras?: FetchExtras
  ): Promise<Types.VendorDataIntegration> {
    return apiRequestWrapper(
      'put',
      'data-status/vendor-data-integrations',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function updateVendorDataSource(
    requestBody: Types.VendorDataSource,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'data-status/vendor-data-sources', {}, {}, requestBody, extras);
  }
}

export namespace DefaultFilters {
  export const getAllUserDefaultFilters = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.UserFilters>> =>
      new Resource<ReadonlyArray<Types.UserFilters>>(
        'get',
        'default-filters/users',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getUserDefaultFilters = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.AttributeFilter>> =>
      new Resource<ReadonlyArray<Types.AttributeFilter>>(
        'get',
        'default-filters/user',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getVendorDefaultFilter = apiWrapper(
    (extras?: FetchExtras): Resource<Types.AttributeFilter> =>
      new Resource<Types.AttributeFilter>(
        'get',
        'default-filters/vendor',
        {},
        {},
        undefined,
        extras
      )
  );
  export function saveMultipleUserDefaultFilters(
    requestBody: ReadonlyArray<Types.UserFilters>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.UserFilters>> {
    return apiRequestWrapper('put', 'default-filters/users', {}, {}, requestBody, extras, []);
  }
  export function saveUserDefaultFilters(
    requestBody: Types.UserFilters,
    extras?: FetchExtras
  ): Promise<Types.UserFilters> {
    return apiRequestWrapper('put', 'default-filters/user', {}, {}, requestBody, extras);
  }
  export function saveVendorDefaultFilter(
    requestBody: Types.VendorDefaultFilterChangeRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'default-filters/vendor', {}, {}, requestBody, extras);
  }
}

export namespace EDIChannels {
  export function deleteChannel(
    requestBody: Types.EdiChannel,
    extras?: FetchExtras
  ): Promise<Types.EdiChannel> {
    return apiRequestWrapper('delete', 'edi-channels', {}, {}, requestBody, extras);
  }
  export const getAllChannels = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.EdiChannel>> =>
      new Resource<ReadonlyArray<Types.EdiChannel>>(
        'get',
        'edi-channels',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveChannel(
    requestBody: Types.EdiChannel,
    extras?: FetchExtras
  ): Promise<Types.EdiChannel> {
    return apiRequestWrapper('put', 'edi-channels', {}, {}, requestBody, extras);
  }
}

export namespace Email {
  export function deleteEmailAddressInAllowlist(
    requestBody: Types.EmailAllowlistAddress,
    extras?: FetchExtras
  ): Promise<boolean> {
    return apiRequestWrapper(
      'delete',
      'emails/allowlist/email-addresses',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteHostInAllowlist(
    requestBody: Types.EmailAllowlistHost,
    extras?: FetchExtras
  ): Promise<boolean> {
    return apiRequestWrapper('delete', 'emails/allowlist/hosts', {}, {}, requestBody, extras);
  }
  export function doesEmailPassAllowlistRules(
    requestBody: string,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'emails/allowlist/validate', {}, {}, requestBody, extras);
  }
  export const getAllAllowlistForHosts = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.EmailAllowlistHost>> =>
      new Resource<ReadonlyArray<Types.EmailAllowlistHost>>(
        'get',
        'emails/allowlist/hosts',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getEmailAddressInAllowlist = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.EmailAllowlistAddress>> =>
      new Resource<ReadonlyArray<Types.EmailAllowlistAddress>>(
        'get',
        'emails/allowlist/email-addresses',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getPendingEmailAttachments = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.PendingEmailAttachment>> =>
      new Resource<ReadonlyArray<Types.PendingEmailAttachment>>(
        'get',
        'emails/blocked',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function insertEmailAddressToAllowlist(
    requestBody: Types.EmailAllowlistAddress,
    extras?: FetchExtras
  ): Promise<Types.EmailAllowlistAddress> {
    return apiRequestWrapper(
      'post',
      'emails/allowlist/email-addresses',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function insertHostToAllowlist(
    requestBody: Types.EmailAllowlistHost,
    extras?: FetchExtras
  ): Promise<Types.EmailAllowlistHost> {
    return apiRequestWrapper('post', 'emails/allowlist/hosts', {}, {}, requestBody, extras);
  }
  export function retryPendingEmail(
    historyId?: number,
    messageId?: string,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'emails/retry', {}, {historyId, messageId}, undefined, extras);
  }
  export function updateEmailAddressInAllowlist(
    requestBody: Types.UpdateEmailAllowlistAddressRequest,
    extras?: FetchExtras
  ): Promise<Types.EmailAllowlistAddress> {
    return apiRequestWrapper(
      'put',
      'emails/allowlist/email-addresses',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function updateHostInAllowlist(
    requestBody: Types.UpdateEmailAllowlistHostRequest,
    extras?: FetchExtras
  ): Promise<Types.EmailAllowlistHost> {
    return apiRequestWrapper('put', 'emails/allowlist/hosts', {}, {}, requestBody, extras);
  }
}

export namespace Environment {
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getEnvironment(extras?: FetchExtras): Promise<Types.Environment> {
    return apiRequestWrapper('get', 'environment', {}, {}, undefined, extras);
  }
  export const getPublicEnvironment = apiWrapper(
    (extras?: FetchExtras): Resource<Types.Environment> =>
      new Resource<Types.Environment>('get', 'environment/public', {}, {}, undefined, extras)
  );
}

export namespace Epoch {
  export function clearUpdateSchedules(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'epoch/schedules', {}, {}, undefined, extras);
  }
  export const getUpdateSchedule = apiWrapper(
    (extras?: FetchExtras): Resource<Types.Schedule> =>
      new Resource<Types.Schedule>('get', 'epoch/schedules', {}, {}, undefined, extras)
  );
  export function setUpdateSchedules(requestBody: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('put', 'epoch/schedules', {}, {}, requestBody, extras);
  }
}

export namespace Errors {
  export function logError(requestBody: Types.ErrorInfo, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'error', {}, {}, requestBody, extras);
  }
}

export namespace Experiments {
  export function deleteExperiment(experimentId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'experiments/{experimentId}',
      {experimentId},
      {},
      undefined,
      extras
    );
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getExperiments(extras?: FetchExtras): Promise<ReadonlyArray<Types.Experiment>> {
    return apiRequestWrapper('get', 'experiments', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getFavoriteExperimentIds(extras?: FetchExtras): Promise<ReadonlyArray<number>> {
    return apiRequestWrapper('get', 'experiments/favorites', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getVisitedExperimentTimestampsForUser(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.VisitedTimestamp>> {
    return apiRequestWrapper('get', 'experiments/visited-times/me', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getVisitedExperimentTimestampsForVendor(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.VisitedTimestamp>> {
    return apiRequestWrapper('get', 'experiments/visited-times', {}, {}, undefined, extras, []);
  }
  export function removeExperimentFromFavorites(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'experiments/favorites/{id}', {id}, {}, undefined, extras);
  }
  export function saveExperiment(
    requestBody: Types.Experiment,
    extras?: FetchExtras
  ): Promise<Types.Experiment> {
    return apiRequestWrapper('put', 'experiments', {}, {}, requestBody, extras);
  }
  export function setExperimentAsFavorite(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('put', 'experiments/favorites/{id}', {id}, {}, undefined, extras);
  }
  export function setVisitedTime(id: number, extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('put', 'experiments/visited-times/{id}', {id}, {}, undefined, extras);
  }
}

export namespace Export {}

export namespace ExportFormatTemplates {
  export function deleteExportFormatTemplate(
    exportFormatId: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'export-format-templates/{exportFormatId}',
      {exportFormatId},
      {},
      undefined,
      extras
    );
  }
  export const getExportFormatTemplatesForVendor = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ExportFormatTemplate>> =>
      new Resource<ReadonlyArray<Types.ExportFormatTemplate>>(
        'get',
        'export-format-templates',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveExportFormatTemplate(
    requestBody: Types.ExportFormatTemplate,
    extras?: FetchExtras
  ): Promise<Types.ExportFormatTemplate> {
    return apiRequestWrapper('put', 'export-format-templates', {}, {}, requestBody, extras);
  }
}

export namespace ExternalDataWarehouse {
  export function deleteDestination(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'export/data-warehouse/{id}', {id}, {}, undefined, extras);
  }
  export const getAllDestinations = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DataWarehouseDestination>> =>
      new Resource<ReadonlyArray<Types.DataWarehouseDestination>>(
        'get',
        'export/data-warehouse/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getDefaultConfiguration = apiWrapper(
    (
      channel?: Types.DeliveryChannel,
      extras?: FetchExtras
    ): Resource<Types.DataWarehouseDestination> =>
      new Resource<Types.DataWarehouseDestination>(
        'get',
        'export/data-warehouse/config/default',
        {},
        {channel},
        undefined,
        extras
      )
  );
  export const getDestinations = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.DataWarehouseDestination>> =>
      new Resource<ReadonlyArray<Types.DataWarehouseDestination>>(
        'get',
        'export/data-warehouse/destination',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveDestination(
    requestBody: Types.DataWarehouseDestination,
    extras?: FetchExtras
  ): Promise<Types.DataWarehouseDestination> {
    return apiRequestWrapper('put', 'export/data-warehouse', {}, {}, requestBody, extras);
  }
  export function syncBigQuery(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'export/data-warehouse/sync/bigquery',
      {},
      {},
      undefined,
      extras
    );
  }
  export function syncImmediately(
    requestBody: boolean | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'export/data-warehouse/sync', {}, {}, requestBody, extras);
  }
}

export namespace FeatureFlags {
  export function deleteFeatureFlag(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'feature-flags/all-features/{id}',
      {id},
      {},
      undefined,
      extras
    );
  }
  export const getAllFeatures = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Feature>> =>
      new Resource<ReadonlyArray<Types.Feature>>(
        'get',
        'feature-flags/all-features',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getAllVendorFeatures = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.VendorFeatureFlag>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.VendorFeatureFlag>;
      }>('get', 'feature-flags/vendor-features/all', {}, {}, undefined, extras)
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getFeaturesForUsers(extras?: FetchExtras): Promise<{
    [key: string]: ReadonlyArray<string>;
  }> {
    return apiRequestWrapper('get', 'feature-flags/all-users', {}, {}, undefined, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getMyFeatures(extras?: FetchExtras): Promise<ReadonlyArray<string>> {
    return apiRequestWrapper('get', 'feature-flags/me', {}, {}, undefined, extras, []);
  }
  export const getVendorFeatures = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VendorFeatureFlag>> =>
      new Resource<ReadonlyArray<Types.VendorFeatureFlag>>(
        'get',
        'feature-flags/vendor-features',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveFeatureFlag(
    requestBody: Types.Feature,
    extras?: FetchExtras
  ): Promise<Types.Feature> {
    return apiRequestWrapper('put', 'feature-flags/all-features', {}, {}, requestBody, extras);
  }
  export function saveFeatures(
    requestBody: {
      [key: string]: ReadonlyArray<string>;
    },
    extras?: FetchExtras
  ): Promise<{
    [key: string]: ReadonlyArray<string>;
  }> {
    return apiRequestWrapper('put', 'feature-flags', {}, {}, requestBody, extras);
  }
  export function saveVendorFeatures(
    requestBody: {
      [key: string]: Types.VendorFeatureFlag;
    },
    extras?: FetchExtras
  ): Promise<{
    [key: string]: ReadonlyArray<Types.VendorFeatureFlag>;
  }> {
    return apiRequestWrapper(
      'put',
      'feature-flags/vendor-features/all',
      {},
      {},
      requestBody,
      extras
    );
  }
}

export namespace Files {
  export function createRestrictedFileType(
    requestBody: Types.RestrictedFileType,
    extras?: FetchExtras
  ): Promise<Types.RestrictedFileType> {
    return apiRequestWrapper('post', 'files/restricted-filetype', {}, {}, requestBody, extras);
  }
  export function createVendorDataSourceFiletype(
    requestBody: Types.VendorDataSourceFiletype,
    extras?: FetchExtras
  ): Promise<Types.VendorDataSourceFiletype> {
    return apiRequestWrapper(
      'post',
      'files/vendor-data-source-filetype',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteFileType(
    requestBody: Types.FileTypeMetadata,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'files/types/metadata', {}, {}, requestBody, extras);
  }
  export function deletePattern(
    requestBody: Types.ClassificationPattern,
    extras?: FetchExtras
  ): Promise<Types.ClassificationPattern> {
    return apiRequestWrapper('delete', 'files/patterns', {}, {}, requestBody, extras);
  }
  export function deleteRestrictedFileType(
    requestBody: Types.RestrictedFileType,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'files/restricted-filetype', {}, {}, requestBody, extras);
  }
  export function deleteVendorDataSourceFiletype(
    requestBody: Types.VendorDataSourceFiletype,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'files/vendor-data-source-filetype',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deriveCadence(
    requestBody: ReadonlyArray<number>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'files/vendor-data-source-filetype/cadence/derive',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deriveCadenceVendorScoped(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'files/vendor-data-source-filetype/cadence/derive/all',
      {},
      {},
      undefined,
      extras
    );
  }
  export const formatFilename = apiWrapper(
    (fileName?: string, extras?: FetchExtras): Resource<Types.Filename> =>
      new Resource<Types.Filename>(
        'get',
        'files/upload/format-filename',
        {},
        {fileName},
        undefined,
        extras
      )
  );
  export const getAllFileTypes = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.FileTypeMetadata>> =>
      new Resource<ReadonlyArray<Types.FileTypeMetadata>>(
        'get',
        'files/types/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getFileStatuses = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.FileStatus>> =>
      new Resource<ReadonlyArray<Types.FileStatus>>(
        'get',
        'files/{id}/statuses',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getFileTypesForIntegration = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.FileTypeMetadata>> =>
      new Resource<ReadonlyArray<Types.FileTypeMetadata>>(
        'get',
        'files/types/integration/{id}',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getFileVersions = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.FileVersion>> =>
      new Resource<ReadonlyArray<Types.FileVersion>>(
        'get',
        'files/{id}/versions',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getImportedFiles = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.FileMetadata>> =>
      new Resource<ReadonlyArray<Types.FileMetadata>>(
        'get',
        'files/imports',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getLastReceivedFilesForVendor = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.LastReceivedFile>> =>
      new Resource<ReadonlyArray<Types.LastReceivedFile>>(
        'get',
        'files/vendor-data-source-filetype-status',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPatterns(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.ClassificationPattern>> {
    return apiRequestWrapper('get', 'files/patterns', {}, {}, undefined, extras, []);
  }
  export const getRestrictedFileTypes = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.RestrictedFileType>> =>
      new Resource<ReadonlyArray<Types.RestrictedFileType>>(
        'get',
        'files/restricted-filetype',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScreenshots = apiWrapper(
    (
      vendorId?: number,
      runId?: number,
      taskId?: number | null,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<string>> =>
      new Resource<ReadonlyArray<string>>(
        'get',
        'files/screenshots',
        {},
        {vendorId, runId, taskId},
        undefined,
        extras,
        []
      )
  );
  export const getSignedUploadUrl = apiWrapper(
    (fileName?: string, extras?: FetchExtras): Resource<string> =>
      new Resource<string>('get', 'files/upload/url', {}, {fileName}, undefined, extras)
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getUsedFileTypes(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.FileTypeMetadata>> {
    return apiRequestWrapper('get', 'files/types/used', {}, {}, undefined, extras, []);
  }
  export const getVendorDataSourceFiletype = apiWrapper(
    (
      vendorDataSourceFiletypeId: number,
      extras?: FetchExtras
    ): Resource<Types.VendorDataSourceFiletype> =>
      new Resource<Types.VendorDataSourceFiletype>(
        'get',
        'files/vendor-data-source-filetype/{vendorDataSourceFiletypeId}',
        {vendorDataSourceFiletypeId},
        {},
        undefined,
        extras
      )
  );
  export const getVendorDataSourceFiletypes = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VendorDataSourceFiletype>> =>
      new Resource<ReadonlyArray<Types.VendorDataSourceFiletype>>(
        'get',
        'files/vendor-data-source-filetype/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getVendorDataSourceFiletypesForVendor = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VendorDataSourceFiletype>> =>
      new Resource<ReadonlyArray<Types.VendorDataSourceFiletype>>(
        'get',
        'files/vendor-data-source-filetype',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getWidgetFilesIds = apiWrapper(
    (
      requestBody: Types.ViewWidgetFilesRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.FileMetadata>> =>
      new Resource<ReadonlyArray<Types.FileMetadata>>(
        'post',
        'files/widget-files',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const queryAffectedFilesForDataDeletion = apiWrapper(
    (
      requestBody: Types.DataSpecification,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.FileMetadata>> =>
      new Resource<ReadonlyArray<Types.FileMetadata>>(
        'post',
        'files/data/query',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const queryFiles = apiWrapper(
    (
      requestBody: Types.FileQuery,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.FileMetadata>> =>
      new Resource<ReadonlyArray<Types.FileMetadata>>(
        'post',
        'files/query',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const queryFilesMultiVendor = apiWrapper(
    (
      requestBody: Types.FileQuery,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.FileMetadata>> =>
      new Resource<ReadonlyArray<Types.FileMetadata>>(
        'post',
        'files/query/multivendor',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function registerUploadedFile(filename: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'files/register/{filename}',
      {filename},
      {},
      undefined,
      extras
    );
  }
  export function resetLastReceivedFilesForVendor(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'files/vendor-data-source-filetype/latest-received-files/all',
      {},
      {},
      undefined,
      extras
    );
  }
  export function resetLatestReceivedFile(
    requestBody: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'files/vendor-data-source-filetype/reset',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function saveFileType(
    requestBody: Types.FileTypeMetadata,
    extras?: FetchExtras
  ): Promise<Types.FileTypeMetadata> {
    return apiRequestWrapper('put', 'files/types/metadata', {}, {}, requestBody, extras);
  }
  export function savePattern(
    requestBody: Types.ClassificationPattern,
    extras?: FetchExtras
  ): Promise<Types.ClassificationPattern> {
    return apiRequestWrapper('put', 'files/patterns', {}, {}, requestBody, extras);
  }
  export function setVendor(
    requestBody: Types.SetVendorRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'files/set-vendor', {}, {}, requestBody, extras);
  }
  export function setVendorDataSourceId(
    requestBody: Types.SetVendorDataSourceIdRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'files/set-vendor-data-source-id', {}, {}, requestBody, extras);
  }
  export const testClassificationPattern = apiWrapper(
    (pattern?: string, extras?: FetchExtras): Resource<ReadonlyArray<string>> =>
      new Resource<ReadonlyArray<string>>(
        'get',
        'files/patterns/test',
        {},
        {pattern},
        undefined,
        extras,
        []
      )
  );
  export function transition(
    requestBody: Types.FileTransitionRequest,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.FileStatus>> {
    return apiRequestWrapper('post', 'files/transition', {}, {}, requestBody, extras, []);
  }
  export function transitionMultiVendor(
    requestBody: Types.FileTransitionRequest,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.FileStatus>> {
    return apiRequestWrapper(
      'post',
      'files/transition/multivendor',
      {},
      {},
      requestBody,
      extras,
      []
    );
  }
  export function updateRestrictedFileType(
    requestBody: Types.RestrictedFileType,
    extras?: FetchExtras
  ): Promise<Types.RestrictedFileType> {
    return apiRequestWrapper('put', 'files/restricted-filetype', {}, {}, requestBody, extras);
  }
  export function updateVendorDataSourceFiletype(
    requestBody: Types.VendorDataSourceFiletype,
    extras?: FetchExtras
  ): Promise<Types.VendorDataSourceFiletype> {
    return apiRequestWrapper(
      'put',
      'files/vendor-data-source-filetype',
      {},
      {},
      requestBody,
      extras
    );
  }
}

export namespace ForecastConfigurations {
  export function abortInProgressRuns(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'forecast/config/abort-in-progress-runs',
      {},
      {},
      undefined,
      extras
    );
  }
  export const getConfigurations = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ForecastConfig>> =>
      new Resource<ReadonlyArray<Types.ForecastConfig>>(
        'get',
        'forecast/config',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getFilesFromRun = apiWrapper(
    (requestBody: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.ForecastRunFiles>> =>
      new Resource<ReadonlyArray<Types.ForecastRunFiles>>(
        'post',
        'forecast/config/run/files',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getLatestConfiguration = apiWrapper(
    (extras?: FetchExtras): Resource<Types.ForecastConfig> =>
      new Resource<Types.ForecastConfig>('get', 'forecast/config/latest', {}, {}, undefined, extras)
  );
  export const getRunStatusHistory = apiWrapper(
    (runId: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.ForecastRunStatus>> =>
      new Resource<ReadonlyArray<Types.ForecastRunStatus>>(
        'get',
        'forecast/config/run/{runId}/statuses',
        {runId},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getRuns = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ForecastRun>> =>
      new Resource<ReadonlyArray<Types.ForecastRun>>(
        'get',
        'forecast/config/runs',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function runConfigNow(requestBody: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'forecast/config/trigger', {}, {}, requestBody, extras);
  }
  export function saveConfiguration(
    requestBody: Types.ForecastConfig,
    extras?: FetchExtras
  ): Promise<Types.ForecastConfig> {
    return apiRequestWrapper('post', 'forecast/config', {}, {}, requestBody, extras);
  }
  export function triggerForecastRunWithOverrides(
    requestBody: Types.TriggerForecastRunsWithOverridesRequest,
    extras?: FetchExtras
  ): Promise<Types.ForecastRun> {
    return apiRequestWrapper(
      'post',
      'forecast/config/trigger-forecast-run-overrides',
      {},
      {},
      requestBody,
      extras
    );
  }
}

export namespace Groups {
  export function deleteGroup(groupId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'groups/{groupId}', {groupId}, {}, undefined, extras);
  }
  export function deleteVirtualAttribute(attributeId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'groups/attributes/{attributeId}',
      {attributeId},
      {},
      undefined,
      extras
    );
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getGroups(extras?: FetchExtras): Promise<ReadonlyArray<Types.Group>> {
    return apiRequestWrapper('get', 'groups', {}, {}, undefined, extras, []);
  }
  export const getVirtualAttributes = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VirtualAttribute>> =>
      new Resource<ReadonlyArray<Types.VirtualAttribute>>(
        'get',
        'groups/attributes',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function materializeVirtualAttributes(
    dryRun?: boolean | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper('post', 'groups/materialize', {}, {dryRun}, undefined, extras);
  }
  export function saveGroup(requestBody: Types.Group, extras?: FetchExtras): Promise<Types.Group> {
    return apiRequestWrapper('put', 'groups', {}, {}, requestBody, extras);
  }
  export function saveVirtualAttribute(
    requestBody: Types.VirtualAttribute,
    extras?: FetchExtras
  ): Promise<Types.VirtualAttribute> {
    return apiRequestWrapper('put', 'groups/attributes', {}, {}, requestBody, extras);
  }
}

export namespace Import {
  export function computeLocationTypeMetricMapping(
    requestBody: ReadonlyArray<string>,
    vendorId?: number | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'import/compute-location-type-metric-mapping',
      {},
      {vendorId},
      requestBody,
      extras
    );
  }
}

export namespace Jobs {
  export function abortActiveJobs(
    requestBody: Types.JobQuery,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'jobs/active', {}, {}, requestBody, extras);
  }
  export function abortJob(id: string, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'jobs/{id}', {id}, {}, undefined, extras);
  }
  export const getJob = apiWrapper(
    (id: string, extras?: FetchExtras): Resource<Types.Job> =>
      new Resource<Types.Job>('get', 'jobs/{id}', {id}, {}, undefined, extras)
  );
  export const getJobLocks = apiWrapper(
    (limit?: number | null, extras?: FetchExtras): Resource<ReadonlyArray<Types.JobLock>> =>
      new Resource<ReadonlyArray<Types.JobLock>>(
        'get',
        'jobs/locks',
        {},
        {limit},
        undefined,
        extras,
        []
      )
  );
  export const getJobStatuses = apiWrapper(
    (id: string, extras?: FetchExtras): Resource<ReadonlyArray<Types.JobStatus>> =>
      new Resource<ReadonlyArray<Types.JobStatus>>(
        'get',
        'jobs/{id}/statuses',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export function migratePartitioningClustering(
    tableType?: Types.BqTableType,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'jobs/migrate-partitioning-clustering',
      {},
      {tableType},
      undefined,
      extras
    );
  }
  export const queryJobs = apiWrapper(
    (requestBody: Types.JobQuery, extras?: FetchExtras): Resource<ReadonlyArray<Types.Job>> =>
      new Resource<ReadonlyArray<Types.Job>>('post', 'jobs/query', {}, {}, requestBody, extras, [])
  );
  export function removeJobLock(requestBody: Types.JobLock, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'jobs/locks', {}, {}, requestBody, extras);
  }
  export function retryJob(id: string, extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'jobs/{id}/retry', {id}, {}, undefined, extras);
  }
  export function setJobLock(
    requestBody: Types.JobLock,
    extras?: FetchExtras
  ): Promise<Types.JobLock> {
    return apiRequestWrapper('post', 'jobs/locks', {}, {}, requestBody, extras);
  }
  export function setPriority(
    id: string,
    priority?: Types.JobPriority,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'jobs/{id}', {id}, {priority}, undefined, extras);
  }
  export function setSlackRecipient(
    id: string,
    slackRecipient?: string,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'jobs/set-slack-recipient/{id}',
      {id},
      {slackRecipient},
      undefined,
      extras
    );
  }
  export function submit(
    requestBody: any,
    jobType: string,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'jobs/submit/{jobType}',
      {jobType},
      {slackRecipient},
      requestBody,
      extras
    );
  }
  export function updateJobLock(
    requestBody: Types.JobLock,
    extras?: FetchExtras
  ): Promise<Types.JobLock> {
    return apiRequestWrapper('put', 'jobs/locks', {}, {}, requestBody, extras);
  }
}

export namespace LocationGraph {
  export function clearGraphCache(vendorId?: number | null, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'graph/clear', {}, {vendorId}, undefined, extras);
  }
  export const getEdges = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.GraphEdge>> =>
      new Resource<ReadonlyArray<Types.GraphEdge>>(
        'get',
        'graph/edges/{id}',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getShippingLaneRules = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ShippingLaneRule>> =>
      new Resource<ReadonlyArray<Types.ShippingLaneRule>>(
        'get',
        'graph/rules',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function populateEdges(partnerId?: number | null, extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'graph/edges/populate', {}, {partnerId}, undefined, extras);
  }
  export function saveShippingLaneRules(
    requestBody: ReadonlyArray<Types.ShippingLaneRule>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'graph/rules', {}, {}, requestBody, extras);
  }
}

export namespace MetricSnapshots {
  export function deleteMetricSnapshot(metricId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'metric-snapshots/{metricId}',
      {metricId},
      {},
      undefined,
      extras
    );
  }
  export function exportMetricSnapshotsToBigQuery(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'metric-snapshots/export', {}, {}, undefined, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getMetadata(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.MetricSnapshotMetadata>> {
    return apiRequestWrapper('get', 'metric-snapshots', {}, {}, undefined, extras, []);
  }
  export const getMetricSnapshot = apiWrapper(
    (metricId: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.MetricSnapshot>> =>
      new Resource<ReadonlyArray<Types.MetricSnapshot>>(
        'get',
        'metric-snapshots/{metricId}',
        {metricId},
        {},
        undefined,
        extras,
        []
      )
  );
  export function materializeMetricSnapshotsToBigQuery(
    requestBody: Types.MetricSnapshotMaterializationRequest,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper('post', 'metric-snapshots/materialize', {}, {}, requestBody, extras);
  }
  export function uploadSnapshotsForDemoVendor(
    requestBody: ReadonlyArray<Types.MetricSnapshot>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'metric-snapshots/upload/demo', {}, {}, requestBody, extras);
  }
}

export namespace Metrics {
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getAnalysisSettings(extras?: FetchExtras): Promise<Types.VendorAnalysisSettings> {
    return apiRequestWrapper('get', 'metrics/settings', {}, {}, undefined, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getAvailableForecastsByForecastedMetricName(extras?: FetchExtras): Promise<{
    [key: string]: ReadonlyArray<Types.ForecastType>;
  }> {
    return apiRequestWrapper('get', 'metrics/forecasts', {}, {}, undefined, extras);
  }
  export const getAvailableMetrics = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Metric>> =>
      new Resource<ReadonlyArray<Types.Metric>>(
        'get',
        'metrics/available',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getDescriptions(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.MetricDescription>> {
    return apiRequestWrapper('get', 'metrics/descriptions', {}, {}, undefined, extras, []);
  }
  export const getMetricDependencyGraph = apiWrapper(
    (
      requestBody: Types.MetricInstance,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.MetricDependencyGraphEntry>> =>
      new Resource<ReadonlyArray<Types.MetricDependencyGraphEntry>>(
        'post',
        'metrics/dependencies',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getMetrics(extras?: FetchExtras): Promise<Types.MetricResponse> {
    return apiRequestWrapper('get', 'metrics/all', {}, {}, undefined, extras);
  }
  export function saveDescription(
    requestBody: Types.MetricDescription,
    extras?: FetchExtras
  ): Promise<Types.MetricDescription> {
    return apiRequestWrapper('put', 'metrics/descriptions', {}, {}, requestBody, extras);
  }
  export function setVendorAnalysisSettings(
    requestBody: Types.VendorAnalysisSettings,
    extras?: FetchExtras
  ): Promise<Types.VendorAnalysisSettings> {
    return apiRequestWrapper('put', 'metrics/settings', {}, {}, requestBody, extras);
  }
}

export namespace NewProductIntroductions {
  export function deleteNewProductIntroduction(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'forecast/new-products/{id}', {id}, {}, undefined, extras);
  }
  export function deleteNewProductIntroductions(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'forecast/new-products/all', {}, {}, undefined, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getNewProductIntroductions(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.NewProductIntroductionConfig>> {
    return apiRequestWrapper('get', 'forecast/new-products', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getNewProductSettings(extras?: FetchExtras): Promise<Types.NewProductSettings> {
    return apiRequestWrapper('get', 'forecast/new-products/settings', {}, {}, undefined, extras);
  }
  export function saveNewProductIntroduction(
    requestBody: Types.NewProductIntroductionConfig,
    extras?: FetchExtras
  ): Promise<Types.NewProductIntroductionConfig> {
    return apiRequestWrapper('put', 'forecast/new-products', {}, {}, requestBody, extras);
  }
  export function saveNewProductSettings(
    requestBody: Types.NewProductSettings,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'forecast/new-products/settings', {}, {}, requestBody, extras);
  }
  export function scheduleNewProductIntroductionForMaterialization(
    requestBody: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'forecast/new-products/materialize',
      {},
      {},
      requestBody,
      extras
    );
  }
}

export namespace Notifications {
  export const getAuthoredNotifications = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Notification>> =>
      new Resource<ReadonlyArray<Types.Notification>>(
        'get',
        'notifications/authored/me',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getNotification(id: number, extras?: FetchExtras): Promise<Types.Notification> {
    return apiRequestWrapper('get', 'notifications/{id}', {id}, {}, undefined, extras);
  }
  export function saveNotification(
    requestBody: Types.Notification,
    extras?: FetchExtras
  ): Promise<Types.Notification> {
    return apiRequestWrapper('post', 'notifications', {}, {}, requestBody, extras);
  }
}

export namespace OrderSchedules {
  export function deleteAllOrderSchedules(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'forecast/order-schedules/all', {}, {}, undefined, extras);
  }
  export function deleteOrderSchedule(scheduleId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'forecast/order-schedules/{scheduleId}',
      {scheduleId},
      {},
      undefined,
      extras
    );
  }
  export const getOrderSchedule = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.OrderSchedule>> =>
      new Resource<ReadonlyArray<Types.OrderSchedule>>(
        'get',
        'forecast/order-schedules',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveOrderSchedule(
    requestBody: Types.OrderSchedule,
    extras?: FetchExtras
  ): Promise<Types.OrderSchedule> {
    return apiRequestWrapper('put', 'forecast/order-schedules', {}, {}, requestBody, extras);
  }
}

export namespace Partners {
  export function addDoubleCountingPartners(
    requestBody: Types.DoubleCountingPartner,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.DoubleCountingPartner>> {
    return apiRequestWrapper('post', 'partners/double-counting', {}, {}, requestBody, extras, []);
  }
  export const getAllPartners = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Partner>> =>
      new Resource<ReadonlyArray<Types.Partner>>(
        'get',
        'partners/all',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getAllPartnersWithData(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.Partner>> {
    return apiRequestWrapper('get', 'partners', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getDoubleCountingPartners(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.DoubleCountingPartner>> {
    return apiRequestWrapper('get', 'partners/double-counting', {}, {}, undefined, extras, []);
  }
  export function removeDoubleCountingPartners(
    requestBody: Types.DoubleCountingPartner,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.DoubleCountingPartner>> {
    return apiRequestWrapper('delete', 'partners/double-counting', {}, {}, requestBody, extras, []);
  }
  export function savePartner(
    requestBody: Types.Partner,
    extras?: FetchExtras
  ): Promise<Types.Partner> {
    return apiRequestWrapper('put', 'partners', {}, {}, requestBody, extras);
  }
}

export namespace Plans {
  export function applyPlanSettingsToActivePlans(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('put', 'plans/settings/apply', {}, {}, undefined, extras);
  }
  export function clear(planId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'plans/plan/{planId}/clear', {planId}, {}, undefined, extras);
  }
  export function clearMaterialization(planId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'plans/plan/{planId}/clear-materialization',
      {planId},
      {},
      undefined,
      extras
    );
  }
  export function clearPlanningTreeCache(planId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'plans/plan/{planId}/clear-cached-tree',
      {planId},
      {},
      undefined,
      extras
    );
  }
  export function computeConnectedPlanningPaths(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'plans/compute-connected-paths', {}, {}, undefined, extras);
  }
  export function deleteAllInapplicablePlanData(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'plans/inapplicable-data/delete-all',
      {},
      {},
      undefined,
      extras
    );
  }
  export function deletePlan(planId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'plans/plan/{planId}', {planId}, {}, undefined, extras);
  }
  export const getActivePlanningTree = apiWrapper(
    (planId: number, extras?: FetchExtras): Resource<Types.AttributeHierarchyTree> =>
      new Resource<Types.AttributeHierarchyTree>(
        'get',
        'plans/plan/{planId}/tree',
        {planId},
        {},
        undefined,
        extras
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getActivePlans(extras?: FetchExtras): Promise<ReadonlyArray<Types.PlanVersion>> {
    return apiRequestWrapper('get', 'plans', {}, {}, undefined, extras, []);
  }
  export const getAllPlanningTree = apiWrapper(
    (planId: number, extras?: FetchExtras): Resource<Types.AttributeHierarchyTree> =>
      new Resource<Types.AttributeHierarchyTree>(
        'get',
        'plans/plan/{planId}/tree/all',
        {planId},
        {},
        undefined,
        extras
      )
  );
  export const getDirectImportPrimaryPaths = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<ReadonlyArray<Types.ValueKey>>> =>
      new Resource<ReadonlyArray<ReadonlyArray<Types.ValueKey>>>(
        'get',
        'plans/direct-import-primary-paths',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getDiscontinuedSkus(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.DiscontinuedSku>> {
    return apiRequestWrapper('get', 'plans/discontinue-sku', {}, {}, undefined, extras, []);
  }
  export const getForecastTypesInUseByPlanning = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ForecastType>> =>
      new Resource<ReadonlyArray<Types.ForecastType>>(
        'get',
        'plans/forecast-types/in-use',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getInapplicablePlanData = apiWrapper(
    (extras?: FetchExtras): Resource<Types.PlanVersion> =>
      new Resource<Types.PlanVersion>('get', 'plans/inapplicable-data', {}, {}, undefined, extras)
  );
  export const getLatestPlanChanges = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.PlanChange>> =>
      new Resource<ReadonlyArray<Types.PlanChange>>(
        'get',
        'plans/plan-changes/latest',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getNextPlanDate = apiWrapper(
    (requestBody: Types.PlanSettings, extras?: FetchExtras): Resource<string> =>
      new Resource<string>('post', 'plans', {}, {}, requestBody, extras)
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPlan(planId: number, extras?: FetchExtras): Promise<Types.PlanVersion> {
    return apiRequestWrapper('get', 'plans/plan/{planId}', {planId}, {}, undefined, extras);
  }
  export const getPlanChanges = apiWrapper(
    (planId: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.PlanChange>> =>
      new Resource<ReadonlyArray<Types.PlanChange>>(
        'get',
        'plans/plan-changes/{planId}',
        {planId},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPlanDemandSources(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.PlanDemandSourceMapping>> {
    return apiRequestWrapper(
      'get',
      'plans/plan-demand-source/latest',
      {},
      {},
      undefined,
      extras,
      []
    );
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPlanDisabledForecastTypes(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.ForecastType>> {
    return apiRequestWrapper('get', 'plans/forecast-types/disabled', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPlanSettings(extras?: FetchExtras): Promise<Types.PlanSettings> {
    return apiRequestWrapper('get', 'plans/settings', {}, {}, undefined, extras);
  }
  export const getPlanUpdateStatus = apiWrapper(
    (planId: number, extras?: FetchExtras): Resource<Types.PlanUpdateStatus> =>
      new Resource<Types.PlanUpdateStatus>(
        'get',
        'plans/plan/{planId}/status',
        {planId},
        {},
        undefined,
        extras
      )
  );
  export const getPlanningInputMetrics = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.Metric>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.Metric>;
      }>('get', 'plans/planning-input-metrics', {}, {}, undefined, extras)
  );
  export const getPurchaseRecommendations = apiWrapper(
    (
      requestBody: Types.PurchaseRecommendationsRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.PurchaseRecommendationsRow>> =>
      new Resource<ReadonlyArray<Types.PurchaseRecommendationsRow>>(
        'post',
        'plans/purchase-recommendations',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getReport = apiWrapper(
    (
      requestBody: Types.ReportsRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.PlanReportRow>> =>
      new Resource<ReadonlyArray<Types.PlanReportRow>>(
        'post',
        'plans/report',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getShipmentPlanDetails = apiWrapper(
    (
      requestBody: Types.ReportsRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.PlanReportRow>> =>
      new Resource<ReadonlyArray<Types.PlanReportRow>>(
        'post',
        'plans/shipment-plan-details',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function materialize(
    planId: number,
    path?: string,
    extras?: FetchExtras
  ): Promise<Types.PlanChange> {
    return apiRequestWrapper(
      'post',
      'plans/plan/{planId}/rebuild/materialize',
      {planId},
      {path},
      undefined,
      extras
    );
  }
  export function materializeCompletedPlans(
    requestBody: Types.CompletedPlanSpreadsheetMaterializationRequest,
    vendorId?: number,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'plans/materialize/completed',
      {},
      {vendorId, slackRecipient},
      requestBody,
      extras
    );
  }
  export function materializeSync(
    requestBody: Types.MaterializationSyncRequest,
    extras?: FetchExtras
  ): Promise<Types.MaterializationSyncResult> {
    return apiRequestWrapper('post', 'plans/materialize/sync', {}, {}, requestBody, extras);
  }
  export function rebuild(
    planId: number,
    path?: string,
    extras?: FetchExtras
  ): Promise<Types.PlanChange> {
    return apiRequestWrapper(
      'post',
      'plans/plan/{planId}/rebuild',
      {planId},
      {path},
      undefined,
      extras
    );
  }
  export function rebuildAll(planType: Types.PlanType, extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'plans/rebuild/{planType}', {planType}, {}, undefined, extras);
  }
  export function saveDiscontinuedSkus(
    requestBody: ReadonlyArray<Types.DiscontinuedSku>,
    extras?: FetchExtras
  ): Promise<Types.PlanVersion> {
    return apiRequestWrapper('post', 'plans/discontinue-sku', {}, {}, requestBody, extras);
  }
  export function savePlan(
    requestBody: Types.PlanVersion,
    extras?: FetchExtras
  ): Promise<Types.PlanVersion> {
    return apiRequestWrapper('put', 'plans', {}, {}, requestBody, extras);
  }
  export function savePlanSettings(
    requestBody: Types.PlanSettings,
    extras?: FetchExtras
  ): Promise<Types.PlanSettings> {
    return apiRequestWrapper('put', 'plans/settings', {}, {}, requestBody, extras);
  }
  export function setPlanDemandSources(
    requestBody: ReadonlyArray<Types.PlanDemandSourceMapping>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'plans/plan-demand-source/latest', {}, {}, requestBody, extras);
  }
  export function setPlanDisabledForecastTypes(
    requestBody: ReadonlyArray<Types.ForecastType>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'plans/forecast-types/disabled', {}, {}, requestBody, extras);
  }
  export function syncDemandPlan(
    planId: number,
    path?: string,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'plans/plan/{planId}/sync-demand-plan',
      {planId},
      {path},
      undefined,
      extras
    );
  }
  export function updateDependencyMetrics(
    planId: number,
    path?: string,
    extras?: FetchExtras
  ): Promise<Types.PlanChange> {
    return apiRequestWrapper(
      'post',
      'plans/plan/{planId}/update-dependency-metrics',
      {planId},
      {path},
      undefined,
      extras
    );
  }
  export function updatePath(
    requestBody: Types.PlanPathChangeRequest,
    planType: Types.PlanType,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'put',
      'plans/update-path/{planType}',
      {planType},
      {},
      requestBody,
      extras
    );
  }
  export function validateMaterialization(
    requestBody: Types.ValidatePlanMaterializationRequest,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper('post', 'plans/validate-materialization', {}, {}, requestBody, extras);
  }
}

export namespace Policies {
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPolicies(extras?: FetchExtras): Promise<ReadonlyArray<Types.UserFilters>> {
    return apiRequestWrapper('get', 'policies', {}, {}, undefined, extras, []);
  }
  export function savePolicies(
    requestBody: ReadonlyArray<Types.UserFilters>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.UserFilters>> {
    return apiRequestWrapper('put', 'policies/multiple', {}, {}, requestBody, extras, []);
  }
  export function savePolicy(
    requestBody: Types.UserFilters,
    extras?: FetchExtras
  ): Promise<Types.UserFilters> {
    return apiRequestWrapper('put', 'policies', {}, {}, requestBody, extras);
  }
}

export namespace Scrapers {
  export function abortRun(runId: number, extras?: FetchExtras): Promise<Types.ScraperStatus> {
    return apiRequestWrapper('post', 'feeds/abort/{runId}', {runId}, {}, undefined, extras);
  }
  export function archiveFeed(feedId: number, extras?: FetchExtras): Promise<Types.FeedAndRuns> {
    return apiRequestWrapper('put', 'feeds/archive/{feedId}', {feedId}, {}, undefined, extras);
  }
  export function deleteFeed(feedId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'feeds/{feedId}', {feedId}, {}, undefined, extras);
  }
  export const getScheduledFeeds = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ScheduledFeed>> =>
      new Resource<ReadonlyArray<Types.ScheduledFeed>>(
        'get',
        'feeds/scheduled',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScheduledFeedsForScraperInstance = apiWrapper(
    (
      scraperInstanceId: number,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ScheduledFeed>> =>
      new Resource<ReadonlyArray<Types.ScheduledFeed>>(
        'get',
        'feeds/scraper-instance/{scraperInstanceId}/feeds',
        {scraperInstanceId},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScheduledFeedsStatuses = apiWrapper(
    (
      feedIds?: ReadonlyArray<number>,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: Types.ScraperStatusEnum;
    }> =>
      new Resource<{
        [key: string]: Types.ScraperStatusEnum;
      }>('get', 'feeds/scheduled/statuses', {}, {feedIds}, undefined, extras)
  );
  export const getScraperInstances = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ScraperInstance>> =>
      new Resource<ReadonlyArray<Types.ScraperInstance>>(
        'get',
        'feeds/scraper-instances',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScraperRun = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<Types.ScraperRun> =>
      new Resource<Types.ScraperRun>('get', 'feeds/runs/{id}', {id}, {}, undefined, extras)
  );
  export const getScraperRunLatestStatus = apiWrapper(
    (runId: number, extras?: FetchExtras): Resource<Types.ScraperStatus> =>
      new Resource<Types.ScraperStatus>(
        'get',
        'feeds/runs/{runId}/latest-status',
        {runId},
        {},
        undefined,
        extras
      )
  );
  export const getScraperRunStatusHistory = apiWrapper(
    (runId: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.ScraperStatus>> =>
      new Resource<ReadonlyArray<Types.ScraperStatus>>(
        'get',
        'feeds/runs/{runId}/statuses',
        {runId},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScraperRuns = apiWrapper(
    (
      feedId?: number,
      limit?: number | null,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ScraperRun>> =>
      new Resource<ReadonlyArray<Types.ScraperRun>>(
        'get',
        'feeds/runs',
        {},
        {feedId, limit},
        undefined,
        extras,
        []
      )
  );
  export const getScraperRunsFromIds = apiWrapper(
    (
      requestBody: ReadonlyArray<number>,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.ScraperRun>> =>
      new Resource<ReadonlyArray<Types.ScraperRun>>(
        'post',
        'feeds/runs/multiple',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getScraperTasks = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<ReadonlyArray<Types.ScraperTask>> =>
      new Resource<ReadonlyArray<Types.ScraperTask>>(
        'get',
        'feeds/runs/{id}/tasks',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getScrapers = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Scraper>> =>
      new Resource<ReadonlyArray<Types.Scraper>>(
        'get',
        'feeds/scrapers',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveFeed(
    requestBody: Types.ScheduledFeed,
    extras?: FetchExtras
  ): Promise<Types.ScheduledFeed> {
    return apiRequestWrapper('put', 'feeds', {}, {}, requestBody, extras);
  }
  export function saveScraper(
    requestBody: Types.Scraper,
    extras?: FetchExtras
  ): Promise<Types.Scraper> {
    return apiRequestWrapper('put', 'feeds/scrapers', {}, {}, requestBody, extras);
  }
  export function saveScraperInstance(
    requestBody: Types.ScraperInstance,
    extras?: FetchExtras
  ): Promise<Types.ScraperInstance> {
    return apiRequestWrapper('put', 'feeds/scraper-instances', {}, {}, requestBody, extras);
  }
  export function scheduleCredentialValidationScraperRun(
    credentialGroupId?: number,
    credentialSetName?: string | null,
    extras?: FetchExtras
  ): Promise<number> {
    return apiRequestWrapper(
      'post',
      'feeds/runs/credential-validation',
      {},
      {credentialGroupId, credentialSetName},
      undefined,
      extras
    );
  }
  export function scheduleRun(
    requestBody: Types.ScraperRun,
    extras?: FetchExtras
  ): Promise<Types.ScraperRun> {
    return apiRequestWrapper('post', 'feeds/runs', {}, {}, requestBody, extras);
  }
  export function unarchiveFeed(feedId: number, extras?: FetchExtras): Promise<Types.FeedAndRuns> {
    return apiRequestWrapper('put', 'feeds/unarchive/{feedId}', {feedId}, {}, undefined, extras);
  }
  export function updateRun(
    requestBody: Types.ScraperRun,
    runId: number,
    extras?: FetchExtras
  ): Promise<Types.ScraperRun> {
    return apiRequestWrapper('put', 'feeds/runs/{runId}', {runId}, {}, requestBody, extras);
  }
}

export namespace Subscriptions {
  export function deletExternalStorageSubscription(
    requestBody: Types.ExternalStorageWidgetSubscription,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'widget-subscriptions/external-storage',
      {},
      {},
      requestBody,
      extras
    );
  }
  export const getEmailWidgetSubscriptions = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.EmailWidgetSubscription>> =>
      new Resource<ReadonlyArray<Types.EmailWidgetSubscription>>(
        'get',
        'widget-subscriptions/email',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getExternalStorageSubscription = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<Types.ExternalStorageWidgetSubscription> =>
      new Resource<Types.ExternalStorageWidgetSubscription>(
        'get',
        'widget-subscriptions/external-storage/{id}',
        {id},
        {},
        undefined,
        extras
      )
  );
  export const getExternalStorageSubscriptions = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.ExternalStorageWidgetSubscription>> =>
      new Resource<ReadonlyArray<Types.ExternalStorageWidgetSubscription>>(
        'get',
        'widget-subscriptions/bulk-exports',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getLatestScheduledTime = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<string> =>
      new Resource<string>(
        'get',
        'widget-subscriptions/external-storage/{id}/latest-scheduled-time',
        {id},
        {},
        undefined,
        extras
      )
  );
  export const getSftpSubscriptionNames = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<string>> =>
      new Resource<ReadonlyArray<string>>(
        'get',
        'widget-subscriptions/export-names',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getSubscriptionNamesBySubscriptionIdByExportFormatId = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: {
        [key: string]: string;
      };
    }> =>
      new Resource<{
        [key: string]: {
          [key: string]: string;
        };
      }>('get', 'widget-subscriptions/export-formats', {}, {}, undefined, extras)
  );
  export const getSubscriptionRuns = apiWrapper(
    (
      subscriptionId: number,
      after?: string,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.SubscriptionRun>> =>
      new Resource<ReadonlyArray<Types.SubscriptionRun>>(
        'get',
        'widget-subscriptions/runs/{subscriptionId}',
        {subscriptionId},
        {after},
        undefined,
        extras,
        []
      )
  );
  export const getSubscriptionStatusSummaries = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.SubscriptionStatusSummary>> =>
      new Resource<ReadonlyArray<Types.SubscriptionStatusSummary>>(
        'get',
        'widget-subscriptions/status-summaries',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getWidgetSubscriptions = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.WidgetSubscription>> =>
      new Resource<ReadonlyArray<Types.WidgetSubscription>>(
        'get',
        'widget-subscriptions',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function reschedule(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'widget-subscriptions/reschedule', {}, {}, undefined, extras);
  }
  export function saveBulkExport(
    requestBody: Types.ExternalStorageWidgetSubscription,
    extras?: FetchExtras
  ): Promise<Types.ExternalStorageWidgetSubscription> {
    return apiRequestWrapper(
      'put',
      'widget-subscriptions/external-storage',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function saveSubscriptionTags(
    subscriptionId: number,
    updatedTags?: ReadonlyArray<string>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'put',
      'widget-subscriptions/tags/{subscriptionId}',
      {subscriptionId},
      {updatedTags},
      undefined,
      extras
    );
  }
  export function send(
    requestBody: Types.SendSubscriptionsRequest,
    subscriptionId: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'widget-subscriptions/send/{subscriptionId}',
      {subscriptionId},
      {},
      requestBody,
      extras
    );
  }
  export function submitSend(
    requestBody: Types.SendSubscriptionsRequest,
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'widget-subscriptions/send',
      {},
      {slackRecipient},
      requestBody,
      extras
    );
  }
  export function testConnection(
    requestBody: Types.TestConnectionRequest,
    extras?: FetchExtras
  ): Promise<Types.ConnectionTestResult> {
    return apiRequestWrapper(
      'post',
      'widget-subscriptions/test-connection',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function updateEmailWidgetSubscriptions(
    requestBody: Types.EmailSubscriptionUpdate,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.EmailWidgetSubscription>> {
    return apiRequestWrapper('post', 'widget-subscriptions', {}, {}, requestBody, extras, []);
  }
}

export namespace Tags {
  export function deleteTag(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'tags/{id}', {id}, {}, undefined, extras);
  }
  export const getPublicTags = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Tag>> =>
      new Resource<ReadonlyArray<Types.Tag>>('get', 'tags/public', {}, {}, undefined, extras, [])
  );
  export const getSubscriptionTags = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Tag>> =>
      new Resource<ReadonlyArray<Types.Tag>>(
        'get',
        'tags/subscription',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getTags = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Tag>> =>
      new Resource<ReadonlyArray<Types.Tag>>('get', 'tags', {}, {}, undefined, extras, [])
  );
  export const getViewTags = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Tag>> =>
      new Resource<ReadonlyArray<Types.Tag>>('get', 'tags/view', {}, {}, undefined, extras, [])
  );
  export function saveTag(requestBody: Types.Tag, extras?: FetchExtras): Promise<Types.Tag> {
    return apiRequestWrapper('put', 'tags', {}, {}, requestBody, extras);
  }
}

export namespace Tracking {
  export function trackInsight(insightId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'tr-cking/insight/{insightId}',
      {insightId},
      {},
      undefined,
      extras
    );
  }
  export function trackView(
    requestBody: Types.ViewTrackingEvent,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'tr-cking/view', {}, {}, requestBody, extras);
  }
}

export namespace TransactionEventData {
  export function checkBigQueryForDuplicateTransactions(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('post', 'transactions/bigquery-duplicates', {}, {}, undefined, extras);
  }
  export function createPendingAugmentations(
    requestBody: Types.PendingTransactionAugmentationRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'transactions/pending-augmentation',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteAndReAugmentIngestionTransactionLineItems(
    requestBody: Types.DeleteTransactionRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'transactions/delete-and-reaugment',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteAndReAugmentIngestionTransactionLineItemsByFile(
    requestBody: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'transactions/delete-and-reaugment-file',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function deleteBigQueryForDuplicateTransactions(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'transactions/bigquery-duplicates',
      {},
      {},
      undefined,
      extras
    );
  }
  export function deleteTransactionDerivationConfiguration(
    id: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'transactions/derivation/configuration/{id}',
      {id},
      {},
      undefined,
      extras
    );
  }
  export function deleteVendorTransactionMatchingIdentifiers(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'delete',
      'transactions/matching-identifiers',
      {},
      {},
      undefined,
      extras
    );
  }
  export const getAvailablePartnerEdges = apiWrapper(
    (
      vendorId?: number | null,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<number>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<number>;
      }>('get', 'transactions/partner-edge/available', {}, {vendorId}, undefined, extras)
  );
  export const getPartnerEdgeInfo = apiWrapper(
    (
      originPartnerId?: number | null,
      destinationPartnerId?: number | null,
      vendorId?: number | null,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.TransactionPartnerEdge>> =>
      new Resource<ReadonlyArray<Types.TransactionPartnerEdge>>(
        'get',
        'transactions/partner-edge/info',
        {},
        {originPartnerId, destinationPartnerId, vendorId},
        undefined,
        extras,
        []
      )
  );
  export const getTransactionDerivationConfigurations = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.TransactionDerivationConfiguration>> =>
      new Resource<ReadonlyArray<Types.TransactionDerivationConfiguration>>(
        'get',
        'transactions/derivation/configuration',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getTransactionMatchGroupById = apiWrapper(
    (
      id: number,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.IngestionTransactionLineItem>> =>
      new Resource<ReadonlyArray<Types.IngestionTransactionLineItem>>(
        'get',
        'transactions/match-group/{id}',
        {id},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getTransactionMatchGroupByIdentifiers = apiWrapper(
    (
      requestBody: {
        [key: string]: string;
      },
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.IngestionTransactionLineItem>> =>
      new Resource<ReadonlyArray<Types.IngestionTransactionLineItem>>(
        'post',
        'transactions/match-group',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getVendorTransactionMatchingIdentifiers = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Attribute>> =>
      new Resource<ReadonlyArray<Types.Attribute>>(
        'get',
        'transactions/matching-identifiers',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const queryEvents = apiWrapper(
    (
      requestBody: Types.OrdersPreviewRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.DisplayTransactionEvent>> =>
      new Resource<ReadonlyArray<Types.DisplayTransactionEvent>>(
        'post',
        'transactions/events/query',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function refreshPartnerEdges(
    originPartnerId?: number | null,
    destinationPartnerId?: number | null,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.TransactionPartnerEdge>> {
    return apiRequestWrapper(
      'post',
      'transactions/partner-edge/refresh',
      {},
      {originPartnerId, destinationPartnerId},
      undefined,
      extras,
      []
    );
  }
  export function saveTransactionDerivationConfiguration(
    requestBody: Types.TransactionDerivationConfiguration,
    extras?: FetchExtras
  ): Promise<Types.TransactionDerivationConfiguration> {
    return apiRequestWrapper(
      'post',
      'transactions/derivation/configuration',
      {},
      {},
      requestBody,
      extras
    );
  }
  export function setPendingDerivation(
    requestBody: Types.ThinPendingTransactionDerivation,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'transactions/derivation', {}, {}, requestBody, extras);
  }
  export function setVendorTransactionMatchingIdentifiers(
    requestBody: Types.MatchingIdentifiersRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'transactions/matching-identifiers',
      {},
      {},
      requestBody,
      extras
    );
  }
}

export namespace TwilioPhoneNumbers {
  export function deleteNumber(
    requestBody: Types.TwilioPhoneNumber,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'twilio-phone-numbers', {}, {}, requestBody, extras);
  }
  export const getAllNumbers = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.TwilioPhoneNumber>> =>
      new Resource<ReadonlyArray<Types.TwilioPhoneNumber>>(
        'get',
        'twilio-phone-numbers',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function saveNumber(
    requestBody: Types.TwilioPhoneNumber,
    extras?: FetchExtras
  ): Promise<Types.TwilioPhoneNumber> {
    return apiRequestWrapper('put', 'twilio-phone-numbers', {}, {}, requestBody, extras);
  }
}

export namespace Usage {
  export const getAttributeUsage = apiWrapper(
    (
      requestBody: Types.Attribute,
      vendorId?: number,
      extras?: FetchExtras
    ): Resource<Types.UsageSummary> =>
      new Resource<Types.UsageSummary>(
        'post',
        'usage/attribute',
        {},
        {vendorId},
        requestBody,
        extras
      )
  );
  export const getMetricUsage = apiWrapper(
    (
      requestBody: Types.Metric,
      vendorId?: number,
      limit?: number | null,
      extras?: FetchExtras
    ): Resource<Types.UsageSummary> =>
      new Resource<Types.UsageSummary>(
        'post',
        'usage/metric',
        {},
        {vendorId, limit},
        requestBody,
        extras
      )
  );
}

export namespace VendorDataIntegrationsCredentials {
  export function createCredentialRequest(
    requestBody: Types.CreateCredentialRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'credentials/requests', {}, {}, requestBody, extras);
  }
  export function createCredentials(
    requestBody: Types.VendorCredentials,
    extras?: FetchExtras
  ): Promise<Types.VendorCredentials> {
    return apiRequestWrapper('post', 'credentials/create', {}, {}, requestBody, extras);
  }
  export function deleteCredentialGroup(
    requestBody: Types.CredentialsGroup,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'credentials/groups', {}, {}, requestBody, extras);
  }
  export function deleteCredentials(
    requestBody: Types.VendorCredentials,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'credentials', {}, {}, requestBody, extras);
  }
  export function expireAndCompleteCredentialRequest(
    requestBody: Types.CredentialRequest,
    extras?: FetchExtras
  ): Promise<Types.CredentialRequest> {
    return apiRequestWrapper('put', 'credentials/requests/expire', {}, {}, requestBody, extras);
  }
  export const getCredentialGroup = apiWrapper(
    (groupId: number, extras?: FetchExtras): Resource<Types.CredentialsGroup> =>
      new Resource<Types.CredentialsGroup>(
        'get',
        'credentials/groups/{groupId}',
        {groupId},
        {},
        undefined,
        extras
      )
  );
  export const getCredentialGroups = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.CredentialsGroup>> =>
      new Resource<ReadonlyArray<Types.CredentialsGroup>>(
        'get',
        'credentials/groups',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getCredentialRequestForToken = apiWrapper(
    (token?: string, extras?: FetchExtras): Resource<Types.CredentialRequest> =>
      new Resource<Types.CredentialRequest>(
        'get',
        'credentials/credential-request',
        {},
        {token},
        undefined,
        extras
      )
  );
  export const getCredentialRequests = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.CredentialRequest>> =>
      new Resource<ReadonlyArray<Types.CredentialRequest>>(
        'get',
        'credentials/requests',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getCredentials(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.VendorCredentials>> {
    return apiRequestWrapper('get', 'credentials', {}, {}, undefined, extras, []);
  }
  export const getCredentialsByGroupAndSet = apiWrapper(
    (
      credentialGroupId?: number,
      credentialSetName?: string | null,
      extras?: FetchExtras
    ): Resource<Types.CredentialsGroupAndValues> =>
      new Resource<Types.CredentialsGroupAndValues>(
        'get',
        'credentials/auth',
        {},
        {credentialGroupId, credentialSetName},
        undefined,
        extras
      )
  );
  export const getCredentialsForToken = apiWrapper(
    (token?: string, extras?: FetchExtras): Resource<Types.CredentialsGroupAndValues> =>
      new Resource<Types.CredentialsGroupAndValues>(
        'get',
        'credentials/token',
        {},
        {token},
        undefined,
        extras
      )
  );
  export const getCredentialsForTokenWithAuth = apiWrapper(
    (token?: string, extras?: FetchExtras): Resource<Types.CredentialsGroupAndValues> =>
      new Resource<Types.CredentialsGroupAndValues>(
        'get',
        'credentials/token-with-auth',
        {},
        {token},
        undefined,
        extras
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getExternalStorageNonSecureCredentials(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.VendorCredentials>> {
    return apiRequestWrapper(
      'get',
      'credentials/external-storage/non-secure',
      {},
      {},
      undefined,
      extras,
      []
    );
  }
  export const getLatestOpenCredentialRequest = apiWrapper(
    (
      credentialGroupId?: number,
      credentialSetName?: string | null,
      extras?: FetchExtras
    ): Resource<Types.CredentialRequest> =>
      new Resource<Types.CredentialRequest>(
        'get',
        'credentials/open-credential-request/{credentialGroupId}',
        {},
        {credentialGroupId, credentialSetName},
        undefined,
        extras
      )
  );
  export const getLoginCredentials = apiWrapper(
    (
      groupName: string,
      credentialSetName?: string | null,
      extras?: FetchExtras
    ): Resource<Types.VendorCredentials> =>
      new Resource<Types.VendorCredentials>(
        'get',
        'credentials/login-credentials/{groupName}',
        {groupName},
        {credentialSetName},
        undefined,
        extras
      )
  );
  export const getOauthAuthorizationUrl = apiWrapper(
    (credentialGroupId?: number, extras?: FetchExtras): Resource<string> =>
      new Resource<string>(
        'get',
        'credentials/oauth-authorization-url',
        {},
        {credentialGroupId},
        undefined,
        extras
      )
  );
  export const getOauthAuthorizationUrlWithToken = apiWrapper(
    (token?: string, extras?: FetchExtras): Resource<string> =>
      new Resource<string>(
        'get',
        'credentials/oauth-authorization-url-with-token',
        {},
        {token},
        undefined,
        extras
      )
  );
  export const getOpenCredentialRequestsByPartner = apiWrapper(
    (
      requestBody: ReadonlyArray<number>,
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.CredentialRequest>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.CredentialRequest>;
      }>('post', 'credentials/open-requests-by-partner', {}, {}, requestBody, extras)
  );
  export function saveCredentialGroup(
    requestBody: Types.CredentialsGroup,
    extras?: FetchExtras
  ): Promise<Types.CredentialsGroup> {
    return apiRequestWrapper('put', 'credentials/groups', {}, {}, requestBody, extras);
  }
  export function setCredentials(
    requestBody: Types.VendorCredentials,
    extras?: FetchExtras
  ): Promise<Types.VendorCredentials> {
    return apiRequestWrapper('post', 'credentials', {}, {}, requestBody, extras);
  }
  export function setCredentialsWithToken(
    requestBody: Types.VendorCredentials,
    token?: string,
    extras?: FetchExtras
  ): Promise<Types.VendorCredentials> {
    return apiRequestWrapper('post', 'credentials/token', {}, {token}, requestBody, extras);
  }
  export function setCredentialsWithTokenWithAuth(
    requestBody: Types.VendorCredentials,
    token?: string,
    extras?: FetchExtras
  ): Promise<Types.VendorCredentials> {
    return apiRequestWrapper(
      'post',
      'credentials/token-with-auth',
      {},
      {token},
      requestBody,
      extras
    );
  }
}

export namespace VendorDiagnostics {
  export function deleteSettings(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'diagnostics/settings', {}, {}, undefined, extras);
  }
  export const fetchOrGenerateInsights = apiWrapper(
    (
      requestBody: Types.DiagnosticsRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.Insight>> =>
      new Resource<ReadonlyArray<Types.Insight>>(
        'post',
        'diagnostics/insights',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function generateHistoricalDiagnostics(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('post', 'diagnostics/historical', {}, {}, undefined, extras);
  }
  export function generateVendorInsights(
    requestBody: string,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper('post', 'diagnostics/insights/generate', {}, {}, requestBody, extras);
  }
  export const getInsight = apiWrapper(
    (insightId: number, extras?: FetchExtras): Resource<Types.Insight> =>
      new Resource<Types.Insight>(
        'get',
        'diagnostics/insights/{insightId}',
        {insightId},
        {},
        undefined,
        extras
      )
  );
  export const getSettings = apiWrapper(
    (extras?: FetchExtras): Resource<Types.DiagnosticsSettings> =>
      new Resource<Types.DiagnosticsSettings>(
        'get',
        'diagnostics/settings',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getVendorInsights = apiWrapper(
    (
      requestBody: Types.DiagnosticsRequest,
      extras?: FetchExtras
    ): Resource<ReadonlyArray<Types.Insight>> =>
      new Resource<ReadonlyArray<Types.Insight>>(
        'post',
        'diagnostics',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export function saveSettings(
    requestBody: Types.DiagnosticsSettings,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'diagnostics/settings', {}, {}, requestBody, extras);
  }
}

export namespace Vendors {
  export function approveAccountRegistrationRequest(
    googleAccountId: string,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'vendors/registration/account/approve/{googleAccountId}',
      {googleAccountId},
      {},
      undefined,
      extras
    );
  }
  export function approveRegistrationRequest(
    requestBody: Types.VendorRegistrationRequest,
    extras?: FetchExtras
  ): Promise<Types.Vendor> {
    return apiRequestWrapper('post', 'vendors/registration/approve', {}, {}, requestBody, extras);
  }
  export function createVendor(
    requestBody: Types.Vendor,
    extras?: FetchExtras
  ): Promise<Types.Vendor> {
    return apiRequestWrapper('post', 'vendors', {}, {}, requestBody, extras);
  }
  export function createVendorRegistrationRequest(
    requestBody: Types.VendorRegistrationRequest,
    extras?: FetchExtras
  ): Promise<Types.VendorRegistrationRequest> {
    return apiRequestWrapper('put', 'vendors/registration', {}, {}, requestBody, extras);
  }
  export function createVendorRegistrationRequestFromGcp(
    requestBody: Types.VendorRegistrationRequest,
    gcpToken?: string,
    extras?: FetchExtras
  ): Promise<Types.VendorRegistrationRequest> {
    return apiRequestWrapper(
      'put',
      'vendors/registration/gcp',
      {},
      {gcpToken},
      requestBody,
      extras
    );
  }
  export function deleteVendorRegistrationRequest(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'vendors/registration/{id}', {id}, {}, undefined, extras);
  }
  export const getAllVendors = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.Vendor>> =>
      new Resource<ReadonlyArray<Types.Vendor>>('get', 'vendors', {}, {}, undefined, extras, [])
  );
  export const getVendorRegistrationRequests = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VendorRegistrationRequest>> =>
      new Resource<ReadonlyArray<Types.VendorRegistrationRequest>>(
        'get',
        'vendors/registration',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export function rejectAccountRegistrationRequest(
    googleAccountId: string,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'vendors/registration/account/reject/{googleAccountId}',
      {googleAccountId},
      {},
      undefined,
      extras
    );
  }
  export function rejectRegistrationRequest(
    requestBody: Types.VendorRegistrationRequest,
    extras?: FetchExtras
  ): Promise<Types.Vendor> {
    return apiRequestWrapper('post', 'vendors/registration/reject', {}, {}, requestBody, extras);
  }
  export function updateVendor(
    requestBody: Types.Vendor,
    extras?: FetchExtras
  ): Promise<Types.Vendor> {
    return apiRequestWrapper('put', 'vendors', {}, {}, requestBody, extras);
  }
}

export namespace Views {
  export function archiveView(viewId: number, extras?: FetchExtras): Promise<Types.View> {
    return apiRequestWrapper('put', 'views/archive/{viewId}', {viewId}, {}, undefined, extras);
  }
  export function clearVisitedTimestamps(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'views/visited-times', {}, {}, undefined, extras);
  }
  export function copyToVendor(id: number, extras?: FetchExtras): Promise<Types.View> {
    return apiRequestWrapper('post', 'views/public/{id}/copy', {id}, {}, undefined, extras);
  }
  export function deleteView(viewId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'views/{viewId}', {viewId}, {}, undefined, extras);
  }
  export function deleteViewLinks(
    requestBody: ReadonlyArray<Types.ViewLink>,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('delete', 'views/links', {}, {}, requestBody, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getFavoriteThinViews(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.ThinView>> {
    return apiRequestWrapper('get', 'views/favorites/thin', {}, {}, undefined, extras, []);
  }
  export const getLandingPage = apiWrapper(
    (extras?: FetchExtras): Resource<Types.View> =>
      new Resource<Types.View>('get', 'views/landing', {}, {}, undefined, extras)
  );
  export const getLandingPageDefaults = apiWrapper(
    (extras?: FetchExtras): Resource<Types.LandingPageDefaults> =>
      new Resource<Types.LandingPageDefaults>(
        'get',
        'views/landing/defaults',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getLatestViews = apiWrapper(
    (limit?: number | null, extras?: FetchExtras): Resource<ReadonlyArray<Types.View>> =>
      new Resource<ReadonlyArray<Types.View>>(
        'get',
        'views/latest',
        {},
        {limit},
        undefined,
        extras,
        []
      )
  );
  export const getPopularViews = apiWrapper(
    (limit?: number, extras?: FetchExtras): Resource<ReadonlyArray<number>> =>
      new Resource<ReadonlyArray<number>>(
        'get',
        'views/popular',
        {},
        {limit},
        undefined,
        extras,
        []
      )
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getPublicThinViews(extras?: FetchExtras): Promise<ReadonlyArray<Types.ThinView>> {
    return apiRequestWrapper('get', 'views/public/thin', {}, {}, undefined, extras, []);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getRecommendedFilterAttributes(
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.Attribute>> {
    return apiRequestWrapper(
      'get',
      'views/recommended-filters/attributes',
      {},
      {},
      undefined,
      extras,
      []
    );
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getRecommendedFilterTree(
    requestBody: Types.RecommendedFilterTreeRequest,
    extras?: FetchExtras
  ): Promise<Types.AttributeHierarchyTree> {
    return apiRequestWrapper('post', 'views/recommended-filters/tree', {}, {}, requestBody, extras);
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getRecommendedFilterValues(
    requestBody: Types.FilteredValuesQuery,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.ThinAttributeValue>> {
    return apiRequestWrapper(
      'post',
      'views/recommended-filters/values',
      {},
      {},
      requestBody,
      extras,
      []
    );
  }
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getThinViews(extras?: FetchExtras): Promise<ReadonlyArray<Types.ThinView>> {
    return apiRequestWrapper('get', 'views/thin', {}, {}, undefined, extras, []);
  }
  export const getViewAsLegacyTemplate = apiWrapper(
    (requestBody: Types.View, extras?: FetchExtras): Resource<Types.View> =>
      new Resource<Types.View>('post', 'views/to-template', {}, {}, requestBody, extras)
  );
  export const getViewAsVendor = apiWrapper(
    (requestBody: Types.View, vendorId: number, extras?: FetchExtras): Resource<Types.View> =>
      new Resource<Types.View>(
        'post',
        'views/to-vendor/{vendorId}',
        {vendorId},
        {},
        requestBody,
        extras
      )
  );
  export const getViewById = apiWrapper(
    (id: number, extras?: FetchExtras): Resource<Types.View> =>
      new Resource<Types.View>('get', 'views/id/{id}', {id}, {}, undefined, extras)
  );
  export const getViewBySlug = apiWrapper(
    (slug: string, extras?: FetchExtras): Resource<Types.View> =>
      new Resource<Types.View>('get', 'views/slug/{slug}', {slug}, {}, undefined, extras)
  );
  // This endpoint is used in Redux, and thus can't currently be used with useApi
  export function getViewLinks(extras?: FetchExtras): Promise<ReadonlyArray<Types.ViewLink>> {
    return apiRequestWrapper('get', 'views/links', {}, {}, undefined, extras, []);
  }
  export const getViews = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.View>> =>
      new Resource<ReadonlyArray<Types.View>>('get', 'views', {}, {}, undefined, extras, [])
  );
  export const getViewsWithValueKey = apiWrapper(
    (requestBody: Types.ValueKey, extras?: FetchExtras): Resource<ReadonlyArray<string>> =>
      new Resource<ReadonlyArray<string>>(
        'post',
        'views/with-valuekey',
        {},
        {},
        requestBody,
        extras,
        []
      )
  );
  export const getVisitedViewTimestampsForUser = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VisitedTimestamp>> =>
      new Resource<ReadonlyArray<Types.VisitedTimestamp>>(
        'get',
        'views/visited-times/me',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getVisitedViewTimestampsForVendor = apiWrapper(
    (extras?: FetchExtras): Resource<ReadonlyArray<Types.VisitedTimestamp>> =>
      new Resource<ReadonlyArray<Types.VisitedTimestamp>>(
        'get',
        'views/visited-times',
        {},
        {},
        undefined,
        extras,
        []
      )
  );
  export const getWorkflowView = apiWrapper(
    (workflow: Types.Workflow, extras?: FetchExtras): Resource<Types.View> =>
      new Resource<Types.View>(
        'get',
        'views/workflow/{workflow}',
        {workflow},
        {},
        undefined,
        extras
      )
  );
  export const getWorkflowViewIds = apiWrapper(
    (
      extras?: FetchExtras
    ): Resource<{
      [key: string]: ReadonlyArray<Types.Workflow>;
    }> =>
      new Resource<{
        [key: string]: ReadonlyArray<Types.Workflow>;
      }>('get', 'views/workflows', {}, {}, undefined, extras)
  );
  export function removeFromFavorites(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('delete', 'views/favorites/{id}', {id}, {}, undefined, extras);
  }
  export function repropagateTemplates(extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('put', 'views/repropagate-templates', {}, {}, undefined, extras);
  }
  export function saveLandingPageDefaults(
    requestBody: Types.LandingPageDefaults,
    extras?: FetchExtras
  ): Promise<Types.LandingPageDefaults> {
    return apiRequestWrapper('put', 'views/landing/defaults', {}, {}, requestBody, extras);
  }
  export function saveView(requestBody: Types.View, extras?: FetchExtras): Promise<Types.View> {
    return apiRequestWrapper('put', 'views', {}, {}, requestBody, extras);
  }
  export function saveViewLinks(
    requestBody: ReadonlyArray<Types.ViewLink>,
    extras?: FetchExtras
  ): Promise<ReadonlyArray<Types.ViewLink>> {
    return apiRequestWrapper('put', 'views/links', {}, {}, requestBody, extras, []);
  }
  export function setAsFavorite(id: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper('put', 'views/favorites/{id}', {id}, {}, undefined, extras);
  }
  export function setNewFirstLaunchpadDefault(
    requestBody: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('put', 'views/replace-first-default', {}, {}, requestBody, extras);
  }
  export function setViewVisitedTime(id: number, extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper('put', 'views/visited-times/{id}', {id}, {}, undefined, extras);
  }
  export function setWorkflowView(
    requestBody: number,
    workflow: Types.Workflow,
    extras?: FetchExtras
  ): Promise<number> {
    return apiRequestWrapper(
      'put',
      'views/workflow/{workflow}',
      {workflow},
      {},
      requestBody,
      extras
    );
  }
  export function shareScreenshot(
    requestBody: Types.ShareScreenshotRequest,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'views/share/screenshot', {}, {}, requestBody, extras);
  }
  export function shareView(
    requestBody: Types.ShareViewRequest,
    id: number,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'views/share/{id}', {id}, {}, requestBody, extras);
  }
}

export namespace Workflows {
  export function clearWalmartRecommendationsCache(extras?: FetchExtras): Promise<string> {
    return apiRequestWrapper(
      'post',
      'workflows/walmart-recommendations/clear',
      {},
      {},
      undefined,
      extras
    );
  }
  export const getEventAnalysisPage = apiWrapper(
    (eventId: number, extras?: FetchExtras): Resource<Types.EventAnalysisPage> =>
      new Resource<Types.EventAnalysisPage>(
        'get',
        'workflows/event-analysis/{eventId}',
        {eventId},
        {},
        undefined,
        extras
      )
  );
  export const getPriceCompliance = apiWrapper(
    (
      requestBody: Types.StoreLevelAnalysisRequest,
      eventId: number,
      extras?: FetchExtras
    ): Resource<Types.StoreLevelWidgetData> =>
      new Resource<Types.StoreLevelWidgetData>(
        'post',
        'workflows/event-analysis/{eventId}/price-compliance',
        {eventId},
        {},
        requestBody,
        extras
      )
  );
  export const getStoreExecution = apiWrapper(
    (
      requestBody: Types.StoreLevelAnalysisRequest,
      eventId: number,
      extras?: FetchExtras
    ): Resource<Types.StoreLevelWidgetData> =>
      new Resource<Types.StoreLevelWidgetData>(
        'post',
        'workflows/event-analysis/{eventId}/store-execution',
        {eventId},
        {},
        requestBody,
        extras
      )
  );
  export const getWalmartProductCandidates = apiWrapper(
    (
      requestBody: ReadonlyArray<Types.AttributeFilter>,
      extras?: FetchExtras
    ): Resource<Types.WalmartProductCandidatesResult> =>
      new Resource<Types.WalmartProductCandidatesResult>(
        'post',
        'workflows/walmart-product-candidates',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getWalmartProductStats = apiWrapper(
    (
      requestBody: Types.AttributeFilter,
      extras?: FetchExtras
    ): Resource<Types.WalmartProductStatsResult> =>
      new Resource<Types.WalmartProductStatsResult>(
        'post',
        'workflows/walmart-product-stats',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getWalmartRecommendations = apiWrapper(
    (
      requestBody: Types.WalmartRecommendationsRequest,
      extras?: FetchExtras
    ): Resource<Types.WalmartRecommendationsResult> =>
      new Resource<Types.WalmartRecommendationsResult>(
        'post',
        'workflows/walmart-recommendations',
        {},
        {},
        requestBody,
        extras
      )
  );
  export const getWalmartRecommendationsSettings = apiWrapper(
    (extras?: FetchExtras): Resource<Types.WalmartRecommendationsSettings> =>
      new Resource<Types.WalmartRecommendationsSettings>(
        'get',
        'workflows/walmart-recommendations/settings',
        {},
        {},
        undefined,
        extras
      )
  );
  export const getWalmartVendorInventory = apiWrapper(
    (extras?: FetchExtras): Resource<Types.WalmartVendorInventoryResult> =>
      new Resource<Types.WalmartVendorInventoryResult>(
        'post',
        'workflows/walmart-vendor-inventory',
        {},
        {},
        undefined,
        extras
      )
  );
  export function precacheWalmartRecommendations(
    slackRecipient?: string | null,
    extras?: FetchExtras
  ): Promise<string> {
    return apiRequestWrapper(
      'post',
      'workflows/walmart-recommendations/precache',
      {},
      {slackRecipient},
      undefined,
      extras
    );
  }
  export function runEventAnalysis(
    requestBody: Types.LocalInterval,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper('post', 'workflows/event-analysis/run', {}, {}, requestBody, extras);
  }
  export function runEventAnalysisForEvent(eventId: number, extras?: FetchExtras): Promise<void> {
    return apiRequestWrapper(
      'post',
      'workflows/event-analysis/{eventId}',
      {eventId},
      {},
      undefined,
      extras
    );
  }
  export function saveWalmartRecommendationsSettings(
    requestBody: Types.WalmartRecommendationsSettings,
    extras?: FetchExtras
  ): Promise<void> {
    return apiRequestWrapper(
      'post',
      'workflows/walmart-recommendations/settings',
      {},
      {},
      requestBody,
      extras
    );
  }
  export const walmartNovaCheck = apiWrapper(
    (extras?: FetchExtras): Resource<boolean> =>
      new Resource<boolean>('get', 'workflows/walmart-nova-check', {}, {}, undefined, extras)
  );
}
