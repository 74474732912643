import moment from 'moment-timezone';
import pluralize from 'pluralize';
import React from 'react';

import {getAttributeNameForGranularity, getGranularityForAttribute} from 'toolkit/attributes/utils';
import {ComputeResultExtended, ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {getEffectiveMetric, getMetricDisplayType, isComparisonMetric} from 'toolkit/metrics/utils';
import {isForwardOnlyForecast, periodExtendsIntoTheFuture} from 'toolkit/time/utils';
import {WidgetData} from 'toolkit/views/types';
import * as Types from 'types';
import {MetricInstance, MetricValueMetadata} from 'types';
import {assertTruthy} from 'utils/assert';
import {sum} from 'utils/functions';
import {getComputeResult} from 'widgets/widget-data';

type SubtotalPeriod = 'this' | 'next' | 'last';

interface GroupingMatch {
  readonly period: SubtotalPeriod;
  readonly granularity: Types.CalendarUnit;
  readonly offset: number;
}

interface GroupingMatchValue extends GroupingMatch {
  readonly value: number;
  readonly metadata: ReadonlyArray<Types.MetricValueMetadata> | null;
}

interface RowGroupingMatch extends GroupingMatch {
  readonly grouping: ThinComputeResultRowExtended;
}

interface ColGroupingMatch extends GroupingMatch {
  readonly grouping: Types.ThinComputeResultColumn;
}

export const REDUCED_PRECISION_FORMAT_OPTIONS = {
  lengthFormat: TextLengthFormat.COMPACT,
  significantFigures: 3,
};

export function getWidgetInsightTitle(
  config: Types.Widget,
  evaluationDate: moment.Moment,
  widgetData: WidgetData<unknown, unknown> | undefined | null,
  hasData = true,
  customInsightHeader?: (result: ComputeResultExtended) => string | null | React.ReactNode
): string | null | React.ReactNode {
  const computeResult = getComputeResult(widgetData, config);
  if (!computeResult || (!config.headerInsight && !customInsightHeader)) {
    return null;
  }
  if (customInsightHeader) {
    return customInsightHeader(computeResult);
  }

  const evalDate = evaluationDate.format(DATE_FORMAT);

  if (config.headerInsight === Types.WidgetHeaderInsight.INFER) {
    const primaryMetricInstance = computeResult.metrics[0];
    const primaryMetric = getEffectiveMetric(primaryMetricInstance);

    if (primaryMetric.name === 'lost_sales') {
      return getLostSalesInsightText(evalDate, computeResult, hasData);
    }
    if (['expected_sales_units_net', 'sales_units_net', 'sales_net'].includes(primaryMetric.name)) {
      return getSalesInsightText(evalDate, computeResult);
    }
    if (primaryMetric.name === 'remaining_supply') {
      return getInventoryInsightText(config, computeResult, hasData);
    }
    if (primaryMetric.name === 'simulated_on_hand_units') {
      return getSimulatedInventoryInsightText(computeResult, hasData);
    }
    if (primaryMetric.name === 'on_hand_units') {
      return getInventoryVisibilityInsightText(computeResult);
    }
    return null;
  }

  switch (config.headerInsight) {
    case Types.WidgetHeaderInsight.IN_STOCK_PERCENT_WITH_PRESMIN:
      return getInStockPercentageWithPresminInsightText(computeResult, evalDate);
    case Types.WidgetHeaderInsight.INVENTORY:
      return getInventoryInsightText(config, computeResult, hasData);
    case Types.WidgetHeaderInsight.INVENTORY_VISIBILITY:
      return getInventoryVisibilityInsightText(computeResult);
    case Types.WidgetHeaderInsight.LOST_SALES:
      return getLostSalesInsightText(evalDate, computeResult, hasData);
    case Types.WidgetHeaderInsight.SALES:
      return getSalesInsightText(evalDate, computeResult);
    case Types.WidgetHeaderInsight.SIMULATED_INVENTORY:
      return getSimulatedInventoryInsightText(computeResult, hasData);
    case Types.WidgetHeaderInsight.TARGET_LAUNCHPAD_POS_FORECAST:
      return getTargetPosForecastInsightText(computeResult);
    case Types.WidgetHeaderInsight.TARGET_LAUNCHPAD_ORDER_FORECAST:
      return getTargetOrderForecastInsightText(computeResult);
    default:
      return null;
  }
}

function getLostSalesInsightText(
  evalDate: string,
  computeResult: ComputeResultExtended,
  hasData?: boolean
) {
  if (!hasData) {
    return 'No lost sales detected';
  }

  const metricInstance = computeResult.metrics[0];

  // If date groupings exist, take value of last period (one before the current)
  const subtotalMatch = getSubtotalGroupingMatchValue(computeResult, evalDate, 0, ['last']);
  if (subtotalMatch) {
    const displayValue = formatMetricValueForHeader(
      metricInstance,
      subtotalMatch.value,
      subtotalMatch.metadata
    );
    const displayPeriod = formatSubtotalPeriod(subtotalMatch);
    return `${displayValue} lost POS sales ${displayPeriod} due to OOS`;
  }

  // If no date groupings, take value of the whole period
  const lostSalesValue = computeResult.data.columnData.metricValues[0];
  const lostSalesMetadata = computeResult.data.columnData.metricMetadata[0];
  if (!Number.isFinite(lostSalesValue)) {
    return null;
  }

  const displayValue = formatMetricValueForHeader(
    metricInstance,
    lostSalesValue,
    lostSalesMetadata
  );
  const displayPeriod = formatDatePeriodForHeader(metricInstance.arguments.period);

  return `${displayValue} lost POS sales ${displayPeriod} due to OOS`;
}

function getInventoryInsightText(
  config: Types.Widget,
  computeResult: ComputeResultExtended,
  hasData?: boolean
) {
  if (!hasData) {
    return 'No products detected with high weeks of supply';
  }

  const riskDescription = getInventoryRiskDescription(config);
  const groupingNoun = getGroupingNoun(computeResult);
  if (!groupingNoun || !riskDescription) {
    return null;
  }

  const leafNodeCount = computeResult.totalRowCount;

  if (!leafNodeCount) {
    return null;
  }

  return `${Format.shortApproximateValue(leafNodeCount)} ${pluralize(
    groupingNoun,
    leafNodeCount
  )} with ${riskDescription}`;
}

function getInventoryVisibilityInsightText(computeResult: ComputeResultExtended) {
  const dateChildren: ReadonlyArray<ThinComputeResultRowExtended> = computeResult.data.children;
  if (dateChildren.length < 5) {
    return null;
  }
  const prior4WeekAverage =
    dateChildren
      .slice(dateChildren.length - 5, dateChildren.length - 1)
      .map(row => row.columnData.metricValues[0])
      .reduce(sum, 0) / 4;
  const endingInventory = dateChildren[dateChildren.length - 1].columnData.metricValues[0];
  const comparisonText =
    endingInventory > prior4WeekAverage * 1.1
      ? 'are increasing compared'
      : endingInventory < prior4WeekAverage * 0.9
        ? 'are decreasing compared'
        : 'are similar';
  return `Network inventory levels ${comparisonText} to the prior 4 week average`;
}

function getSimulatedInventoryInsightText(computeResult: ComputeResultExtended, hasData?: boolean) {
  if (!hasData) {
    return 'No warehouse stock risks detected';
  }

  const firstProductGroupingIndex = computeResult.rowGroupings.findIndex(
    attr => attr.attribute.type === Types.AttributeType.PRODUCT
  );
  if (firstProductGroupingIndex === -1 || computeResult.rowGroupings.length > 2) {
    return null;
  }

  const productLocationCount =
    computeResult.rowGroupings.length === 1
      ? computeResult.data.children.length
      : computeResult.data.children
          .map(products => products.children.length)
          .reduce((a, b) => a + b, 0);

  if (!productLocationCount) {
    return null;
  }

  const simulatedOnHandMetricInstance = computeResult.metrics[0];
  const period = assertTruthy(simulatedOnHandMetricInstance.arguments.period);
  const displayPeriod = Format.period(
    period.type === 'complex' ? period.endPeriod : period
  ).toLocaleLowerCase();

  // We've got feedback that product-location is an overly technical term so use "product"
  // here since the fact that products exist in locations is considered implicit
  return `${productLocationCount} products with predicted OOS in ${displayPeriod}`;
}

function getInStockPercentageWithPresminInsightText(
  computeResult: ComputeResultExtended,
  evaluationDate: string
) {
  const rowGroupingMatch = getRowGroupingForMatchingTimeUnit(
    computeResult,
    evaluationDate,
    0,
    ['last'],
    1
  );
  if (!rowGroupingMatch) {
    return null;
  }

  const {grouping} = rowGroupingMatch;
  const formattedPresentationMinimm = formatMetricValueForHeader(
    computeResult.metrics[0],
    grouping.columnData.metricValues[0],
    grouping.columnData.metricMetadata[0]
  );
  const formattedInStock = formatMetricValueForHeader(
    computeResult.metrics[1],
    grouping.columnData.metricValues[1],
    grouping.columnData.metricMetadata[1]
  );
  return `${formattedInStock} of item-stores are in-stock, with ${formattedPresentationMinimm} above presentation minimum`;
}

function getTargetPosForecastInsightText(computeResult: ComputeResultExtended) {
  const rowCount = computeResult.totalRowCount;
  if (!rowCount) {
    return null;
  }
  return `Target increased the POS forecast for ${rowCount} products in the last week`;
}

function getTargetOrderForecastInsightText(computeResult: ComputeResultExtended) {
  const rowCount = computeResult.totalRowCount;
  if (!rowCount) {
    return null;
  }
  return `Target increased the order forecast for ${rowCount} products in the last week`;
}

export function getSliceOfTopLevelDateGrouping(
  computeResult: ComputeResultExtended,
  {
    start,
    endInclusive,
    endExclusive,
  }: {start?: moment.Moment; endInclusive?: moment.Moment; endExclusive?: moment.Moment}
): ReadonlyArray<ThinComputeResultRowExtended> {
  if (computeResult.rowGroupings[0].attribute.type !== Types.AttributeType.DATE) {
    return [];
  }
  return computeResult.data.children.filter(child => {
    if (!child.value?.value) {
      return false;
    }
    const childInterval = child.value.value as Types.LocalInterval;
    return (
      child.value?.value &&
      (!start || start.isBefore(childInterval.end)) &&
      (!endInclusive || endInclusive.isSameOrAfter(childInterval.start)) &&
      (!endExclusive || endExclusive.isSameOrAfter(childInterval.end))
    );
  });
}

function getInventoryRiskDescription(config: Types.Widget) {
  const remainingSupplyFilters = config.metricFilters
    .flatMap(x => x)
    .filter(filter => getEffectiveMetric(filter.metric).name === 'remaining_supply');
  if (remainingSupplyFilters.some(isGreaterThanPredicate)) {
    return 'excess inventory';
  } else if (remainingSupplyFilters.some(isLessThanFilter)) {
    return 'an out-of-stock risk';
  }

  return null;
}

function isGreaterThanPredicate(metricFilter: Types.MetricFilter) {
  return (
    metricFilter.predicate === Types.MetricFilterPredicate.GE ||
    metricFilter.predicate === Types.MetricFilterPredicate.GT
  );
}

function isLessThanFilter(metricFilter: Types.MetricFilter) {
  return (
    metricFilter.predicate === Types.MetricFilterPredicate.LE ||
    metricFilter.predicate === Types.MetricFilterPredicate.LT
  );
}

function getSalesInsightText(evalDate: string, computeResult: ComputeResultExtended) {
  const currentIntervalPart = getSalesCurrentIntervalPart(evalDate, computeResult);
  const futurePart = getSalesFuturePart(evalDate, computeResult);
  const historicalPart = getSalesHistoricalPart(evalDate, computeResult);

  if (futurePart) {
    return `${currentIntervalPart}, ${futurePart}`;
  } else if (historicalPart) {
    return `${currentIntervalPart}, ${historicalPart}`;
  } else {
    return currentIntervalPart;
  }
}

function getSalesCurrentIntervalPart(evalDate: string, computeResult: ComputeResultExtended) {
  const metricInstance = computeResult.metrics[0];
  const subtotalMatch = getSubtotalGroupingMatchValue(computeResult, evalDate, 0, ['last']);
  if (subtotalMatch) {
    const displayValue = formatMetricValueForHeader(
      metricInstance,
      subtotalMatch.value,
      subtotalMatch.metadata
    );
    const compsAdjective = getCompsAdjective(metricInstance, subtotalMatch.value);
    const unitsDisplay = formatUnitConversionAttribute(metricInstance, subtotalMatch.value);
    const displayPeriod = formatSubtotalPeriod(subtotalMatch);

    return `${displayValue} ${compsAdjective} ${unitsDisplay} reported ${displayPeriod}`;
  }

  const unitsValue = computeResult.data.columnData.metricValues[0];
  const unitsValueDisplay = Format.metricValue(metricInstance, unitsValue, null, {
    lengthFormat: TextLengthFormat.COMPACT,
    significantFigures: 3,
    useDollarSign: true,
  });

  const compsAdjective = getCompsAdjective(metricInstance, unitsValue);
  const unitsDisplay = formatUnitConversionAttribute(metricInstance, unitsValue);
  const periodDisplay = formatDatePeriodForHeader(metricInstance.arguments.period);

  return `${unitsValueDisplay} ${compsAdjective} ${unitsDisplay} reported ${periodDisplay}`;
}

function getSalesHistoricalPart(evalDate: string, computeResult: ComputeResultExtended) {
  const metricInstance = computeResult.metrics[0];
  const subtotalMatch = getSubtotalGroupingMatchValue(computeResult, evalDate, 0, ['last'], 2);
  if (subtotalMatch) {
    const displayValue = formatMetricValueForHeader(
      metricInstance,
      subtotalMatch.value,
      subtotalMatch.metadata
    );
    const compsAdjective = getCompsAdjective(metricInstance, subtotalMatch.value);
    const unitsDisplay = formatUnitConversionAttribute(metricInstance, subtotalMatch.value);
    const displayPeriod = formatSubtotalPeriod(subtotalMatch);

    return `${displayValue} ${compsAdjective} ${unitsDisplay} reported ${displayPeriod}`;
  }
  return '';
}

function getSalesFuturePart(evalDate: string, computeResult: ComputeResultExtended): string | null {
  const [primaryMetricInstance] = computeResult.metrics;
  const primaryEffectiveMetric = getEffectiveMetric(primaryMetricInstance);
  const unitConversionAttribute = primaryMetricInstance.arguments.unitConversionAttribute;

  const forecastsAndIndices = computeResult.metrics
    .map<[Types.MetricInstance, number]>((metricInstance, index) => [metricInstance, index])
    // match forecast against the actuals
    .filter(([metricInstance]) =>
      getEffectiveMetric(metricInstance)
        .forecastedMetrics.map(metric => metric.name)
        .includes(primaryEffectiveMetric.name)
    )
    // make sure the forecast period is into the future
    .filter(([metricInstance]) =>
      periodExtendsIntoTheFuture(assertTruthy(metricInstance.arguments.period))
    )
    // both must be unit-converted with same value (or nothing)
    .filter(
      ([metricInstance]) =>
        metricInstance.arguments.unitConversionAttribute?.id === unitConversionAttribute?.id
    )
    // both metrics must have the same display type (i.e. no $ and % in same sentence)
    .filter(
      ([metricInstance]) =>
        getMetricDisplayType(metricInstance) === getMetricDisplayType(primaryMetricInstance)
    );

  if (!forecastsAndIndices.length) {
    return null;
  }

  const [forecastMetricInstance, forecastMetricIndex] = forecastsAndIndices[0];

  // If date groupings exist, then show only the next date unit's value in the text.
  // Else, use the total value.
  const subtotalMatch = getSubtotalGroupingMatchValue(
    computeResult,
    evalDate,
    forecastMetricIndex,
    ['this', 'next']
  );
  if (subtotalMatch) {
    const forecastValueDisplay = formatMetricValueForHeader(
      forecastMetricInstance,
      subtotalMatch.value,
      subtotalMatch.metadata
    );
    return `${forecastValueDisplay} expected ${formatSubtotalPeriod(subtotalMatch)}`;
  }

  // if we don't have date groupings we can use for subtotals, render the full subtotal using
  // the period, as long as it's not a complex period (it looks very ugly)
  const forecastGrouping = computeResult.data;

  const period = assertTruthy(forecastMetricInstance.arguments.period);
  if (!isForwardOnlyForecast(period)) {
    // We'll render the period as-is, but we don't want to render the complex periods as
    // they'd look very ugly (e.g. "Last 4 Weeks Through Next 13 Weeks")
    return null;
  }

  const forecastValue = forecastGrouping.columnData.metricValues[forecastMetricIndex];
  const forecastMetadata = forecastGrouping.columnData.metricMetadata[forecastMetricIndex];
  if (!Number.isFinite(forecastValue)) {
    return null;
  }

  const forecastValueDisplay = formatMetricValueForHeader(
    forecastMetricInstance,
    forecastValue,
    forecastMetadata
  );
  const futureDisplayPeriod = formatDatePeriodForHeader(forecastMetricInstance.arguments.period);

  return `${forecastValueDisplay} expected ${futureDisplayPeriod}`;
}

function formatMetricValueForHeader(
  metricInstance: MetricInstance,
  value: number | null,
  metadata: ReadonlyArray<MetricValueMetadata> | null
) {
  return Format.metricValue(metricInstance, value, metadata, {
    ...REDUCED_PRECISION_FORMAT_OPTIONS,
    includeCurrency: true,
    useDollarSign: true,
  });
}

function formatDatePeriodForHeader(period: Types.DatePeriod, predicateWord = 'in') {
  const displayPeriod = Format.period(period).toLocaleLowerCase();

  if ('amount' in period && period.type !== 'future' && period.type !== 'previous') {
    const requiresPredicate = period.amount > 1;
    return `${requiresPredicate ? predicateWord + ' the' : ''} ${displayPeriod}`;
  }

  return displayPeriod;
}

function formatSubtotalPeriod(subtotalMatch: GroupingMatchValue) {
  const displayGranularity = getAttributeNameForGranularity(
    subtotalMatch.granularity
  ).toLocaleLowerCase();

  if (subtotalMatch.period !== 'this' && subtotalMatch.offset > 1) {
    const pluralizedDisplayGranularity = pluralize(displayGranularity, subtotalMatch.offset);
    return subtotalMatch.period === 'last'
      ? `${subtotalMatch.offset} ${pluralizedDisplayGranularity} ago`
      : `in ${subtotalMatch.offset} ${pluralizedDisplayGranularity}`;
  }

  return `${subtotalMatch.period} ${displayGranularity}`;
}

function getSubtotalGroupingMatchValue(
  computeResult: ComputeResultExtended,
  evaluationDate: string,
  metricIndex: number,
  acceptedPeriods: ReadonlyArray<SubtotalPeriod>,
  // offset is only used for matching "last" or "next" periods and indicates how many "last"/"next"
  // periods to offset from the current period
  offset = 1
): GroupingMatchValue | null {
  // If the row/col groupings are available as subtotals by date period, use data from the
  // best matching period. These are usually "last", "this", or "next" period from evaluation date.
  const subtotalRowGroupingMatch = getRowGroupingForMatchingTimeUnit(
    computeResult,
    evaluationDate,
    metricIndex,
    acceptedPeriods,
    offset
  );
  const subtotalColGroupingMatch = getColGroupingForMatchingTimeUnit(
    computeResult,
    evaluationDate,
    metricIndex,
    acceptedPeriods,
    offset
  );

  const match = subtotalRowGroupingMatch ?? subtotalColGroupingMatch;
  const {value: subtotalValue, metadata: subtotalMetadata} = getGroupingMatchValue(
    subtotalRowGroupingMatch,
    subtotalColGroupingMatch,
    metricIndex
  );
  if (!match || !subtotalValue) {
    return null;
  }

  return {
    value: subtotalValue,
    metadata: subtotalMetadata,
    granularity: match.granularity,
    period: match.period,
    offset,
  };
}

function getRowGroupingForMatchingTimeUnit(
  computeResult: ComputeResultExtended,
  evaluationDate: string,
  metricIndex: number,
  acceptedPeriods: ReadonlyArray<SubtotalPeriod>,
  offset: number
): RowGroupingMatch | null {
  const firstGrouping = computeResult.rowGroupings[0];
  // grouping must be the first one, else subtotals are not available
  if (firstGrouping?.attribute.type !== Types.AttributeType.DATE) {
    return null;
  }

  const currentPeriodDateRow = acceptedPeriods.includes('this')
    ? computeResult.data.children.find(row => isCurrentInterval(row.value, evaluationDate))
    : null;
  const nextPeriodDateRow = acceptedPeriods.includes('next')
    ? getNextGroupingMatch(computeResult, evaluationDate, 'row', offset)
    : null;
  const previousPeriodDateRow = acceptedPeriods.includes('last')
    ? getPreviousGroupingMatch(computeResult, metricIndex, evaluationDate, 'row', offset)
    : null;

  const granularity = getGranularityForAttribute(firstGrouping.attribute);
  if (Number.isFinite(currentPeriodDateRow?.columnData.metricValues[metricIndex])) {
    return {grouping: currentPeriodDateRow!, period: 'this', granularity, offset};
  } else if (Number.isFinite(nextPeriodDateRow?.columnData.metricValues[metricIndex])) {
    return {grouping: nextPeriodDateRow!, period: 'next', granularity, offset};
  } else if (Number.isFinite(previousPeriodDateRow?.columnData.metricValues[metricIndex])) {
    return {grouping: previousPeriodDateRow!, period: 'last', granularity, offset};
  }
  return null;
}

function getColGroupingForMatchingTimeUnit(
  computeResult: ComputeResultExtended,
  evaluationDate: string,
  metricIndex: number,
  acceptedPeriods: ReadonlyArray<SubtotalPeriod>,
  offset: number
): ColGroupingMatch | null {
  const firstGrouping = computeResult.columnGroupings[0];
  // grouping must be the first one, else subtotals are not available
  if (firstGrouping?.attribute.type !== Types.AttributeType.DATE) {
    return null;
  }

  const currentPeriodDateCol = acceptedPeriods.includes('this')
    ? computeResult.data.columnData.children.find(col =>
        isCurrentInterval(col.value, evaluationDate)
      )
    : null;
  const nextPeriodDateCol = acceptedPeriods.includes('next')
    ? getNextGroupingMatch(computeResult, evaluationDate, 'col', offset)
    : null;
  const previousPeriodDateCol = acceptedPeriods.includes('last')
    ? getPreviousGroupingMatch(computeResult, metricIndex, evaluationDate, 'col', offset)
    : null;

  const granularity = getGranularityForAttribute(firstGrouping.attribute);
  if (Number.isFinite(currentPeriodDateCol?.metricValues[metricIndex])) {
    return {grouping: currentPeriodDateCol!, period: 'this', granularity, offset};
  } else if (Number.isFinite(nextPeriodDateCol?.metricValues[metricIndex])) {
    return {grouping: nextPeriodDateCol!, period: 'next', granularity, offset};
  } else if (Number.isFinite(previousPeriodDateCol?.metricValues[metricIndex])) {
    return {grouping: previousPeriodDateCol!, period: 'last', granularity, offset};
  }
  return null;
}

function getGroupingMatchValue(
  rowMatch: RowGroupingMatch | null,
  colMatch: ColGroupingMatch | null,
  metricIndex: number
): {value: number | null; metadata: ReadonlyArray<Types.MetricValueMetadata> | null} {
  return {
    value:
      rowMatch?.grouping.columnData.metricValues[metricIndex] ??
      colMatch?.grouping.metricValues[metricIndex] ??
      null,
    metadata:
      rowMatch?.grouping.columnData.metricMetadata[metricIndex] ??
      colMatch?.grouping.metricMetadata[metricIndex] ??
      null,
  };
}

function getNextGroupingMatch(
  computeResult: ComputeResultExtended,
  evaluationDate: string,
  type: 'row',
  offset: number
): ThinComputeResultRowExtended | null;
function getNextGroupingMatch(
  computeResult: ComputeResultExtended,
  evaluationDate: string,
  type: 'col',
  offset: number
): Types.ThinComputeResultColumn | null;

function getNextGroupingMatch(
  computeResult: ComputeResultExtended,
  evaluationDate: string,
  type: 'row' | 'col',
  offset: number
) {
  const children =
    type === 'row' ? computeResult.data.children : computeResult.data.columnData.children;
  const nextPeriodIndex = children.findIndex(row => isNextInterval(row.value, evaluationDate));
  // offset is specified as the offset from the current period, subtract one as nextPeriodIndex already
  // counts as an offset of 1
  const offsetPeriodIndex = nextPeriodIndex + offset - 1;
  if (nextPeriodIndex < 0 || offsetPeriodIndex >= children.length) {
    return null;
  }
  return children[offsetPeriodIndex];
}

function getPreviousGroupingMatch(
  computeResult: ComputeResultExtended,
  metricIndex: number,
  evaluationDate: string,
  type: 'row',
  offset: number
): ThinComputeResultRowExtended;
function getPreviousGroupingMatch(
  computeResult: ComputeResultExtended,
  metricIndex: number,
  evaluationDate: string,
  type: 'col',
  offset: number
): Types.ThinComputeResultColumn;

function getPreviousGroupingMatch(
  computeResult: ComputeResultExtended,
  metricIndex: number,
  evaluationDate: string,
  type: 'row' | 'col',
  offset: number
) {
  const period = assertTruthy(computeResult.metrics[metricIndex].arguments.period);

  // If the current period exists, the "last" period is the one before it.
  const currentPeriodIndex =
    type === 'row'
      ? computeResult.data.children.findIndex(row => isCurrentInterval(row.value, evaluationDate))
      : computeResult.data.columnData.children.findIndex(col =>
          isCurrentInterval(col.value, evaluationDate)
        );

  if (currentPeriodIndex === 0) {
    // If the current period is the first one, there can't be a previous period
    return null;
  } else if (currentPeriodIndex > 0) {
    return type === 'row'
      ? computeResult.data.children[currentPeriodIndex - offset]
      : computeResult.data.columnData.children[currentPeriodIndex - offset];
  }

  // If no current period exists, assume that the result contains only historical data. Use
  // the fact that the last period's last grouping is the "last" value for given granularity.
  // If an offset is specified, since we're making the assumption that the last grouping is the
  // "last" value (offset of 1), then we do any additional offsets from there.
  //
  // Note: if this assumption proves to be a problem, we can walk through all children and confirm
  // that it's all historical (or future) data to verify the assumption.
  switch (period.type) {
    case 'lastn':
    case 'fixed_to_now':
    case 'todate':
    case 'todate_weekend':
      return type === 'row'
        ? computeResult.data.children[computeResult.data.children.length - offset]
        : computeResult.data.columnData.children[
            computeResult.data.columnData.children.length - offset
          ];
    default:
      return null;
  }
}

function formatUnitConversionAttribute(metricInstance: Types.MetricInstance, value: number) {
  if (metricInstance.metric.displayType === Types.MetricDisplayType.MONEY) {
    return '';
  }

  const unitConversionAttribute = metricInstance.arguments.unitConversionAttribute;
  const unitsNoun = unitConversionAttribute ? Format.attribute(unitConversionAttribute) : 'unit';

  // Best-effort: QL is the only current exception (2023-02) where unit conversion attr
  // is an abbreviation and in all caps.
  return pluralize(unitsNoun === 'QL' ? unitsNoun : unitsNoun.toLocaleLowerCase(), value);
}

function getCompsAdjective(metricInstance: Types.MetricInstance, value: number): string {
  return isComparisonMetric(metricInstance) ? (value >= 0 ? 'more' : 'less') : '';
}

function getGroupingNoun(computeResult: ComputeResultExtended): string | null {
  const groupings = [...computeResult.rowGroupings, ...computeResult.columnGroupings];
  const hasProductGroupings = groupings.some(
    attrInstance => attrInstance.attribute.type === Types.AttributeType.PRODUCT
  );
  const hasPartnerGrouping = groupings.some(
    attrInstance => attrInstance.attribute.name === 'Partner'
  );
  const hasLocationGroupings = groupings
    .filter(attrInstance => attrInstance.attribute.name !== 'Partner')
    .some(attrInstance => attrInstance.attribute.type === Types.AttributeType.LOCATION);

  if (hasProductGroupings && hasLocationGroupings) {
    return 'product-location';
  } else if (hasProductGroupings && hasPartnerGrouping) {
    return 'product-partner';
  } else if (hasProductGroupings) {
    return 'product';
  } else if (hasLocationGroupings) {
    return 'location';
  } else if (hasPartnerGrouping) {
    return 'partner';
  }
  return null;
}

function isCurrentInterval(attrValue: Types.ThinAttributeValue | null, evalDate: string): boolean {
  const interval = getLocalInterval(attrValue);
  return !!interval && interval.start <= evalDate && interval.end > evalDate;
}

function isNextInterval(attrValue: Types.ThinAttributeValue | null, evalDate: string): boolean {
  const interval = getLocalInterval(attrValue);
  return !!interval && interval.start > evalDate;
}

function getLocalInterval(attrValue: Types.ThinAttributeValue | null): Types.LocalInterval | null {
  return attrValue?.valueType === 'interval' ? (attrValue?.value as Types.LocalInterval) : null;
}
