import {Map, Set} from 'immutable';
import moment from 'moment-timezone';

import {CurrentUser} from 'redux/reducers/user';
import {ComputeResultExtended, IComputeResultExtended} from 'toolkit/compute/types';
import {AlloyIcon} from 'toolkit/icons/types';
import {DEFAULT_PERIOD} from 'toolkit/time/constants';
import * as Types from 'types';
import {ApiError} from 'types/error';
import Record from 'types/record';
import {fromArray as _fromArray} from 'types/utils';
import {ExtendsOrNull} from 'utils/types';

export type ThinViewsById = Map<number, Types.ThinView>;
export type FullViewsById = Map<number, Types.View>;

export interface AnalysisUrlParams {
  readonly vendor: string;
  readonly viewId?: string;
  readonly viewSlug?: string;
  readonly widgetId?: string;
  0?: string; // the value of `*` expression is stored in [0]
}

export type WorkflowSlug = 'promotion-analysis' | 'retail-replenishment';
type DiagnosticsSlug = 'diagnostics';

export interface WorkflowUrlParams {
  readonly vendor: string;
  readonly workflow?: WorkflowSlug | DiagnosticsSlug;
}

export interface BreadcrumbItem {
  readonly slug: string;
  readonly entityName: string;
}

export interface SaveAsParams {
  readonly saveAs?: boolean;
  readonly asLegacyTemplate?: boolean;
}

export interface ThinAttributeInstance {
  readonly graphContext?: Types.GraphContext | null;
  readonly id: number;
  readonly templateAttributeId: number;
}

export interface ThinAttributeFilter {
  readonly attribute: ThinAttributeInstance;
  readonly inclusive?: boolean;
  readonly valueIds: ReadonlyArray<number>;
}

export interface IViewUrlParams {
  readonly asOfDate?: moment.Moment;
  readonly calendar?: Types.RetailCalendarEnum;
  readonly evaluationPeriod?: Types.DatePeriod;
  readonly filters: ReadonlyArray<ThinAttributeFilter>;
  readonly metric?: Types.MetricInstance;
  readonly selectionFilters?: ReadonlyArray<ThinAttributeFilter>;
}

export interface ViewActions {
  readonly canArchive: boolean;
  readonly canEditLayout: boolean;
  readonly canEditSettings: boolean;
  readonly canExportImport: boolean;
  readonly canAddPresentationOverrides: boolean;
  readonly canToggleFavorite: boolean;
  readonly canSave: boolean;
  readonly canSaveAs: boolean;
  readonly canSaveAsTemplate: boolean;
  readonly canShare: boolean;
}

export class ViewUrlParams extends Record<IViewUrlParams>()({
  asOfDate: null,
  calendar: null,
  evaluationPeriod: null,
  filters: [],
  metric: null,
  selectionFilters: [],
}) {
  static fromJS(data: any) {
    return new ViewUrlParams(data).merge({
      asOfDate: data.asOfDate ? moment.utc(data.asOfDate) : null,
    });
  }

  static fromArray(array: IViewUrlParams[]) {
    return _fromArray<ViewUrlParams>(array, ViewUrlParams);
  }

  static isEmpty(viewParams: ViewUrlParams | null) {
    return !(
      viewParams &&
      (viewParams.filters.length > 0 ||
        Object.entries(viewParams.toJS()).some(
          ([key, value]) => !['filters', 'selectionFilters'].includes(key) && !!value
        ))
    );
  }
}

export interface WidgetData<T extends WidgetDataClass | unknown, M = unknown> {
  readonly data?: T | null;
  readonly ephemeralData: M;
  readonly cacheVersion: string | null;
  readonly error?: Error | ApiError | null;
  readonly isFetching: boolean;
  readonly lastUpdated: moment.Moment | null;
  readonly needsRefresh: boolean;
  readonly requestId: string | null;
}

export function createDefaultWidgetData<DataType = unknown>(): WidgetData<DataType> {
  return {
    data: null,
    cacheVersion: null,
    ephemeralData: {},
    error: null,
    isFetching: false,
    lastUpdated: null,
    needsRefresh: false,
    requestId: null,
  };
}

export interface CalendarSelection {
  currentDate?: moment.Moment;
}

type DiagnosticsResult = ReadonlyArray<Types.Insight>;
export type EventWidgetData = WidgetData<Types.CalendarEventResult, CalendarSelection>;
export type AttributeWidgetData = WidgetData<AttributeResult>;
export type AttributeHierarchyWidgetData = WidgetData<
  CompositeResult<AttributeResult | AttributeHierarchyTreeResult>
>;
export type ComputeWidgetData = WidgetData<ComputeResultExtended>;
export type LoaderAnimation = 'OVERLAY' | 'BAR' | 'NONE';
export type DiagnosticTableWidgetData = WidgetData<DiagnosticsResult>;
export type PlanningWidgetData = WidgetData<
  CompositeResult<Types.CalendarEventResult | ComputeResultExtended>
>;
export type NewProductWidgetData =
  | ComputeWidgetData
  | WidgetData<CompositeResult<ComputeResultExtended>>;

export interface IAttributeResult {
  attributeInstances: ReadonlyArray<Types.AttributeInstance>;
}

export class AttributeResult extends Record<IAttributeResult>()({
  attributeInstances: [],
}) {}

export interface IAttributeHierarchyTreeResult {
  tree: Types.AttributeHierarchyTree | null;
}

export class AttributeHierarchyTreeResult extends Record<IAttributeHierarchyTreeResult>()({
  tree: {value: null, children: []},
}) {}

export type BaseWidgetDataTypes =
  | IAttributeResult
  | IComputeResultExtended
  | Types.CalendarEventResult
  | DiagnosticsResult;

export type WidgetDataClass = BaseWidgetDataTypes | CompositeResult<any>;

export enum WidgetDataType {
  ATTRIBUTE = 'ATTRIBUTE',
  COMPOSITE = 'COMPOSITE',
  COMPUTE = 'COMPUTE',
  EVENT = 'EVENT',
}

export class CompositeResult<ContainedResultTypes> {
  readonly attribute: ExtendsOrNull<ContainedResultTypes | null, AttributeResult>;
  readonly attributeHierarchyTree: ExtendsOrNull<
    ContainedResultTypes,
    AttributeHierarchyTreeResult
  >;
  readonly compute: ExtendsOrNull<ContainedResultTypes, ComputeResultExtended>;
  readonly computeResults: ReadonlyArray<
    ExtendsOrNull<ContainedResultTypes, ComputeResultExtended>
  >;
  readonly events: ExtendsOrNull<ContainedResultTypes, Types.CalendarEventResult>;
  readonly ranges: ExtendsOrNull<ContainedResultTypes, Types.AllDataRangeResponse>;
  readonly tierHierarchy: ExtendsOrNull<ContainedResultTypes, ReadonlyArray<string>>;

  constructor(params: {
    [Key in keyof CompositeResult<ContainedResultTypes>]?: CompositeResult<ContainedResultTypes>[Key];
  }) {
    this.attribute = (params.attribute || null) as ExtendsOrNull<
      ContainedResultTypes | null,
      AttributeResult
    >;
    this.attributeHierarchyTree = (params.attributeHierarchyTree || null) as ExtendsOrNull<
      ContainedResultTypes,
      AttributeHierarchyTreeResult
    >;
    this.compute = (params.compute || null) as ExtendsOrNull<
      ContainedResultTypes,
      ComputeResultExtended
    >;
    this.computeResults = params.computeResults || [];
    this.events = (params.events || null) as ExtendsOrNull<
      ContainedResultTypes,
      Types.CalendarEventResult
    >;
    this.ranges = (params.ranges || null) as ExtendsOrNull<
      ContainedResultTypes,
      Types.AllDataRangeResponse
    >;
    this.tierHierarchy = (params.tierHierarchy || null) as ExtendsOrNull<
      ContainedResultTypes,
      ReadonlyArray<string>
    >;
  }
}

export interface DataProviderOptions {
  readonly activeView: ViewState;
  readonly widgetIndex: number;
  readonly planType?: Types.PlanType | null;
  readonly planDiff?: Types.PlanDiff | null;
  readonly currentUser: CurrentUser;
  readonly removeDoubleCountingPartners?: boolean;
  readonly requestId: string;
  readonly useUnversionedData?: boolean;
}

export type WidgetDataProvider = (
  dataProviderOptions: DataProviderOptions
) => Promise<WidgetDataClass> | null;

export interface WidgetMetadata {
  readonly dataProvider: WidgetDataProvider | null;
  readonly dataType: WidgetDataType | null;
  readonly description: string;
  readonly icon: AlloyIcon;
  readonly label: string;
  readonly isCalendar?: boolean;
  readonly isChart?: boolean;
  readonly isContainer?: boolean;
  // filter widgets are used to pick selection filters for other widgets
  // - they are not affected by any widgets' selection filters
  // - if they set selection filters that conflict with another widget,
  //   that other widget will lose its selection filters
  readonly isFilterWidget?: boolean;
  readonly isHeader?: boolean;
  readonly isMap?: boolean;
  readonly isSidebar?: boolean;
  readonly isTable?: boolean;
  readonly isLandingPageWidget?: boolean;
  readonly isWorkflowPageWidget?: boolean;
  readonly isWrappableInContainer?: boolean;
  readonly layoutContainerClassName?: string;
  readonly loaderAnimation: LoaderAnimation;
  readonly managedMetricArguments: Set<Types.MetricArgument>;
  readonly requestType: Types.ComputeRequestType;
  readonly shortDisplayName: string;
  readonly supportsCalendarEvents?: boolean;
  readonly supportsColumnGrouping?: boolean;
  readonly supportsColumnTransposition?: boolean;
  readonly supportsEditingProductMappings?: boolean;
  readonly supportsDebuggingMetrics?: boolean;
  readonly supportsEmailSubscriptions?: boolean;
  readonly supportsExporting?: boolean;
  readonly supportsImageExport?: boolean;
  readonly supportsSettingPlanOverrides?: boolean;
  readonly supportsRowGrouping?: boolean;
  readonly supportsOutdatedData?: boolean;
  readonly supportsCustomMetricColors?: boolean;
  readonly supportsCustomMetricNames?: boolean;
  readonly supportsIconizedColumns?: boolean;
  readonly isEditable: (currentUser: CurrentUser) => boolean;
  readonly requiredPermission?: Types.PermissionKey;
}

export function getDefaultViewProperties(): Omit<Types.View, 'name'> {
  return {
    calendar: Types.RetailCalendarEnum.NRF,
    weekFormat: Types.WeekFormat.START_OF_WEEK,
    description: null,
    evaluationPeriod: DEFAULT_PERIOD,
    filters: [],
    id: null,
    isFixedHeight: true,
    lastModifiedAt: null,
    lastModifiedBy: null,
    archivedAt: null,
    layoutType: Types.LayoutType.FLOW,
    ownerId: null,
    shareLevel: Types.ShareLevel.PRIVATE,
    slug: null,
    tags: [],
    unitConversionAttribute: null,
    widgets: [],
    updateMode: Types.ViewUpdateMode.AUTOMATIC,
    enableDemandPlanLinks: true,
    enableInventoryPlanLinks: true,
    enableOrderPreviewLinks: true,
    currency: Types.CurrencyCode.USD,
    type: Types.ViewType.DEFAULT,
    isPublished: false,
    packageIds: [],
    removeDoubleCounting: false,
    requiredPermissions: [],
  };
}

export interface IViewState {
  editingWidgetIndex: number | null;
  editingWidgetSubscriptionIndex: number | null;
  viewWidgetFilesIndex: number | null;
  expandedWidgetIndex: number | null;
  evaluationDate: moment.Moment | null;
  computeEvaluationDateTime: moment.Moment | null;
  isEditingLayout: boolean;
  isPeriodChooserOpen: boolean;
  isSelectionEnabled: boolean;
  isViewMenuOpen: boolean;
  originalWidgets: ReadonlyArray<Types.Widget>;
  proposedWidgets: ReadonlyArray<Types.Widget>;
  selectedExperiment: Types.Experiment | null;
  selectionFiltersByWidgetIndex: Map<number, ReadonlyArray<Types.AttributeFilter>>;
  view: Types.View;
  viewParams: ViewUrlParams | null;
  widgetData: ReadonlyArray<WidgetData<unknown>>;
}

export class ViewState extends Record<IViewState>()({
  editingWidgetIndex: null,
  editingWidgetSubscriptionIndex: null,
  viewWidgetFilesIndex: null,
  evaluationDate: null,
  computeEvaluationDateTime: null,
  expandedWidgetIndex: null,
  isEditingLayout: false,
  isPeriodChooserOpen: false,
  isSelectionEnabled: true,
  isViewMenuOpen: false,
  originalWidgets: [],
  proposedWidgets: [],
  selectedExperiment: null,
  selectionFiltersByWidgetIndex: Map(),
  view: {
    ...getDefaultViewProperties(),
    weekFormat: Types.WeekFormat.START_OF_WEEK,
    evaluationPeriod: DEFAULT_PERIOD,
    filters: [],
    name: '',
    slug: null,
    widgets: [],
  },
  viewParams: null,
  widgetData: [],
}) {}
