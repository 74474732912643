import './GlobalCreateMenu.scss';

import React from 'react';
import {Overlay} from 'react-bootstrap';

import * as Api from 'api';
import {storeAndGoToNewView} from 'redux/actions/analysis';
import {setGlobalCreateMenuOpen} from 'redux/actions/navigation';
import {useCurrentUser} from 'redux/context/user';
import {getAllMetricsWithAnyData, getWidgetConfigLoadStatus} from 'redux/selectors/analysis';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import Boundary from 'toolkit/components/Boundary';
import ContextMenuPopover from 'toolkit/components/ContextMenuPopover';
import eventIcon from 'toolkit/events/icons/icon-event.svg';
import experimentIcon from 'toolkit/groups/icons/icon-experiment.svg';
import {getAllowedExperimentMetricOptions} from 'toolkit/groups/utils';
import {hasPermission} from 'toolkit/users/utils';
import {defaultOverlayProps} from 'toolkit/utils/react-bootstrap';
import dashboardIcon from 'toolkit/views/icons/icon-dashboard.svg';
import {createDefaultView} from 'toolkit/views/utils';
import * as Types from 'types';
import {suspendIf} from 'utils/api';
import {Status} from 'utils/status';
import {useApi, useResult} from 'utils/useApi';
import {isPromoAnalysisEnabled} from 'workflows/utils';

import {Props as DropdownMenuProps} from './UserMenu';

const MenuContent: React.FC<MenuContentProps> = props => {
  const availableMetrics = useSelector(getAllMetricsWithAnyData);
  const hasExperimentMetrics = !!getAllowedExperimentMetricOptions(availableMetrics).length;
  const currentUser = useCurrentUser();
  const allGroupings = useSelector(state => state.analysis.data.allGroupings);
  const defaultAttributeHierarchies = useSelector(
    state => state.analysis.data.defaultAttributeHierarchies
  );
  const dispatch = useDispatch();
  const defaultAttributes = useResult(
    useApi(Api.Attributes.getDefaultFilterAttributes.getResource())
  );

  const widgetConfigLoadStatus = useSelector(getWidgetConfigLoadStatus);
  suspendIf(widgetConfigLoadStatus !== Status.succeeded);

  const handleItemKeyPress = (handler: () => void) => (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handler();
      dispatch(setGlobalCreateMenuOpen(false));
    }
  };

  const createDashboard = () =>
    dispatch(
      storeAndGoToNewView(
        createDefaultView(
          currentUser,
          currentUser.settings.analysisSettings,
          defaultAttributeHierarchies,
          availableMetrics,
          allGroupings,
          defaultAttributes
        )
      )
    );
  return (
    <>
      {hasPermission(currentUser, Types.PermissionKey.DASHBOARD_CREATE_DASHBOARD) && (
        <li tabIndex={0} onClick={createDashboard} onKeyUp={handleItemKeyPress(createDashboard)}>
          <img
            ref={comp => comp?.focus()}
            alt="Dashboard"
            className="create-icon"
            src={dashboardIcon}
          />
          <div className="global-create-menu-text-wrapper">
            <div className="global-create-menu-item-title">Dashboard</div>
            <div className="global-create-menu-item-subtitle">Start an analysis</div>
          </div>
        </li>
      )}
      {hasExperimentMetrics &&
        hasPermission(currentUser, Types.PermissionKey.EXPERIMENT_CREATE_EXPERIMENT) && (
          <>
            <li
              tabIndex={0}
              onClick={props.onCreateExperiment}
              onKeyUp={handleItemKeyPress(props.onCreateExperiment)}
            >
              <img alt="Experiment" className="create-icon" src={experimentIcon} />
              <div className="global-create-menu-text-wrapper">
                <div className="global-create-menu-item-title">Experiment</div>
                <div className="global-create-menu-item-subtitle">
                  Measure impact of a promotion or other change
                </div>
              </div>
            </li>
          </>
        )}
      {hasPermission(currentUser, Types.PermissionKey.EVENTS_CREATE_EVENT) && (
        <li
          tabIndex={0}
          onClick={props.onCreateEvent}
          onKeyUp={handleItemKeyPress(props.onCreateEvent)}
        >
          <img alt="Event" className="create-icon" src={eventIcon} />
          <div className="global-create-menu-text-wrapper">
            <div className="global-create-menu-item-title">Event</div>
            <div className="global-create-menu-item-subtitle">
              Define {isPromoAnalysisEnabled(currentUser) && 'and analyze '}a promotion
            </div>
          </div>
        </li>
      )}
      {currentUser.isDevelopmentMode && (
        <li
          className="footer-item"
          tabIndex={0}
          onClick={props.onCreateFromLegacyTemplate}
          onKeyUp={handleItemKeyPress(props.onCreateFromLegacyTemplate)}
        >
          <div className="global-create-menu-text-wrapper">
            <div className="global-create-menu-item-title">Dashboard from Legacy Template</div>
          </div>
        </li>
      )}
    </>
  );
};

const GlobalCreateMenu: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const closeMenuOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!event.isPropagationStopped()) {
      dispatch(setGlobalCreateMenuOpen(false));
    }
  };

  return (
    <Overlay
      {...defaultOverlayProps}
      placement={props.placement}
      target={() => props.menuRef.current}
      show
      onHide={props.onHide}
    >
      <ContextMenuPopover
        className="GlobalCreateMenu"
        id="topnav-global-create-menu"
        variant="large"
        onClick={closeMenuOnClick}
      >
        <Boundary>
          <MenuContent
            onCreateEvent={props.onCreateEvent}
            onCreateExperiment={props.onCreateExperiment}
            onCreateFromLegacyTemplate={props.onCreateFromLegacyTemplate}
          />
        </Boundary>
      </ContextMenuPopover>
    </Overlay>
  );
};
GlobalCreateMenu.displayName = 'GlobalCreateMenu';
type MenuContentProps = {
  onCreateEvent: () => void;
  onCreateExperiment: () => void;
  onCreateFromLegacyTemplate: () => void;
};
type Props = DropdownMenuProps & MenuContentProps;
export default GlobalCreateMenu;
