import './NavTopBarPlusMenu.scss';

import {Placement} from '@popperjs/core';
import classNames from 'classnames';
import {push} from 'connected-react-router';
import React, {useCallback} from 'react';

import GlobalCreateMenu from 'nav/GlobalCreateMenu';
import {setGlobalCreateMenuOpen} from 'redux/actions/navigation';
import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import Icon from 'toolkit/components/Icon';
import Tooltip from 'toolkit/components/Tooltip';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';
import {hasAnyPermission} from 'toolkit/users/utils';
import * as Types from 'types';
import {isNonNullish} from 'utils/functions';

const NavBarPlusMenu: React.FC<Props> = ({
  setCreateCalendarEventDialogOpen,
  setNewViewDialogOpen,
  customPlusButton,
  placement = 'bottom-end',
}) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const isGlobalCreateMenuOpen = useSelector(state => state.navigation.isGlobalCreateMenuOpen);
  const globalCreateItemRef = React.createRef<HTMLElement>();

  const openGlobalCreateMenu = useCallback(
    () => dispatch(setGlobalCreateMenuOpen(true)),
    [dispatch]
  );
  const hideGlobalCreateMenu = useCallback(
    () => dispatch(setGlobalCreateMenuOpen(false)),
    [dispatch]
  );

  const openNewExperimentPage = () => {
    dispatch(push(`/${currentUser.vendor.name}/experiments/new-experiment`));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      openGlobalCreateMenu();
    }
  };

  if (
    !hasAnyPermission(
      currentUser,
      Types.PermissionKey.EVENTS_CREATE_EVENT,
      Types.PermissionKey.DASHBOARD_CREATE_DASHBOARD,
      Types.PermissionKey.EXPERIMENT_CREATE_EXPERIMENT
    )
  ) {
    return;
  }

  return (
    <>
      {isNonNullish(customPlusButton) ? (
        customPlusButton(globalCreateItemRef, openGlobalCreateMenu, handleKeyDown)
      ) : (
        <Tooltip placement={placement} tooltip="Create new...">
          <div
            ref={globalCreateItemRef as React.RefObject<HTMLDivElement>}
            className={classNames('global-create-dropdown', getHideOnPhonesClassName())}
            tabIndex={0}
            onClick={openGlobalCreateMenu}
            onKeyDown={handleKeyDown}
          >
            <div className="handle">
              <Icon icon="plus" />
            </div>
          </div>
        </Tooltip>
      )}

      {isGlobalCreateMenuOpen && (
        <GlobalCreateMenu
          menuRef={globalCreateItemRef as React.RefObject<HTMLDivElement>}
          placement={placement}
          onCreateEvent={() => setCreateCalendarEventDialogOpen(true)}
          onCreateExperiment={openNewExperimentPage}
          onCreateFromLegacyTemplate={() => setNewViewDialogOpen(true)}
          onHide={hideGlobalCreateMenu}
        />
      )}
    </>
  );
};
NavBarPlusMenu.displayName = 'NavBarPlusMenu';

type Props = {
  placement?: Placement;
  setCreateCalendarEventDialogOpen: (isOpen: boolean) => void;
  setNewViewDialogOpen: (isOpen: boolean) => void;
  customPlusButton?: (
    ref: React.RefObject<HTMLElement>,
    onClick: (e: React.MouseEvent<HTMLElement>) => void,
    onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void
  ) => React.ReactNode;
};

export default NavBarPlusMenu;
